export const Colors = {
    // Background Colors
    lightBackgroundColor: '#FFFFFF',
    darkBackgroundColor: '#0C0C13',
    grayBackgroundColor: '#52525F',
    cardGrayBackgroundColor: '#323235',
    iconBgBackgroundColor: '#00000024',
    blueBackgroundColor: '#00A3FF',

    // Text Colors
    darkTextColor: '#121217',
    lightTextColor: '#FFFFFF',
    darkOrangeTextColor: '#FF9E00',
    darkGreyTextColor: '#52525F',
    blueTextColor: '#00A3FF',

    // Genre Colors
    masculineGenreColor: '#0063F9',
    feminineGenreColor: '#FD4B92',
    neutralGenreColor: '#6D787E',
    pluralGenreColor: '#E0E0E6',

    // Verb Colors
    predicateVerbColor: '#592ACB',
    verb1VerbColor: '#7542E4',
    trennbareVerbColor: '#9059FF',
    verb2VerbColor: '#37176C',

    // Declension Colors
    subjectDeclensionColor: '#009BFF',
    dativDeclensionColor: '#EB002C',
    acusativDeclensionColor: '#28A138',
    genitivDeclensionColor: '#FF7700',
    nomComplementColor: '#009BFF',

    // Adverb Colors
    tekamoloAdverbsColor: '#00B69C',
    negationAdverbsColor: '#591212',

    // Conjunction Colors
    connectorConjunctionColor: '#FF9E00',

    // Preposition Colors
    prepositionPrepositionColor: '#EBEBEC',

    // Button Colors
    darkBlueButtonColor: '#0063F9',
    blackButtonColor: '#000000',

    // Common Colors
    whiteColor: '#FFFFFF',
    blackColor: '#000000',
    redColor: '#EB002C',
    lightGreyColor: '#D9D9D9',
    mediumGreyColor: '#52525F',
    darkGreyColor: '#323235',
    lightBlueColor: '#00A3FF',
    darkBlueColor: '#0063F9',
    lightOrangeColor: '#FFB800',
    darkOrangeColor: '#FF9E00',
    darkGreenColor: '#004E42',

    // Tooltip Colors
    tooltipBackgroundColor: '#0A06C0',
};

import React, { useState, useRef, useEffect } from "react";
import VerticalSpace from "../../../VerticalSpace";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import deleteIcon from "../../../../assets/deleteIcon.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { baseURL, getRequest, postRequest } from "../../../../api/apiClient";
import Toast from "../../../Common/Toast";
import "../../../../stylesheets/theme.css";
import Loader from "../../../../utils/Loading";
import "../../../../stylesheets/common.css";

const ImageComponents = () => {
  const [flashImage, setFlashImage] = useState<File | null>(null);
  const [flashImageId, setFlashImageId] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
   const [errors, setErrors] = useState({
     flashImage: "",
   });

  const navigate = useNavigate();
  const location = useLocation();

  const { flashcardContentId } = location.state || {};

  useEffect(() => {
    if (flashcardContentId) {
      fetchFlashImage(flashcardContentId);
    }
  }, [flashcardContentId]);

  const fetchFlashImage = async (id: number) => {
    try {
      setIsLoading(true);
      const response = await getRequest(
        `${baseURL}/lesson/get-flashimage/${flashcardContentId}`
      );

      const flashImageData = response?.data?.data;
      setIsLoadingImage(true);
      if (flashImageData) {
        setFlashImageId(flashImageData.id);
        setFlashImage(flashImageData.flashimage);
      }
      setIsLoadingImage(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoadingImage(false);
      setIsLoading(false);
      console.error("Error fetching flashImage details:", error);
    }
  };

  const handleFlashImageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setFlashImage(event.target.files[0]);
    }
  };

  const handleChooseFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handlePublishClick = async () => {
     const validationErrors = {
       flashImage: !flashImage ? "flash image is required" : "",
     };

     setErrors(validationErrors);
     Object.entries(validationErrors).forEach(([key, error]) => {
       if (error) {
         Toast(error, "error");
       }
     });

     // If any validation error exists, stop the form submission
     if (Object.values(validationErrors).some((error) => error)) {
       return;
     }

    const formData = new FormData();

    formData.append("flashCardContentComponentId", flashcardContentId);
    if (flashImage) formData.append("flashImage", flashImage);

    try {
      let response;
      if (flashImageId) {
        setIsLoading(true);
        response = await postRequest(
          `${baseURL}/lesson/update-flashImage/${flashImageId}`,
          formData
        );
        setIsLoading(false);
        // navigate(-1);
        if (response?.data.success){
          navigate(-1);
          Toast(response?.data.statusMessage, "success");
        } 
      } else {
        setIsLoading(true);
        response = await postRequest(
          `${baseURL}/lesson/create-flashImage`,
          formData
        );
        if(response?.data.success){
          setFlashImageId(response?.data?.data.id);
          navigate(-1);
        }
        // navigate(-1);
        setIsLoading(false);
      }
    } catch (error) {
       setIsLoading(false);
      console.error("Error:", error);
    }
  };

  const handleDeleteImage = (imageType: string) => {
    switch (imageType) {
      case "flash":
        setFlashImage(null);
        break;
      default:
        break;
    }
  };

  const handleBackClick = async () => {
    try {
      const response = await getRequest(
        `${baseURL}/lesson/get-fetch-flashcard-components/${flashcardContentId}`
      );
      const flashCardId = response?.data.data[0].flashCardId;
      navigate(`/lessons/theme/subthemes/flashcard/${flashCardId}`);
    } catch (error) {
      console.error("Error fetching themeId:", error);
    }
  };

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-3 main-page">
          <div
            className="d-flex  align-items-center go-back"
            onClick={handleBackClick}
            style={{ width: "fit-content" }}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="me-2"
              style={{
                backgroundColor: "#D9D9D9",
                padding: "7px",
                borderRadius: "8px",
                color: "#000",
                cursor: "pointer",
              }}
            />
            <label
              style={{
                color: "#FF9E00",
                fontSize: "16px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Back
            </label>
          </div>
          <VerticalSpace height={10} />
          <h4
            style={{
              textAlign: "center",
              margin: "auto",
              color: "#7542E4",
              fontWeight: "bold",
            }}
          >
            Add image / svg
          </h4>
          <VerticalSpace height={30} />
          <div className="container" style={{ maxWidth: "1117px" }}>
            <div
              style={{
                backgroundColor: "rgb(220 220 220 / 66%)",
                borderRadius: "30px",
                padding: "18px 20px",
              }}
            >
              {!flashImage && (
                <div>
                  <VerticalSpace height={4} />
                  <div
                    className="drop_box"
                    style={{ margin: "0px", padding: "29px 29px 16px 29px" }}
                  >
                    <header>
                      <h4>Upload a image jpg, png or svg</h4>
                    </header>

                    <input
                      type="file"
                      ref={fileInputRef}
                      hidden
                      accept="image/*"
                      className={`form-control ${
                        errors.flashImage ? "is-invalid" : ""
                      }`}
                      onChange={handleFlashImageChange}
                    />
                    <VerticalSpace height={20} />
                    <button
                      type="button"
                      className="btn btn-outline-secondary me-2 text-center user"
                      style={{
                        color: "#100F15",
                        background: "none",
                        border: "1px solid #100F15",
                        borderRadius: "10px",
                        padding: "14px 30px",
                        fontSize: "14px",
                        width: "auto",
                      }}
                      onClick={handleChooseFileClick}
                    >
                      Choose file
                    </button>
                  </div>
                </div>
              )}
              {flashImage && (
                <div
                  className="drop_box"
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {typeof flashImage === "string" ? (
                      <>
                        {isLoadingImage ? (
                          <Loader />
                        ) : (
                          <>
                            <img
                              src={flashImage}
                              alt="flash-image"
                              style={{
                                maxWidth: "100px",
                                maxHeight: "100px",
                                cursor: "pointer",
                              }}
                              onClick={handleChooseFileClick}
                            />
                            <input
                              type="file"
                              ref={fileInputRef}
                              hidden
                              accept="image/*"
                              onChange={handleFlashImageChange}
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <img
                        src={URL.createObjectURL(flashImage)}
                        alt="flash"
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                    )}
                    <p
                      style={{
                        color: "#000000",
                        marginTop: "0%",
                        marginBottom: "0%",
                      }}
                    >
                      {flashImage.name}
                    </p>{" "}
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleDeleteImage("flash");
                    }}
                  >
                    <img
                      src={deleteIcon}
                      className="user-scale"
                      alt="Delete"
                      style={{ WebkitUserSelect: "none", userSelect: "none" }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className="container row d-flex justify-content-center publish-button"
            style={{
              position: "fixed",
              zIndex: 2,
              height: "12%",
              maxWidth: "100%",
              bottom: "0px",
              background:
                "linear-gradient(178.35deg, rgba(255, 255, 255, 0) 1.4%, rgba(255, 255, 255, 0.69) 26.79%, #FFFFFF 50.22%)",
            }}
          >
            <div
              className="d-flex justify-content-center"
              style={{
                position: "fixed",
                zIndex: 1,
                bottom: "34px",
              }}
            >
              <button
                type="button"
                className="btn btn-success d-flex justify-content-center user"
                style={{
                  backgroundColor: "#FF9E00",
                  color: "#100F15",
                  height: "fit-content",
                  borderRadius: "10px",
                  padding: "10px 20%",
                  width: "400px",
                  fontSize: "14px",
                }}
                onClick={handlePublishClick}
              >
                Publish
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ImageComponents;

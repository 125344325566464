import React from "react";
import Container from "../Common/Container";
import ColumnContainer from "../Common/ColumnContainer";
import RowContainer from "../Common/RowContainer";
import Particles from "../Common/ParticleDesign";
import "../../stylesheets/common.css";
import '../../utils/stringExtention'
interface Conjugation {
  value: string;
  translation: string;
}

interface Data {
  id: number;
  flashCardContentComponentId: number;
  particleType: string;
  particleValue: string;
  conjugation: Conjugation[];
  lightTheme: string;
}

interface ConjugationComponentProps {
  data: Data;
  onClick?: () => void;
}

// Static conjugation types
const conjugationTypes = ["ich", "du", "er/sie/es", "wir", "ihr", "sie/Sie"];

const ConjugationComponent: React.FC<ConjugationComponentProps> = ({
  data,
  onClick,
}) => {
  return (
    <Container
      bgColor={data?.lightTheme === "Light" ? "#FFFFFF" : "#0C0C13"}
      color={data?.lightTheme === "Light" ? "#121217" : "#FFFFFF"}
      width="100%"
      borderRadius="24px"
      padding="16px"
      cursor="pointer"
      onClick={onClick}
    >
      <ColumnContainer>
        <RowContainer alignItem="center" justifyContent="center">
          <Particles
            text={[data?.particleValue ?? "", ""]}
            type={[data?.particleType ?? "", ""]}
            textColor={data?.lightTheme === "Light" ? "#121217" : "#FFFFFF"}
          />
        </RowContainer>
        <ColumnContainer width="100%" gap="0">
          {data?.conjugation && data?.conjugation.length
            ? conjugationTypes.slice(0, 3).map((type, index) => (
                <RowContainer
                  justifyContent="space-between"
                  className="block-text-component"
                
                >
                  <ColumnContainer
                    gap="0px"
                    width="100%"
                    alignItem="flex-start"
                    flex="1"
                  >
                    <RowContainer gap="6px" className="block-text-component">
                      {/* Static type with corresponding conjugation value */}
                      <strong>
                        <div
                          style={{
                            color:
                              data?.lightTheme === "Light"
                                ? "#121217"
                                : "#FFFFFF",
                          }}
                        >
                          {type}
                        </div>
                      </strong>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: (data?.conjugation[index]?.value || "").cleanUpString(),
                        }}
                      />
                    </RowContainer>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: (data?.conjugation[index]?.translation || "").cleanUpString(),
                      }}
                      className="conjugation-component"
                      color="#52525F"
                    />
                  </ColumnContainer>
                  <ColumnContainer
                    gap="0px"
                    width="100%"
                    alignItem="flex-end"
                    justifyContent="flex-end"
                    flex="1"
                  >
                    <RowContainer
                      gap="6px"
                      alignItem="flex-end"
                      justifyContent="flex-end"
                      className="block-text-component"
                    >
                      {/* Static type with corresponding conjugation value */}
                      <strong>
                        <div
                          style={{
                            color:
                              data?.lightTheme === "Light"
                                ? "#121217"
                                : "#FFFFFF",
                          }}
                        >
                          {type}
                        </div>
                      </strong>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: (data?.conjugation[index + 3]?.value || "").cleanUpString(),
                        }}
                      />
                    </RowContainer>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: (data?.conjugation[index + 3]?.translation || "").cleanUpString(),
                      }}
                      className="conjugation-component"
                    />
                  </ColumnContainer>
                </RowContainer>
              ))
            : ""}
        </ColumnContainer>
      </ColumnContainer>
    </Container>
  );
};

export default ConjugationComponent;

import React, { useState } from "react";
import RowContainer from "../Common/RowContainer";
import Particles from "../Common/ParticleDesign";

interface FlashCardComponentData {
  lightTheme?: string | null;
  britzelId?: number | null;
  header?: string | null;
  flashCardContentComponentId?: number | null;
  sentences?: {
    value: string;
    particleValue1?: string | null;
    type: string;
    particleType1?: string | null;
  }[];
  footer?: string | null;
  size?: string | null;
}

interface FlashcardComponent {
  id?: number;
  contentComponentType: string;
  flashCardContentComponentId?: number;
  data?: FlashCardComponentData;
}

interface ExampleComponentProps {
  flashCardComponentData: FlashCardComponentData;
  isHalf?: boolean;
  isLast?: boolean;
  isBretzel?: boolean;
  currentFlashcard?: { components?: FlashcardComponent[] };
  currentFlashCardComponentIndex: number;
  onClick: (id?: number) => void;
}

const ExampleComponent: React.FC<ExampleComponentProps> = ({
  flashCardComponentData,
  isHalf = false,
  isBretzel = false,
  currentFlashcard,
  currentFlashCardComponentIndex,
  isLast,
  onClick,
}) => {
  const sizefullExample = () => {
    return (
      <RowContainer
        bgColor={
          flashCardComponentData.lightTheme === "Light"
            ? "#FFFFFF"
            : isBretzel
            ? "#0C0C13"
            : ""
        }
        padding="8px"
        borderRadius="24px"
        gap="6px"
        flexDirection="column"
        // alignItem="center"
        justifyContent="center"
        cursor="pointer"
        onClick={() => {
          if (
            currentFlashcard?.components?.[currentFlashCardComponentIndex].data
              ?.britzelId
              ? true
              : false
          ) {
            if (
              currentFlashcard?.components?.[currentFlashCardComponentIndex]
                .flashCardContentComponentId
            ) {
              onClick(
                currentFlashcard?.components?.[currentFlashCardComponentIndex]
                  .flashCardContentComponentId
              );
            }
          } else {
            if (flashCardComponentData.flashCardContentComponentId) {
              onClick(
                currentFlashcard?.components?.[currentFlashCardComponentIndex]
                  .id
              );
            }
          }
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: (flashCardComponentData.header || "").cleanUpString(),
          }}
          style={{
            width: "100%",
            opacity: "75%",
          }}
        />

        <RowContainer
          alignItem="center"
          justifyContent="center"
          gap="4px"
          flexWrap="wrap"
        >
          {flashCardComponentData &&
            flashCardComponentData?.sentences?.map((sentence, index) => (
              <Particles
                // key={sentence?.id}
                text={[
                  sentence.value ?? sentence.value ?? "",
                  sentence.particleValue1 ?? "",
                ]}
                type={[sentence.type ?? "", sentence.particleType1 ?? ""]}
                textColor={
                  flashCardComponentData.lightTheme === "Light"
                    ? "#000"
                    : "#FFFFFF"
                }
              />
            ))}
        </RowContainer>

        <div
          dangerouslySetInnerHTML={{
            __html: (flashCardComponentData.footer || "").cleanUpString(),
          }}
          style={{
            width: "100%",
            opacity: "75%",
          }}
        />
      </RowContainer>
    );
  };

  const renderGrid = () => {
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "16px",
          padding: 0,
          width: "100%",
          overflow: "hidden",
        }}
      >
        {getConsecutiveComponents(
          currentFlashcard?.components,
          currentFlashCardComponentIndex
        ).map((component, index) => (
          <>
            <ExampleComponent
              key={index}
              flashCardComponentData={component.data || {}}
              isHalf={false}
              currentFlashCardComponentIndex={index + 1}
              currentFlashcard={currentFlashcard}
              isBretzel={component?.data?.britzelId ? true : false}
              onClick={() => {
                if (component?.data?.britzelId ? true : false) {
                  if (
                    currentFlashcard?.components?.[index + 1]
                      .flashCardContentComponentId
                  ) {
                    onClick(
                      currentFlashcard?.components?.[index + 1]
                        .flashCardContentComponentId
                    );
                  }
                } else {
                  if (component.id) {
                    onClick(component.id);
                  }
                }
              }}
            />
          </>
        ))}
      </div>
    );
  };

  // Helper function to get consecutive components
  const getConsecutiveComponents = (
    components: FlashcardComponent[] | undefined,
    startIndex: number
  ): FlashcardComponent[] => {
    const consecutiveComponents: FlashcardComponent[] = [];

    if (!components || startIndex >= components.length) {
      return consecutiveComponents;
    }

    for (let i = startIndex; i < components.length; i++) {
      const element = components[i];
      if (
        element.contentComponentType === "example" &&
        element.data?.size === "Half"
      ) {
        consecutiveComponents.push(element);
      } else {
        break;
      }
    }

    return consecutiveComponents;
  };

  // Conditionally render if it matches specific criteria
  if (currentFlashCardComponentIndex > 0 && isHalf) {
    const previousComponent =
      currentFlashcard?.components?.[currentFlashCardComponentIndex - 1];
    const nextComponent =
      currentFlashcard?.components?.[currentFlashCardComponentIndex + 1];
    if (
      currentFlashCardComponentIndex ==
      (currentFlashcard?.components?.length ?? 0) - 1
    ) {
      return <div></div>;
    }
    if (
      nextComponent?.contentComponentType === "example" &&
      nextComponent?.data?.size === "Full"
    ) {
      return <div></div>;
    }
    if (
      previousComponent?.contentComponentType === "example" &&
      previousComponent?.data?.size === "Half" &&
      nextComponent?.data?.size === "Half"
    ) {
      return <div></div>;
    }
  }

  return isHalf ? renderGrid() : sizefullExample();
};

export default ExampleComponent;

import React from 'react'
import Container from '../Common/Container';

interface FlashCardImageComponentProps {
  id: number;
  flashCardContentComponentId?: number;
  flashimage: string;
  order: number;
  grammarGroupId?: number;
  britzelId?: number;
  height?: string;
  width?: string;
  onClick?: () => void;
}

const FlashCardImageComponent: React.FC<FlashCardImageComponentProps> = ({
  id,
  flashCardContentComponentId,
  flashimage,
  order,
  grammarGroupId,
  britzelId,
  height,
  width,
  onClick,
}) => {
  return (
    <Container cursor="pointer" onClick={onClick}>
      <img
        src={flashimage}
        className="img-fluid"
        alt="flashimage"
        style={{
          height: height,
          width: width,
          objectFit: "contain",
        }}
      />
    </Container>
  );
};

export default FlashCardImageComponent
// stringExtensions.ts

declare global {
  interface String {
    cleanUpString(): string;
  }
}

// Implement the method
String.prototype.cleanUpString = function(): string {
  return this
    .replaceAll(" ", "")  // Remove unwanted characters
    .replaceAll('<p style="', '<p style="margin:0px;')  // Adjust <p> tag styles
    .replaceAll("<p><br></p>", "");  // Remove empty <p><br></p> tags
};

// Export the module to ensure it gets executed
export {};
import React from "react";
import Particles from "../Common/ParticleDesign";
import ColumnContainer from "../Common/ColumnContainer";

interface Items {
  id: number;
  columnId: number;
  type: string;
  value: string;
  order: number;
  createdAt: string;
  updatedAt: string;
  particleValue?: string;
  particleType1?: string;
  particleValue1?: string;
}

interface Columns {
  id: number;
  particleListId: number;
  column: number;
  title: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  items: Items[];
}

interface DATA {
  id: number;
  flashCardContentComponentId?: number;
  lightTheme: string;
  createdAt: string;
  updatedAt: string;
  columns?: Columns[];
}

interface ParticalListProps {
  data: DATA;
  onClick?: () => void;
}

const ParticalList: React.FC<ParticalListProps> = ({ data, onClick }) => {
  // Extract columns
   const column1 = data.columns?.find((col) => col.column === 1) || null;
   const column2 = data.columns?.find((col) => col.column === 2) || null;
   
  const { lightTheme } = data;

  return (  
    <div
      style={{  
        display: "flex",
        padding: "8px 12px",
        borderRadius: "24px",
        alignItems: "start",
        justifyContent: "space-between",
        backgroundColor: `${lightTheme === "Light" ? "#FFFFFF" : "#0C0C13"}`,
        color: `${lightTheme === "Light" ? "#121217" : "#FFFFFF"}`,
        width: "100%",
        cursor: "pointer",
      }}
      onClick={onClick}  
    >
      {column1?.active && (
        <>
          {/* Left Column */}
          <ColumnContainer flex="1">
            {column1.title && (
              <p
                className="m-0 mb-2"
                style={{ fontWeight: "400", fontSize: "15px" }}
              >
                {column1.title}
              </p>
            )}
            {column1.items.map((item) => (
              <Particles
                key={item.id}
                text={[
                  item.particleValue ?? item.value ?? "",
                  item.particleValue1 ?? "",
                ]}
                type={[item.type ?? "", item.particleType1 ?? ""]}
                textColor={lightTheme === "Light" ? "#121217" : "#FFFFFF"}
              />
            ))}
          </ColumnContainer>
        </>
      )}
      {column2?.active && (
        <>
          {/* Right Column */}
          <ColumnContainer
            flex="1"
            justifyContent="flex-end"
            alignItem="flex-end"
          >
            {column2.title && (
              <p
                className="m-0 mb-2"
                style={{ fontWeight: "400", fontSize: "15px" }}
              >
                {column2.title}
              </p>
            )}
            {column2.items.map((item) => (
              <Particles
                key={item.id}
                text={[
                  item.particleValue ?? item.value ?? "",
                  item.particleValue1 ?? "",
                ]}
                type={[item.type ?? "", item.particleType1 ?? ""]}
                textColor={lightTheme === "Light" ? "#121217" : "#FFFFFF"}
              />
            ))}
          </ColumnContainer>
        </>
      )}
    </div>
  );
};

export default ParticalList;

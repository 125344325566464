import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import deleteIcon from "../../assets/deleteIcon.svg";
import messageIcon from "../../assets/Images/messageIcon.svg";
import VerticalSpace from "../VerticalSpace";
import Label from "../Common/Label";
import RowContainer from "../Common/RowContainer";
import NestedMenu from "../ParticlesDropdown/NestedMenu";
import "../../stylesheets/theme.css";
import Toast from "../Common/Toast";
import { baseURL, getRequest, postRequest } from "../../api/apiClient";
import LessonsDropdown from "../LessonDropdwon/lessonsDropdwon";
import DeleteConfirmation from "../Common/DeleteConfirmation";
import RightSideArrow from "../../assets/RightSideArrow.svg";
import RadioButton from "../Common/RadioButton";
import Loader from "../../utils/Loading";
import Tooltip from "../Common/Tooltip";
import gsap from 'gsap';
import '../../stylesheets/common.css'

interface DropdownValue {
  id: number;
  name: string;
  pId: number;
  particleType: string;
  particleValue: string;
  particleType1: string;
  particleValue1: string;
  children: DropdownValue[];
}

interface Pair {
  id?: number;
  dropdownValue: DropdownValue | null;
  inputValue: string;
  order: number;
}

interface Theme {
  id: number;
  name: string;
}

interface Lesson {
  id: number;
  name: string;
  themes: Theme[];
}

interface Level {
  level?: string;
  lessons: Lesson[];
}

interface TooltipData {
  id?: number;
  notificationId?: number;
  type: string;
  originalWord: string;
  tooltipText: string;
}


const Notifications = () => {
  const { id } = useParams();
  const [question, setQuestion] = useState("");
  const [lessonsData, setLessonsData] = useState<Level[]>([]);
  const [pairs, setPairs] = useState<Pair[]>([]);
  const [notificationId, setNotificationId] = useState("");
  const [level, setLevel] = useState("");
  const [points, setPoints] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    question: "",
    lesson: "",
  });
  const [deleteDialogMessage, setDeleteDialogMessage] = useState("");
  const [onConfirmDelete, setOnConfirmDelete] = useState<() => void>(() => { });
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [pairToDelete, setPairToDelete] = useState<{
    index: number;
    id?: number;
  } | null>(null);
  const [tooltipData, setTooltipData] = useState<TooltipData[]>([]);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [tooltipPosition, setTooltipPosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });
  const [selectedText, setSelectedText] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [validationError, setValidationError] = useState<{
    pairs: {
      [key: number]: {
        dropdownValue?: string;
        inputValue?: string;
      };
    };
  }>({
    pairs: {},
  });

  const navigate = useNavigate();

  useEffect(()=>{
    const handleClickOutside = (event: MouseEvent) => {
      if(tooltipRef.current && !tooltipRef.current.contains(event.target as Node) && showTooltip){
        setShowTooltip(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  },[showTooltip]);

  useEffect(() => {
    fetchNotificationData();
  }, []);

  const fetchNotificationData = async () => {
    try {
      setIsLoading(true);
      let response;
      if (id) {
        response = await getRequest(
          `${baseURL}/notification-challenge/get-notification-challenge-by-id/${id}`
        );
      } else {
        setIsLoading(false);
        return;
      }

      if (response?.data.success) {
        const updatedNotificationData =
          response.data.data.notificationChallenge;
        const particles = response.data.data.particles;
        const tooltips = response.data.data.tooltips;

        setQuestion(updatedNotificationData.question);
        setTooltipData(tooltips);

        const themeName = await fetchThemeById(updatedNotificationData.themeId);
        setLessonsData([
          {
            lessons: [
              {
                id: updatedNotificationData.themeId,
                name: themeName.name,
                themes: [], // Ensure themes are populated as needed
              },
            ],
          },
        ]);
        setLevel(themeName.lessonsLevel);
        setPoints(`${updatedNotificationData.points} pts`);

        const fetchedPairs = await Promise.all(
          particles.map(async (item: any, index: number) => {
            const particleData = await fetchParticleById(item.type);
            return {
              id: item.id,
              dropdownValue: {
                id: particleData.id,
                name: particleData.name,
                pId: particleData.pId,
                particleType: particleData.particleType,
                particleValue: particleData.particleValue,
                particleType1: particleData.particleType1,
                particleValue1: particleData.particleValue1,
                children: particleData.children || [],
              },
              inputValue: item.value,
              order: item.order || index + 1,
            };
          })
        );
        setPairs(fetchedPairs);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleQuestionChange = (value: string) => {
    setQuestion(value);
    setErrors({
      ...errors,
      question: value ? "" : "Please select a question.",
    })
  };


  const handleSelectItem = (item: Lesson) => {
    setLessonsData([{ lessons: [item] }]);
    setErrors({
      ...errors,
      lesson: item ? "" : "Please select a lesson.",
    });
  };

  const addNewPair = () => {
    const newOrder = pairs.length + 1;
    setPairs([
      ...pairs,
      { dropdownValue: null, inputValue: "", order: newOrder },
    ]);
  };

  const handleDropdownChange = (index: number, item: DropdownValue) => {
    const updatedPairs = [...pairs];
    updatedPairs[index].dropdownValue = item;
    const updatedErrors = { ...validationError };
    if (updatedErrors.pairs[index]) {
      updatedErrors.pairs[index].dropdownValue = "";
    } else {
      updatedErrors.pairs[index] = { dropdownValue: "" };
    }
    setPairs(updatedPairs);
    setValidationError(updatedErrors);
  };

  const handleInputChange = (index: number, value: string) => {
    const updatedPairs = [...pairs];
    updatedPairs[index].inputValue = value;
    const updatedErrors = { ...validationError };
    if (updatedErrors.pairs[index]) {
      updatedErrors.pairs[index].inputValue = "";
    } else {
      updatedErrors.pairs[index] = { inputValue: "" };
    }
    setPairs(updatedPairs);
    setValidationError(updatedErrors);
  };

  const removePair = async (index: number, id?: number) => {
    if (id !== undefined) {
      try {
        const reqData = {
          modelName: "notificationChallengeParticles",
          id: id,
        };
        const response = await postRequest(
          `${baseURL}/lesson/delete-bretzel-content`,
          reqData
        );
        if (response?.data) {
          const updatedPairs = [...pairs];
          updatedPairs.splice(index, 1);
          setPairs(updatedPairs);
          Toast(response?.data.statusMessage, "success");
        } else {
          console.error("Error deleting pair:", response?.data);
        }
      } catch (error) {
        console.error("Error deleting pair:", error);
      }
    } else {
      const updatedPairs = [...pairs];
      updatedPairs.splice(index, 1);
      setPairs(updatedPairs);

      const removeErrors = { ...validationError };
      if (removeErrors.pairs) {
        delete removeErrors.pairs[index];
      }
      setValidationError(removeErrors);

    }
  };

  const handleDeletePair = (index: number, id?: number) => {
    if (id !== undefined) {
      setPairToDelete({ index, id });
      setDeleteDialogMessage("Are you sure you want to delete this items?");
      setOnConfirmDelete(() => () => removePair(index, id)); // Pass index and ID to confirm function
      setShowDeleteDialog(true);
    } else {
      removePair(index);
    }
  };

  const handleSave = async () => {
    let hasError = false;

    const validationErrors = {
      question: !question ? "question is required." : "",
      lesson: !lessonsData.length ? "Please select a lesson." : "",
    };

    setErrors(validationErrors);

    if (pairs.length === 0) {
      Toast("Please add at least one pair.", "error");
      return;
    }

    const newErrorsColumn1: {
      [key: number]: { dropdownValue?: string; inputValue?: string };
    } = {};
    pairs.forEach((item, index) => {
      newErrorsColumn1[index] = {};
      if (!item.dropdownValue) {
        newErrorsColumn1[index].dropdownValue = "required";
        hasError = true;
      }
      if (!item.inputValue) {
        newErrorsColumn1[index].inputValue = "required";
        hasError = true;
      }
    });

    setValidationError((prevErrors) => ({
      ...prevErrors,
      pairs: newErrorsColumn1,
    }));

    if (hasError) {
      return;
    }
    if (Object.values(validationErrors).some((error) => error)) {
      return;
    }

    const particles = pairs.map((pair) => ({
      id: pair.id,
      value: pair.inputValue,
      type: pair.dropdownValue?.id || "",
      order: pair.order,
    }));

    const firstLesson = lessonsData[0];

    const themeId = firstLesson.lessons[0].id;
    const requestData = {
      question: question,
      themeId: themeId,
      points: 2,
      particles: particles,
    };
    try {
      setIsLoading(true);
      let response;
      const updateId = notificationId || id;
      if (updateId) {
        response = await postRequest(
          `${baseURL}/notification-challenge/update-notification-challenge/${updateId}`,
          requestData
        );
      } else {
        response = await postRequest(
          `${baseURL}/notification-challenge/create-notification-challenge`,
          requestData
        );

      }

      if (response?.data.success) {
        const updatedNotificationData = response.data.data.challenge;
        const particles = response.data.data.particle;
        setNotificationId(updatedNotificationData.id);
        setQuestion(updatedNotificationData.question);
        setPoints(`${updatedNotificationData.points} pts`);
        const fetchedPairs = await Promise.all(
          particles.map(async (item: any, index: number) => {
            const particleData = await fetchParticleById(item.type);
            return {
              id: item.id,
              dropdownValue: {
                id: particleData.id,
                name: particleData.name,
                pId: particleData.pId,
                particleType: particleData.particleType,
                particleValue: particleData.particleValue,
                particleType1: particleData.particleType1,
                particleValue1: particleData.particleValue1,
                children: particleData.children || [],
              },
              inputValue: item.value,
              order: item.order || index + 1,
            };
          })
        );
        setPairs(fetchedPairs);
        Toast(response?.data.statusMessage, "success");
      }
      setIsLoading(false);
      
    } catch (error) {
      setIsLoading(false);
      console.error("Error handling question:", error);
    }
  };

  const fetchThemeById = async (id: number) => {
    try {
      const response = await getRequest(
        `${baseURL}/notification-challenge/get-themename-by-id/${id}`
      );
      return response?.data.data;
    } catch (error) {
      console.error("Error fetching particle by ID:", error);
      throw error;
    }
  };

  const fetchParticleById = async (id: number) => {
    try {
      const response = await getRequest(
        `${baseURL}/particle/get-particleById/${id}`
      );
      return response?.data.data;
    } catch (error) {
      console.error("Error fetching particle by ID:", error);
      throw error;
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false);
    setPairToDelete(null);
  };

  const tooltipAvailable = (selectedText: string) => {
    return tooltipData.some((tooltip) => tooltip.originalWord === selectedText);
  };

  const calculateTooltipPosition = () => {
    if (inputRef.current) {
      const input = inputRef.current;
      const start = input.selectionStart || 0;
      const end = input.selectionEnd || 0;

      // Get the selected text
      const selected = input.value.substring(start, end)
      setSelectedText(selected);

      if (start !== end && selected) {
        // Create a hidden div with the same text as the input to measure the position
        const div = document.createElement("div");
        document.body.appendChild(div);

        const style = window.getComputedStyle(input);
        div.style.position = "absolute";
        div.style.whiteSpace = "pre-wrap";
        div.style.visibility = "hidden";
        div.style.font = style.font;
        div.style.padding = style.padding;
        div.style.border = style.border;
        div.style.width = `${input.clientWidth}px`;

        // Set the content before the selection
        div.textContent = input.value.substring(0, start);

        const span = document.createElement("span");
        span.textContent = selected;
        div.appendChild(span);

        // Calculate position
        const rect = span.getBoundingClientRect();

        if (window.innerWidth < 769) {
          // Fixed tooltip for mobile view
          setTooltipPosition({
            top: id ? 80 : 56, // Fixed top position for mobile
            left: 151, // Fixed left position for mobile
          });
        } else {
          // Calculate position for larger screens
          setTooltipPosition({
            top: id
              ? rect.top - 740 + window.scrollY
              : rect.top - 765 + window.scrollY, 
            left: rect.left + 50 + window.scrollX,
          });
        }

        // Clean up
        document.body.removeChild(div);
        setShowTooltip(false);
      } else {
        setShowTooltip(false);
      }
    }
  };

  const handleTextSelection = () => {
    calculateTooltipPosition();
  };

  const handleIconClick = () => {
    if (!id && !notificationId) {
      return Toast("Please create a Notification", "error")
    }
    if (selectedText) {
      if (tooltipAvailable(selectedText)) {
        const existingTooltip = tooltipData.find(
          (tooltip) => tooltip.originalWord === selectedText
        );
        setShowTooltip(true);
        setTooltipData(
          tooltipData.map((tooltip) =>
            tooltip.originalWord === selectedText
              ? { ...tooltip, tooltipText: existingTooltip?.tooltipText || '' }
              : tooltip
          )
        );
      } else {
        setShowTooltip(true);
        //  setTooltipData([]);
      }
    } else {
      Toast("Please select any word first", "error");
      setShowTooltip(false);
    }
  };

  const handleSaveMessage = async (
    message: string,
    action: "add" | "update"
  ) => {
    const reqData = {
      challengeId: +(id || notificationId),
      type: "Notification",
      originalWord: selectedText,
      tooltipText: message,
    };
    if (action === 'add') {
      setShowTooltip(false);

      Toast("Tooltip created successfully.", "success");
      const response = await postRequest(
        `${baseURL}/tooltip/create-tooltip`,
        reqData
      );

      if (response?.data) {
        setTooltipData((prevTooltipData) => [
          ...prevTooltipData,
          response?.data.data.tooltip,
        ]);
      }
    } else if (action === "update") {
      const tooltipToUpdate = tooltipData.find(
        (tooltip) => tooltip.originalWord === selectedText
      );

      if (tooltipToUpdate) {

        const updateData = { tooltipText: message };
        setShowTooltip(false);
        Toast("Tooltip updated successfully.", "success");
        const response = await postRequest(
          `${baseURL}/tooltip/update-tooltip/${tooltipToUpdate.id}`,
          updateData
        );

        if (response?.data) {
          setTooltipData((prevTooltipData) =>
            prevTooltipData.map((tooltip) =>
              tooltip.id === tooltipToUpdate.id
                ? { ...tooltip, tooltipText: message }
                : tooltip
            )
          );
        }
      }

    }
  };
  const handleAction = async (action: "close" | "delete") => {
    if (action === "delete") {
      const tooltipToDelete = tooltipData.find(
        (tooltip) => tooltip.originalWord === selectedText
      );

      if (tooltipToDelete) {
        setShowTooltip(false);
        Toast("Tooltip deleted successfully", "success");
        const response = await postRequest(
          `${baseURL}/tooltip/delete-tooltip-by-id/${tooltipToDelete.id}`
        );
        if (response?.data) {
          setTooltipData((prevTooltipData) =>
            prevTooltipData.filter(
              (tooltip) => tooltip.id !== tooltipToDelete.id
            )
          );
        }
      }
    } else if (action === "close") {
      setShowTooltip(false);
      setSelectedText("")
    }
  };

  const handleBackClick = async () => {
    navigate(`/notifications`);
  };

  useEffect(() => {
    let timeLine = gsap.timeline();
    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 1,
      ease: "power2.out",
    });

    


  }, [isLoading]);

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="p-3 main-page">
            <div
              className="d-flex  align-items-center go-back"
              onClick={handleBackClick}
              style={{ width: "fit-content" }}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="me-2"
                style={{
                  backgroundColor: "#D9D9D9",
                  padding: "7px",
                  borderRadius: "8px",
                  color: "#000",
                  cursor: "pointer",
                }}
              />
              <label
                style={{
                  color: "#FF9E00",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Back
              </label>
            </div>
            <VerticalSpace height={15} />
            <h4
              style={{
                textAlign: "center",
                margin: "auto",
                color: "#7542E4",
                fontWeight: "bold",
              }}
              className="row-container"
            >
              Add a notification challenge
            </h4>
            <VerticalSpace height={30} />
            <div
              className="container user-data"
              style={{
                borderRadius: "30px",
                maxWidth: "1117px",
                position: "relative",
              }}
            >
              <div
                className="col-12  p-4"
                style={{
                  borderRadius: "30px 30px 0px 0px",
                  backgroundColor: "#d9d9d9",
                }}
              >
                {level ? (
                  <RowContainer alignItem="center" justifyContent="end">
                    <RadioButton checked={true} />
                    <Label fontSize="14px" fontWeight={400}>
                      {level}
                    </Label>
                  </RowContainer>
                ) : null}
                <Label
                  fontSize="20px"
                  padding="0px 0px 0px 8px"
                  fontWeight={700}
                >
                  Question
                </Label>
                <VerticalSpace height={10} />
                <RowContainer className="notification-container">
                  <RowContainer alignItem="center">
                    <input
                      type="text"
                      className={`form-control ${
                        errors.question ? "is-invalid" : ""
                      }`}
                      ref={inputRef}
                      id="inputthemes"
                      placeholder=""
                      style={{
                        borderRadius: "10px",
                        height: "40px",
                        fontSize: "14px",
                      }}
                      value={question}
                      onChange={(e) => handleQuestionChange(e.target.value)}
                      onMouseUp={handleTextSelection}
                      onKeyUp={handleTextSelection}
                      onDrag={handleTextSelection}
                      onSelect={handleTextSelection}
                    />
                    <div
                      className="p-0"
                      style={{
                        cursor: "pointer",
                        width: "fit-content",
                      }}
                      onClick={handleIconClick}
                    >
                      <img src={messageIcon} className="user-scale" />
                    </div>
                  </RowContainer>
                  {showTooltip && (
                    <div ref={tooltipRef}>
                      <Tooltip
                        position={tooltipPosition}
                        onSaveMessage={handleSaveMessage}
                        onAction={handleAction}
                        tooltipAvailable={tooltipAvailable(selectedText)}
                        tooltipText={
                          selectedText && tooltipData.length > 0
                            ? tooltipData.find(
                                (tooltip) =>
                                  tooltip?.originalWord === selectedText
                              )?.tooltipText || ""
                            : ""
                        }
                      />
                   </div>
                  )}
                  <RowContainer
                    alignItem="center"
                    className="notification-container"
                  >
                    <div className="col-12" style={{ flex: "unset" }}>
                      <LessonsDropdown
                        data={lessonsData as []}
                        onSelectItem={(item) =>
                          handleSelectItem(item as Lesson)
                        }
                        borderColor={errors.lesson ? "#dc3545" : "#D9D9D9"}
                      />
                    </div>
                    <RowContainer alignItem="center" width="70px">
                      <Label fontSize="18px" fontWeight={400}>
                        2 pts
                      </Label>
                      <img src={RightSideArrow} alt="Arrow" />
                    </RowContainer>
                  </RowContainer>
                </RowContainer>
              </div>
              <div
                className="col-12  p-4"
                style={{
                  borderRadius: "0px 0px 30px 30px",
                  backgroundColor: "#d9d9d980",
                }}
              >
                <div className="col d-flex notification-content">
                  <div className="col-6">
                    <Label
                      fontSize="20px"
                      padding="0px 0px 0px 8px"
                      fontWeight={700}
                    >
                      Guide
                    </Label>
                  </div>
                  <Label fontSize="20px" fontWeight={700}>
                    Our example
                  </Label>
                </div>

                <VerticalSpace height={10} />

                <div
                  className="col d-flex flex-column align-itmes-center sentence-example"
                  style={{ padding: "0px 14px 0px 0px", gap: "16px" }}
                >
                  {pairs.map((pair, index) => (
                    <div
                      key={index}
                      className="row sentence-row"
                      style={{ flexWrap: "unset", alignItems: "center" }}
                    >
                      <div
                        className="col sentence-dropdown"
                        style={{ flex: "1" }}
                      >
                        <NestedMenu
                          data={pair.dropdownValue ? [pair.dropdownValue!] : []}
                          onSelectItem={(value) =>
                            handleDropdownChange(index, value as DropdownValue)
                          }
                          borderColor={
                            validationError.pairs[index]?.dropdownValue
                              ? "#dc3545"
                              : "#D9D9D9"
                          }
                        />
                        <div
                          className="p-0  delete-icon"
                          style={{
                            cursor: "pointer",
                            width: "fit-content",
                          }}
                          onClick={() => handleDeletePair(index, pair.id)}
                        >
                          <img
                            src={deleteIcon}
                            className="user-scale"
                            style={{
                              WebkitUserSelect: "none",
                              userSelect: "none",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col sentence-input">
                        <input
                          type="text"
                          value={pair.inputValue || ""}
                          onChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                          className={`form-control ${
                            validationError.pairs[index]?.inputValue
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder=""
                          style={{
                            borderRadius: "10px",
                            height: "40px",
                            fontSize: "14px",
                          }}
                        />
                      </div>
                      <div
                        className="p-0 delete-button"
                        style={{
                          cursor: "pointer",
                          width: "fit-content",
                        }}
                        onClick={() => handleDeletePair(index, pair.id)}
                      >
                        <img
                          src={deleteIcon}
                          className="user-scale"
                          style={{
                            WebkitUserSelect: "none",
                            userSelect: "none",
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <VerticalSpace height={16} />
                <button
                  type="button"
                  className="user"
                  style={{
                    color: "#100F15",
                    background: "none",
                    border: "1px solid #100F15",
                    borderRadius: "10px",
                    padding: "14px 20px",
                    fontSize: "14px",
                    width: "auto",
                  }}
                  onClick={addNewPair}
                >
                  Add new pair
                </button>
              </div>
            </div>
          </div>
          <VerticalSpace height={220} />
          <div
            className="container row d-flex justify-content-center publish-button"
            style={{
              position: "fixed",
              zIndex: 1,
              height: "12%",
              maxWidth: "100%",
              bottom: "0px",
              background:
                "linear-gradient(178.35deg, rgba(255, 255, 255, 0) 1.4%, rgba(255, 255, 255, 0.69) 26.79%, #FFFFFF 50.22%)",
            }}
          >
            <div
              className="d-flex justify-content-center"
              style={{
                position: "fixed",
                zIndex: 1,
                bottom: "34px",
              }}
            >
              <button
                type="button"
                className="btn btn-success d-flex justify-content-center user"
                style={{
                  backgroundColor: "#FF9E00",
                  color: "#100F15",
                  height: "fit-content",
                  borderRadius: "10px",
                  padding: "10px 20%",
                  width: "400px",
                  fontSize: "14px",
                }}
                onClick={handleSave}
              >
                Publish
              </button>
            </div>
          </div>
        </>
      )}

      {showDeleteDialog && (
        <DeleteConfirmation
          showDeletePopup={true}
          setShowDeletePopup={setShowDeleteDialog}
          message={deleteDialogMessage}
          deleteButtonLabel="Delete"
          onDelete={onConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};
export default Notifications;

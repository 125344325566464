import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../assets/css/sidebar/style.css";
import Logo from "../assets/Images/logo/logo.svg";
import VerticalSpace from "../Components/VerticalSpace";
import Home from "../assets/Images/SidebarIcon/home.png";
import setting from "../assets/Images/SidebarIcon/setting.svg";
import user from "../assets/Images/SidebarIcon/user.svg";
import question from "../assets/Images/SidebarIcon/question.svg";
import memberp from "../assets/Images/SidebarIcon/memberPri.svg";
import SideBarIcon from "../Components/Common/SidebarIcon";
import ParticalIcon from "../assets/Images/SidebarIcon/particle.svg";
import notificationIcon from "../assets/Images/SidebarIcon/notificationIcon.svg";
import Lesson from "../assets/Images/SidebarIcon/lessons.svg";
import TextFieldsIcon from "../assets/Images/SidebarIcon/textFieldsIcon.svg";
import LogOutIcon from "../assets/logout.svg";
import DragDropIcon from "../assets/Images/SidebarIcon/drag&dropwordsIcon.svg";
import TicketIcon from "../assets/Images/SidebarIcon/ticket.svg";
import DragDropWords from "../assets/Images/SidebarIcon/drag&dropwords.svg";
import multipleOptionIcon from "../assets/Images/SidebarIcon/multipleOptions.svg";
import messageIcon from "../assets/Images/SidebarIcon/messageIcon.svg";
import configIcon from "../assets/Images/configIcon.svg";
import InformationIcon from "../assets/Images/SidebarIcon/informationIcon.svg"
import { SidebarContext } from "../hooks/UseSidebar";
import DeleteConfirmation from "../Components/Common/DeleteConfirmation";

// import "../assets/css/ImageWithHoverEffect.css";

interface SidebarProps {
  onLinkClick: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ onLinkClick }) => {
  const location = useLocation();
  const { side } = useContext(SidebarContext);
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const navigate = useNavigate();

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  // Function to check if the current path is a child of the provided parent path
  const isChildActive = (parentPath: string) => {
    return location.pathname.startsWith(parentPath);
  };

  const handleLogoutConfirmation = () =>{
    setShowLogoutDialog(true);
  };

  const handleLogout = () => {
    localStorage.clear();
    setShowLogoutDialog(false);
    // navigate("/login");
    window.location.href = "https://admin.lexik.app/login";
  };

  return (
    <>
      <div className={`sidebar ${side ? "visible" : "hidden"}`}>
        <div className="row vh-100 overflow-auto">
          <div
            className="col-12 d-flex sticky-top h-sm-100"
            style={{
              borderRadius: "0px 20px 20px 0px",
              // padding: "10px",
            }}
          >
            <div className="sidebar-content d-flex flex-sm-column flex-row flex-grow-1 align-items-center px-2 pt-1 ">
              <a
                href="/"
                className="d-flex align-items-center pb-sm-3 mb-md-0 text-white"
                style={{
                  borderBottom: "1px solid gray",
                  paddingBottom: "10px",
                }}
              >
                <img
                  src={Logo}
                  alt="Logo"
                  style={{
                    height: "34px",
                    width: "36px",
                    margin: "10px",
                    WebkitUserSelect: "none",
                    userSelect: "none",
                  }}
                />
              </a>
              <VerticalSpace height={20} />

              <ul
                className="sidebar-menu nav nav-pills flex-sm-column d-flex align-itmes-center justify-content-center"
                // id="menu"
                // style={{
                //   gap: "2px",
                // }}
              >
                <li className="nav-item">
                  <li>
                    <SideBarIcon
                      image={Home}
                      toScreen="/"
                      className={
                        isActive("/") ? "active text-white" : "default-color"
                      }
                      tooltipText="Dashboard"
                      onLinkClick={onLinkClick}
                    />
                  </li>
                </li>
                <li className="nav-item">
                  <li>
                    <SideBarIcon
                      image={memberp}
                      toScreen="/members"
                      className={
                        isActive("/members") || isChildActive("/members")
                          ? "active text-white"
                          : "default-color"
                      }
                      tooltipText="Member"
                      onLinkClick={onLinkClick}
                    />
                  </li>
                </li>
                <li className="nav-item">
                  <li>
                    <SideBarIcon
                      image={user}
                      toScreen="/users"
                      className={
                        isActive("/users") || isChildActive("/users")
                          ? "active text-white"
                          : "default-color"
                      }
                      tooltipText="User"
                      onLinkClick={onLinkClick}
                    />
                  </li>
                </li>
                <li className="nav-item">
                  <li>
                    <SideBarIcon
                      image={Lesson}
                      toScreen="/lessons"
                      className={
                        isActive("/lessons") || isChildActive("/lessons")
                          ? "active text-white"
                          : "default-color"
                      }
                      tooltipText="Lessons"
                      onLinkClick={onLinkClick}
                    />
                  </li>
                </li>
                <li className="nav-item">
                  <li>
                    <SideBarIcon
                      image={notificationIcon}
                      toScreen="/notifications"
                      className={
                        isActive("/notifications") ||
                        isChildActive("/notifications")
                          ? "active text-white"
                          : "default-color"
                      }
                      tooltipText="Notification"
                      onLinkClick={onLinkClick}
                    />
                  </li>
                </li>
                <li className="nav-item">
                  <li>
                    <SideBarIcon
                      image={TextFieldsIcon}
                      toScreen="/textfields"
                      className={
                        isActive("/textfields") || isChildActive("/textfields")
                          ? "active text-white"
                          : "default-color"
                      }
                      tooltipText="TextFields"
                      onLinkClick={onLinkClick}
                    />
                  </li>
                </li>
                <li className="nav-item">
                  <li>
                    <SideBarIcon
                      image={DragDropWords}
                      toScreen="/drag-drop-words"
                      className={
                        isActive("/drag-drop-words") ||
                        isChildActive("/drag-drop-words")
                          ? "active text-white"
                          : "default-color"
                      }
                      tooltipText="Drag-drop-words"
                      onLinkClick={onLinkClick}
                    />
                  </li>
                </li>
                <li className="nav-item">
                  <li>
                    <SideBarIcon
                      image={DragDropIcon}
                      toScreen="/drag-drop-colors"
                      className={
                        isActive("/drag-drop-colors") ||
                        isChildActive("/drag-drop-colors")
                          ? "active text-white"
                          : "default-color"
                      }
                      tooltipText="Drag-drop-colors"
                      onLinkClick={onLinkClick}
                    />
                  </li>
                </li>
                <li className="nav-item">
                  <li>
                    <SideBarIcon
                      image={multipleOptionIcon}
                      toScreen="/multiple-options"
                      className={
                        isActive("/multiple-options") ||
                        isChildActive("/multiple-options")
                          ? "active text-white"
                          : "default-color"
                      }
                      tooltipText="Multiple-options"
                      onLinkClick={onLinkClick}
                    />
                  </li>
                </li>
                <li className="nav-item">
                  <li>
                    <SideBarIcon
                      image={question}
                      toScreen="/questions"
                      className={
                        isActive("/questions") || isChildActive("/questions")
                          ? "active text-white"
                          : "default-color"
                      }
                      tooltipText="Question"
                      onLinkClick={onLinkClick}
                    />
                  </li>
                </li>
                <li className="nav-item">
                  <li>
                    <SideBarIcon
                      image={TicketIcon}
                      toScreen="/tickets"
                      className={
                        isActive("/tickets") || isChildActive("/ticket")
                          ? "active text-white"
                          : "default-color"
                      }
                      tooltipText="Tickets"
                      onLinkClick={onLinkClick}
                    />
                  </li>
                </li>
                <li className="nav-item">
                  <li>
                    <SideBarIcon
                      image={messageIcon}
                      toScreen="/messages"
                      className={
                        isActive("/messages") || isChildActive("/message")
                          ? "active text-white"
                          : "default-color"
                      }
                      tooltipText="Messages"
                      onLinkClick={onLinkClick}
                    />
                  </li>
                </li>
                <li className="nav-item">
                  <li>
                    <SideBarIcon
                      image={configIcon}
                      toScreen="/configs"
                      className={
                        isActive("/configs") || isChildActive("/configs")
                          ? "active text-white"
                          : "default-color"
                      }
                      tooltipText="Configs"
                      onLinkClick={onLinkClick}
                    />
                  </li>
                </li>
                <li className="nav-item">
                  <li>
                    <SideBarIcon
                      image={ParticalIcon}
                      toScreen="/particles"
                      className={
                        isActive("/particles") || isChildActive("/particles")
                          ? "active text-white"
                          : "default-color"
                      }
                      tooltipText="Particles"
                      onLinkClick={onLinkClick}
                    />
                  </li>
                </li>
                <li className="nav-item">
                  <li>
                    <SideBarIcon
                      image={InformationIcon}
                      toScreen="/app-supports"
                      className={
                        isActive("/app-supports") || isChildActive("/app-supports")
                          ? "active text-white"
                          : "default-color"
                      }
                      tooltipText="App Supports"
                      onLinkClick={onLinkClick}
                    />
                  </li>
                </li>
              </ul>

              <div className="sidebar-bottem py-sm-3 mt-sm-auto ms-auto ms-sm-0 flex-shrink-1">
                <ul>
                  {/* <li className="nav-item">
                  <li>
                    <SideBarIcon
                      image={setting}
                      toScreen=""
                      className={
                        isActive("") ? "active text-white" : "default-color"
                      }
                      tooltipText="Setting"
                      onLinkClick={onLinkClick}
                    />
                  </li>
                </li> */}
                  {/* LogOutIcon */}
                  <li className="nav-item">
                    <li>
                      <SideBarIcon
                        image={LogOutIcon}
                        toScreen=""
                        className={
                          isActive("") ? "active text-white" : "default-color"
                        }
                        tooltipText="Logout"
                        onLinkClick={(e) => {
                          e.preventDefault();
                          handleLogoutConfirmation();
                        }}
                      />
                      {/* <img
                        src={LogOutIcon}
                        alt="Logo"
                        style={{
                          height: "34px",
                          width: "36px",
                          margin: "10px",
                          WebkitUserSelect: "none",
                          userSelect: "none",
                        }}
                        onClick={handleLogoutConfirmation}
                      /> */}
                    </li>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showLogoutDialog && (
        <DeleteConfirmation
          showDeletePopup={true}
          setShowDeletePopup={setShowLogoutDialog}
          message={"Are you sure you want to log out?"}
          deleteButtonLabel="Logout"
          onDelete={handleLogout}
        />
      )}
    </>
  );
};

export default Sidebar;

export function dotformatDate(dateString: any) {
  
  const cleanedDateString = dateString.replace(/(\d+)(st|nd|rd|th)/, "$1");
  
  const dateWithFullYear = cleanedDateString.replace(/(\d{2})$/, "20$1");

  const date = new Date(dateWithFullYear);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

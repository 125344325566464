import React, { useState, useRef, useEffect } from "react";
import "../../assets/css/members.css";
import RowContainer from "../../Components/Common/RowContainer";
import Container from "../../Components/Common/Container";
import Label from "../../Components/Common/Label";
import ColumnContainer from "../../Components/Common/ColumnContainer";
import MembershipUser from "../../Components/membershipUser";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CustomDropdown from "../../Components/Common/CustomDropdown";
import "../../assets/fonts/font.css";
import "../../stylesheets/indexpage.css";
import VerticalSpace from "../../Components/VerticalSpace";
import PaginationComponent from "../../Components/Common/PaginationComponent";
import { baseURL, getRequest, postRequest } from "../../api/apiClient";
import Toast from "../../Components/Common/Toast";
import gsap from "gsap";
import "../../stylesheets/common.css";
import Loader from "../../utils/Loading";
import ShimmerEffect from "../../utils/ShimmerEffect";
import formatDate from "../../Components/DateFormat";
import DeleteConfirmation from "../../Components/Common/DeleteConfirmation";

const ScrollableRowContainer = styled(RowContainer)`
  @media (max-width: 1150px) {
    width: 100%;
    overflow-x: auto;
  }
`;

const InnerRowContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 1100px; /* Ensures minimum width to trigger horizontal scrolling */
`;

interface Member {
  id: number;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  membership: string;
  subscription: Subscription;
  active: boolean;
}
interface Subscription {
  startDate: string;
  endDate: string;
}

const MemberShipUsers = () => {
  const [users, setUsers] = useState<Member[]>([]);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [filterLoading, setFilterLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState<number[]>([]);
  const [selectedMemberId, setSelectedMemberId] = useState<number | null>(
    null
  );
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(5);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
   const [filters, setFilters] = useState<{
     startDate?: string;
     endDate?: string;
   }>({});
  const navigate = useNavigate();

  const dropdownItems: string[] = ["All", "Since", "Until"];

  const handleSelect = (item: string) => {
    setSelectedItem(item);
  };

  // Mock data
  useEffect(() => {
    fetchMember(page + 1, limit, filters);
  }, [page, limit, filters]);
  
  const fetchMember = async (
    page: number,
    perPage: number,
    filters: { startDate?: string; endDate?: string }
  ) => {
    if (!filters) {
      setLoading(true);
    } else {
      setFilterLoading(true);
    }
    try {
      let url = `${baseURL}/users/get-users?isPremium=${true}&page=${page}&limit=${perPage}`;
      if (filters.startDate) {
        url += `&startDate=${filters.startDate}`;
      }

      if (filters.endDate) {
        url += `&endDate=${filters.endDate}`;
      }

      const response = await getRequest(url);
      if (response?.data) {
        const modifiedUsers = response.data.data.users.map((user: Member) => {
          const fullNameParts = user.fullName.split(" ");
          const firstName = fullNameParts[0];
          const lastName = fullNameParts.slice(1).join(" ");
          return { ...user, firstName, lastName };
        });
        setUsers(modifiedUsers);
        setCount(response.data.data.totalUserCount);
        setPage(response.data.data.page - 1);
        setHasNextPage(response.data.data.hasNextPage);
        setHasPreviousPage(response.data.data.hasPreviousPage);
      }
      setLoading(false);
      setFilterLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleApplyFilters = () => {
    switch (selectedItem) {
      case "Since":
        setFilters({ startDate: "ASC" });
        break;
      case "Until":
        setFilters({ endDate: "DESC" });
        break;
      default:
        setFilters({});
        break;
    }
  };

  const handleEditMember = (id: number) => {
    navigate(`/members/edit-member/${id}`);
  };

   const handleStatusChange = async (id: number, active: boolean) => {
     const reqData = {
       modelName: "users",
       id: id,
       isActive: !active,
     };
     await postRequest(`${baseURL}/change-status`, reqData);
     // await fectMember(page + 1, limit, filters);
   };

  const onCheckboxChange = (id: number) => {
    if (selectedMembers.includes(id)) {
      setSelectedMembers(
        selectedMembers.filter((selectedId) => selectedId !== id)
      );
    } else {
      setSelectedMembers([...selectedMembers, id]);
    }
  };

  const deleteSelectedMembers = () => {
    if (selectedMembers.length > 0) {
      setShowDeleteDialog(true);
    } else {
      Toast("Please select at least one member to delete.", "error");
    }
  };

  const deleteMembers = async (memberIds: number | number[]) => {
    try {
      const payload = {
        id: Array.isArray(memberIds) ? memberIds : memberIds,
      };
      const response = await postRequest(
        `${baseURL}/members-challenge/delete-members-challenge-by-id`,
        payload
      );

      if (response?.status === 200) {
        Toast("memberss deleted successfully", "success");
        setUsers((prevData) =>
          prevData.filter((members) =>
            Array.isArray(memberIds)
              ? !memberIds.includes(members.id)
              : members.id !== memberIds
          )
        );
      } else {
        Toast("Failed to delete notifications", "error");
      }
    } catch (error) {
      console.error("Error deleting notifications:", error);
    }
  };

  const handleDelete = async () => {
    if (selectedMemberId !== null) {
      await deleteMembers(selectedMemberId);
    } else {
      await deleteMembers(selectedMembers);
    }
    // await fetchTextFields(page + 1, limit, filters);
    setSelectedMembers([]);
    setSelectedMemberId(null);
    setShowDeleteDialog(false);
  };

  const toggleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  useEffect(() => {
    let timeLine = gsap.timeline();

    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 0.3,
      ease: "power2.out",
    });

    timeLine.from(".user-data", {
      opacity: 0,
      y: 40,
      duration: 0.5,
      ease: "power2.out",
      stagger: 0.2,
    });
  }, [loading]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="container-fluid">
            <div className="p-3">
              {/* Heading */}
              <div className="row mb-3 member">
                <div className="col">
                  <h2 style={{ fontWeight: "bold" }}>Members Premium</h2>
                </div>
              </div>

              {/* Filter Label */}
              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="filter" className="form-label member">
                    Filter
                  </label>
                </div>
              </div>

              {/* Buttons Row */}
              <div className="row mb-3 main-responsive">
                <div className="col d-flex responsive left-buttons">
                  <CustomDropdown
                    items={dropdownItems}
                    onSelect={handleSelect}
                    selectedItem={selectedItem || ""}
                    height="50px"
                  />
                  <button
                    type="button"
                    className="btn btn-primary ms-3 user"
                    style={{
                      backgroundColor: "#100F15",
                      borderRadius: "10px",
                      color: "#FFFFFF",
                      fontSize: "14px",
                      width: "auto",
                      padding: "14px 50px",
                    }}
                    onClick={handleApplyFilters}
                  >
                    Apply
                  </button>
                </div>
                <div className="col d-flex justify-content-end responsive left-buttons">
                  {/* <button
              type="button"
              className="btn btn-outline-secondary me-2 text-center user"
              style={{
                color: "#100F15",
                background: "none",
                border: "1px solid #100F15",

                borderRadius: "10px",
                fontSize: "14px",
                width: "auto",
                padding: "14px 40px",
              }}
              onClick={deleteSelectedMembers}
            >
              Delete all
            </button> */}
                  {/* <button
              type="button"
              className="btn btn-success user"
              style={{
                backgroundColor: "#FF9E00",
                borderRadius: "10px",
                color: "#100F15",
                fontSize: "14px",
                width: "auto",
                padding: "14px 40px",
              }}
            >
              Add now
            </button> */}
                </div>
              </div>

              <ScrollableRowContainer>
                <InnerRowContainer>
                  <Container width="100%">
                    <RowContainer
                      alignItem="center"
                      padding="8px 12px 8px 12px"
                      gap="8px"
                      className="row-container"
                    >
                      <RowContainer flex="0.7">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        ></Label>
                      </RowContainer>
                      <RowContainer flex="2">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          ID
                        </Label>
                      </RowContainer>
                      <RowContainer flex="4">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Name
                        </Label>
                      </RowContainer>
                      <RowContainer flex="4">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Lastname
                        </Label>
                      </RowContainer>
                      <RowContainer flex="7">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          email
                        </Label>
                      </RowContainer>
                      {/* <RowContainer flex="5">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Membership
                        </Label>
                      </RowContainer> */}
                      <RowContainer flex="4">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Since
                        </Label>
                      </RowContainer>
                      <RowContainer flex="4">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Until
                        </Label>
                      </RowContainer>
                      <RowContainer flex="3">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Action
                        </Label>
                      </RowContainer>
                    </RowContainer>
                    {filterLoading ? (
                      <>
                        <RowContainer
                          bgColor="#ffffff"
                          borderRadius="8px"
                          padding="10px 0px"
                        >
                          {Array.from({ length: 6 }, (_, index) => {
                            return (
                              <RowContainer
                                key={index}
                                width={
                                  index === 0 || index === 5 ? "10%" : "20%"
                                }
                                display="flex"
                                justifyContent="center"
                              >
                                <ShimmerEffect />
                              </RowContainer>
                            );
                          })}
                        </RowContainer>
                      </>
                    ) : (
                      <>
                        {users && users.length > 0 ? (
                          <ColumnContainer>
                            {users.map((member, index) => (
                              <MembershipUser
                                srNo={page * limit + index + 1}
                                key={member.id}
                                id={member.id}
                                name={member.firstName}
                                lastname={member.lastName || "N/A"}
                                email={member.email}
                                membership={member.membership}
                                since={member.subscription ? formatDate(
                                  member.subscription.startDate
                                ) : 'N/A'}
                                until={member.subscription ? formatDate(member.subscription.endDate ) :  'N/A' }
                                onEdit={handleEditMember}
                                onDelete={() => {
                                  setSelectedMemberId(member.id);
                                  toggleDeleteDialog();
                                }}
                                isChecked={selectedMembers.includes(member.id)}
                                onCheckboxChange={onCheckboxChange}
                                status={member.active}
                                onStatusChange={() =>
                                  handleStatusChange(
                                    member.id,
                                    member.active
                                  )
                                }
                              />
                            ))}
                          </ColumnContainer>
                        ) : (
                          <>
                            <VerticalSpace height={30} />
                            <Label
                              alignItems="center"
                              display="flex"
                              color="rgba(164, 163, 167, 1)"
                              justifyContent="center"
                            >
                              No results found.
                            </Label>
                          </>
                        )}
                      </>
                    )}
                  </Container>
                </InnerRowContainer>
              </ScrollableRowContainer>
              <VerticalSpace height={10} />
              <RowContainer justifyContent="flex-end" className="user-data">
                <PaginationComponent
                  page={page}
                  rowsPerPage={limit}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  count={count}
                  hasNextPage={hasNextPage}
                  hasPreviousPage={hasPreviousPage}
                />
              </RowContainer>
            </div>
            {showDeleteDialog && (
              <DeleteConfirmation
                showDeletePopup={true}
                setShowDeletePopup={setShowDeleteDialog}
                message={`Are you sure you want to delete ${
                  selectedMembers.length === 0
                    ? "this member?"
                    : selectedMembers.length === 1
                    ? "this members?"
                    : "these members?"
                }`}
                deleteButtonLabel="Delete"
                onDelete={handleDelete}
              />
            )}
            <VerticalSpace height={110} />
          </div>
        </>
      )}
    </>
  );
};

export default MemberShipUsers;

import React, { useState } from "react";
import Label from "./Common/Label";
import RowContainer from "./Common/RowContainer";
import Container from "./Common/Container";
import HorizontalSpace from "./HorizontalSpace";

interface Activity {
  id: number;
  name: string;
  progress: string;
  duration: string;
  startdate: string;
  type: boolean;
  level: string;
  // points: string;
}


const MembershipActivity: React.FC<Activity> = ({
  id,
  name,
  progress,
  duration,
  startdate,
  type,
  level,
  // points,
}) => {
  return (
    <Container
      bgColor="#fff"
      borderRadius="10px"
      height="50px"
      alignItems="center"
      display="flex"
      // width="696px"
      width="100%"
      className="user"
    >
      <RowContainer
        justifyContent="space-between"
        alignItem="center"
        borderRadius="12px"
        // gap="3.6px"
        margin="10px"
      >
        <RowContainer flex="6.5" gap="0px">
          <Label fontSize="14px" fontWeight={400}>
            {name}
          </Label>
        </RowContainer>
        <RowContainer flex="4" gap="0px">
          <Label fontSize="14px" fontWeight={400}>
            {progress}
          </Label>
        </RowContainer>
        <RowContainer flex="4" gap="0px">
          <Label fontSize="14px" fontWeight={400}>
            {duration}
          </Label>
        </RowContainer>
        <RowContainer flex="5" gap="0px">
          <Label fontSize="14px" fontWeight={400}>
            {startdate}
          </Label>
        </RowContainer>
        <RowContainer flex="5" gap="0px" alignItem="center">
          {type ? (
            <Label
              fontSize="10px"
              color="#FFFFFF"
              fontWeight={400}
              cursor="pointer"
              bgColor="#28A138"
              justifyContent="center"
              display="flex"
              width="82px"
              borderRadius="40px"
              padding="8px 13px"
            >
              Lessonn
            </Label>
          ) : (
            <Label
              fontSize="10px"
              color="#FFFFFF"
              fontWeight={400}
              cursor="pointer"
              bgColor="#00A3FF"
              justifyContent="center"
              display="flex"
              width="82px"
              borderRadius="40px"
              padding="8px 13px"
            >
              Challenge
            </Label>
          )}
        </RowContainer>

        <RowContainer flex="1.8" gap="0px">
          <Label fontSize="14px" fontWeight={400}>
            {level}
          </Label>
        </RowContainer>
        {/* <RowContainer flex="2.5" gap="0px">
          <Label fontSize="14px" color="#7542E4" fontWeight={400}>
            {points}
          </Label>
        </RowContainer> */}
      </RowContainer>
    </Container>
  );
};
export default MembershipActivity;

import React, { useState } from "react";
import Label from "../Common/Label";
import RowContainer from "../Common/RowContainer";
import Container from "../Common/Container";
import editIcon from "../../assets/editIcon.svg";
import deleteIcon from "../../assets/deleteIcon.svg";
import styled from "styled-components";
import CustomCheckbox from "../Common/CustomCheckbox";
import Switch from "../Common/Switch";
import StatusChangeConfirmation from "../Common/StatusUpdateConfirmation";

interface RankingLevels {
  id: number;
  level: string;
  label: string;
  challenges_tag: string;
  latestdate: string;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  onCheckboxChange: (id: number) => void;
  isChecked: boolean;
  status: boolean;
  onStatusChange: (id: number) => void;
}

const NewRowContainer = styled(RowContainer)`
  overflow: hidden;
`;

const NewLabel = styled(Label)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const RankingLevels: React.FC<RankingLevels> = ({
  id,
  level,
  label,
  challenges_tag,
  latestdate,
  onEdit,
  onDelete,
  onCheckboxChange,
  isChecked,
  status,
  onStatusChange
}) => {
  const [isSwitchOn, setIsSwitchOn] = useState(status);
  const [showStatusPopup, setShowStatusPopup] = useState(false);

  const toggleSwitch = () => {
    setShowStatusPopup(true);
  };

  const handleUpdateStatus = () => {
    const newStatus = !isSwitchOn;
    setIsSwitchOn(newStatus);
    onStatusChange(id);
    setShowStatusPopup(false);
  };

  const handleDelete = () => {
    onDelete(id);
  };

  const handleCancelStatusChange = () => {
    setShowStatusPopup(false);
  };

  return (
    <>
      <Container
        bgColor="#fff"
        borderRadius="10px"
        height="50px"
        alignItems="center"
        display="flex"
        width="100%"
        className="user-data user-container-hover"
      >
        <RowContainer
          justifyContent="space-between"
          alignItem="center"
          borderRadius="12px"
          margin="10px"
        >
          <RowContainer flex="1.3">
            {/* <CustomCheckbox
            checked={isChecked}
            onChange={() => onCheckboxChange(id)}
          /> */}
          </RowContainer>
          <NewRowContainer gap="0px" flex="9">
            <NewLabel fontSize="16px" fontWeight={400}>
              {level}
            </NewLabel>
          </NewRowContainer>
          <NewRowContainer gap="0px" flex="7">
            <NewLabel fontSize="16px" fontWeight={400}>
              {label}
            </NewLabel>
          </NewRowContainer>
          <NewRowContainer flex="4.8">
            <NewLabel fontSize="16px" fontWeight={400}>
              {challenges_tag}
            </NewLabel>
          </NewRowContainer>
          <NewRowContainer flex="6.1">
            <NewLabel fontSize="16px" fontWeight={400}>
              {latestdate}
            </NewLabel>
          </NewRowContainer>
          <RowContainer flex="3.3">
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ gap: "10px" }}
            >
              <Switch isOn={isSwitchOn} onToggle={toggleSwitch} />
              <div style={{ cursor: "pointer" }}>
                <img
                  src={editIcon}
                  className="user-scale"
                  onClick={() => onEdit(id)}
                  style={{ WebkitUserSelect: "none", userSelect: "none" }}
                />
              </div>
              <div style={{ cursor: "pointer" }} onClick={handleDelete}>
              <img src={deleteIcon} style={{ WebkitUserSelect: "none", userSelect: "none" }}/>
            </div>
            </div>
          </RowContainer>
        </RowContainer>
      </Container>

      {showStatusPopup && (
        <StatusChangeConfirmation
          showStatusPopup={showStatusPopup}
          setShowStatusPopup={setShowStatusPopup}
          message={`Are you sure you want to ${
            isSwitchOn ? "deactivate" : "activate"
          } this lesson?`}
          statusButtonLabel={isSwitchOn ? "Deactivate" : "Activate"}
          onStatus={handleUpdateStatus}
          onCancel={handleCancelStatusChange}
        />
      )}
    </>
  );
};
export default RankingLevels;

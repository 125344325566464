import Container from "../Common/Container";
import "../../stylesheets/common.css";
import RowContainer from "../Common/RowContainer";
import Label from "../Common/Label";
import ColumnContainer from "../Common/ColumnContainer";
import formatUsersCount from "../formatUsersCount";
import styled from "styled-components";
// import "../../stylesheets/LessonCardProgress.css";

interface courses {
  level: string;
  users: string | number;
  progress: number;
}

interface lessonsProgress {
  totalLessons: string;
  lastMonthLessons: string | number;
  courses: courses[];
}

interface LessonCountProps {
  lessonsCount: lessonsProgress[];
}

const getLevelBackgroundColor = (level: string) => {
  switch (level) {
    case "A1":
      return "#00A3FF";
    case "A2":
      return "#FF9E00";
    case "B1":
      return "#592ACB";
    default:
      return "#121217";
  }
};

const NewRowContainer = styled(RowContainer)`
  @media (max-width: 510px) {
    flex-wrap: wrap;
    gap: 30px;
  }
`;


const LessonCount: React.FC<LessonCountProps> = ({ lessonsCount }) => {
  return (
    <Container
      className="user-box left-container"
      borderRadius="24px"
      padding="15px"
      bgColor="#FFFFFF"
      width="100%"
    >
      {/* Lessons, Courses, and Completion Data */}
      {lessonsCount.map((lesson, lessonIndex) => (
        <NewRowContainer
          key={lessonIndex}
          alignItem="center"
          justifyContent="space-between"
          padding="7px 0"
        >
          {/* Lessons Section */}
          <ColumnContainer flex="3" gap="0px">
            <Label fontSize="20px" fontWeight={700} color="#323235">
              Lessons
            </Label>
            <Label className="dashboaed-scale" fontSize="48px" fontWeight={700} color="#FF9E00">
              {lesson.totalLessons}
            </Label>
            <Label
              fontSize="11px"
              fontWeight={600}
              color="#FFFFFF"
              bgColor="#404040"
              borderRadius="12px"
              width="max-content"
              padding="6px 13px"
              className="dashboaed-scale"
            >
              {lesson.lastMonthLessons} lessons added last month
            </Label>
          </ColumnContainer>

          {/* Courses Section */}
          <ColumnContainer flex="3" gap="15px">
            <Label fontSize="20px" fontWeight={700} color="#323235">
              Courses
            </Label>
            {lesson.courses.map((course, courseIndex) => (
              <RowContainer className="dashboaed-scale" key={courseIndex} alignItem="center" gap="20px">
                <Label
                  fontSize="12px"
                  fontWeight={700}
                  color="#FFFFFF"
                  borderRadius="12px"
                  bgColor={getLevelBackgroundColor(course.level)}
                  width="31px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {course.level}
                </Label>
                <Label
                  fontSize="13px"
                  fontWeight={700}
                  color="#121217"
                  width="max-content"
                >
                  {formatUsersCount(Number(course.users))} users
                </Label>
              </RowContainer>
            ))}
          </ColumnContainer>

          {/* Completion Section */}
          <ColumnContainer flex="3" gap="15px">
            <Label fontSize="20px" fontWeight={700} color="#323235">
              Completion
            </Label>
            {lesson.courses.map((course, courseIndex) => (
              <RowContainer className="dashboaed-scale" key={courseIndex} alignItem="center">
                <Label
                  fontSize="14px"
                  fontWeight={700}
                  color={getLevelBackgroundColor(course.level)}
                >
                  {course.progress}%
                </Label>
                <div
                  className="dashboaed-scale"
                  style={{
                    width: "100%",
                    height: "10px",
                    backgroundColor: "#E0E0E0", 
                    borderRadius: "5px",
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: `${course.progress}%`,
                      height: "100%",
                      backgroundColor: getLevelBackgroundColor(course.level),
                      borderRadius: "5px",
                    }}
                  ></div>
                </div>
              </RowContainer>
            ))}
          </ColumnContainer>
        </NewRowContainer>
      ))}
    </Container>
  );
};

export default LessonCount;


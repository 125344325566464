import React from "react";
import RowContainer from "../Common/RowContainer";
import '../../stylesheets/common.css';
import { useNavigate } from "react-router-dom";
interface BlockTextComponentProps {
  id: number;
  description: string;
  lightTheme?: string;
  isBretzel?: boolean;
  flashCardContentComponentId?: number;
  onClick?: () => void;
}

const BlockTextComponent: React.FC<BlockTextComponentProps> = ({
  id,
  description,
  lightTheme,
  isBretzel = false,
  onClick,
}) => {
  return (
    <RowContainer
      className="block-text-component"
      alignItem="center"
      justifyContent="center"
      bgColor={lightTheme === "Light" ? "#FFFFFF" : isBretzel ? "#0C0C13" : ""}
      borderRadius="24px"
      padding="10px"
      cursor="pointer"
      onClick={onClick}
    >
      <div dangerouslySetInnerHTML={{ __html: (description)?.cleanUpString() }} />
    </RowContainer>
  );  
};

export default BlockTextComponent;

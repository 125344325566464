import React, { useState } from "react";
import Label from "./Common/Label";
import { Link } from "react-router-dom";
import RowContainer from "./Common/RowContainer";
import Container from "./Common/Container";
import HorizontalSpace from "./HorizontalSpace";
import editIcon from "../assets/editIcon.svg";
import deleteIcon from "../assets/deleteIcon.svg";
import styled from "styled-components";
import CustomCheckbox from "./Common/CustomCheckbox";
import Switch from "./Common/Switch";

interface Question {
  id: number;
  question: string;
  level: string;
  latestdate: string;
  // active: boolean;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  onCheckboxChange: (id: number) => void;
  isChecked: boolean;
  status: boolean;
  onStatusChange: (id: number) => void;
  // onToggle: (id: number) => void;
  // toggleDeleteDialog: () => void;
}

const NewRowContainer = styled(RowContainer)`
  overflow: hidden;
`;

const NewLabel = styled(Label)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Questions: React.FC<Question> = ({
  id,
  question,
  level,
  latestdate,
  onEdit,
  onDelete,
  onCheckboxChange,
  isChecked,
  status,
  onStatusChange,
}) => {
  const [isSwitchOn, setIsSwitchOn] = useState(status);

  const toggleStatus = () => {
    const newStatus = !isSwitchOn;
    setIsSwitchOn(newStatus);
    onStatusChange(id);
  };

  const handleDelete = () => {
    onDelete(id); // Call the delete function with the ID of the challenge
  };

  return (
    <Container
      bgColor="#fff"
      borderRadius="10px"
      height="50px"
      alignItems="center"
      display="flex"
      width="100%"
      className="user-data user-container-hover"
    >
      <RowContainer
        justifyContent="space-between"
        alignItem="center"
        borderRadius="12px"
        margin="10px"
      >
        <RowContainer flex="1.4">
          {/* <CustomCheckbox
            checked={isChecked}
            onChange={() => onCheckboxChange(id)}
          /> */}
        </RowContainer>
        <NewRowContainer flex="23" gap="0px">
          <NewLabel fontSize="16px" fontWeight={400}>
            {question}
          </NewLabel>
        </NewRowContainer>

        <NewRowContainer flex="4.9" gap="0px">
          <NewLabel fontSize="16px" fontWeight={400}>
            {level}
          </NewLabel>
        </NewRowContainer>
        <NewRowContainer flex="6.7" gap="0px">
          <NewLabel fontSize="16px" fontWeight={400}>
            {latestdate}
          </NewLabel>
        </NewRowContainer>
        <RowContainer flex="3" gap="0px">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ gap: "10px" }}
          >
            <Switch isOn={isSwitchOn} onToggle={toggleStatus} />
            <div style={{ cursor: "pointer" }}>
              <img
                src={editIcon}
                onClick={() => onEdit(id)}
                className="user-scale"
                style={{ WebkitUserSelect: "none", userSelect: "none" }}
              />
            </div>
            {/* <div style={{ cursor: "pointer" }} onClick={handleDelete}>
              <img src={deleteIcon} alt="Delete" style={{ WebkitUserSelect: "none", userSelect: "none" }}/>
            </div> */}
          </div>
        </RowContainer>
      </RowContainer>
    </Container>
  );
};
export default Questions;

import { useEffect, useState } from "react";
import RowContainer from "../../Components/Common/RowContainer";
import "../../stylesheets/indexpage.css";
import { baseURL, getRequest } from "../../api/apiClient";
import user from "../../assets/Images/logo/user.svg";
import Loader from "../../utils/Loading";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../../stylesheets/common.css";
import UsersCounts from "../../Components/Dashboard/UsersCount";
import LessonCard from "../../Components/Dashboard/LessonsProgress";
import LessonCount from "../../Components/Dashboard/LessonsCount";
import TicketsCount from "../../Components/Dashboard/Tickets";
import PackagesCount from "../../Components/Dashboard/Packages";
import CountriesCounts from "../../Components/Dashboard/CountriesCount";
import styled from "styled-components";
import { dotformatDate } from "../../utils/formatDate";

// Register the ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

interface Count {
  totalUserCount: number;
  totalLessonsCount: number;
}

const ScrollableRowContainer = styled(RowContainer)`
  @media (max-width: 1150px) {
    width: 100%;
    flex-direction: column;
    padding: 10px;
  }
`;

const NewRowContainer = styled(RowContainer)`
  @media (max-width: 670px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState<any>({});

  useEffect(() => {
    fetchDashBoardData();
  }, []);

  const fetchDashBoardData = async () => {
    setIsLoading(true);
    const response = await getRequest(`${baseURL}/subscriptions/counts`);
    if (response?.data?.success) {
      setDashboardData(response?.data?.data);
    }
    setIsLoading(false);
  };

  const userCountsData = [
    {
      name: "Premium Users",
      users:
        (dashboardData?.premiumUsers?.length > 0 &&
          dashboardData?.premiumUsers[0]?.count) ||
        0,
      progress: dashboardData?.lastMonthPremiums || 0,
      isLoss: false,
    },
    {
      name: "Freemium Users",
      users: dashboardData?.freemiumUsers?.totalFreemiumUsers || 0,
      progress: dashboardData?.freemiumUsers?.newFreemiumUsersLastMonth || 0,
      isLoss: false,
    },
    { 
      name: "Free to paid Users",
      users: dashboardData?.freeToPaidUsers?.totalFreeToPaidUsers || 0,
      progress: dashboardData?.freeToPaidUsers?.newFreeToPaidUsersLastMonth || 0,
      isLoss: false,
    },
  ];

  const appCountsData = [
    {
      name: "In-app purchase",
      users: `€${dashboardData?.appPurchaseUsers?.totalPurchaseUsers || 0}`,
      progress: dashboardData?.appPurchaseUsers?.newPurchaseUsersLastMonth || 0,
      isLoss: true,
    },
    {
      name: "Revenue subscriptions",
      users: `€${dashboardData?.getDataFromStripes?.totalRevenue || 0}`,
      progress: dashboardData?.getDataFromStripes?.lastMonthRevenue || 0,
      isLoss: false,
    },
    {
      name: "Membership Renewal",
      users: dashboardData?.getDataFromStripes?.totalRenewalCount || 0,
      progress: dashboardData?.getDataFromStripes?.lastMonthRenewalCount || 0,
      isLoss: false,
    },
  ];
  
  const lessonsData = dashboardData?.mostPopularLesson && dashboardData?.mostPopularLesson?.map((mostPopularLesson: any) => ({
    level: mostPopularLesson.level,
    name: mostPopularLesson.themeName,
    users: mostPopularLesson.totalUsers,
    progress: mostPopularLesson.completionPercentage
   }));

  const lessonsCount = [
    {
      totalLessons: dashboardData?.totalLessonCount?.totalCount || 0,
      lastMonthLessons: dashboardData?.totalLessonCount?.lastMonthCount || 0,
      courses: [
        {
          level: "A1",
          users: 25000,
          progress: 90,
        },
        {
          level: "A2",
          users: 50000,
          progress: 75,
        },
        {
          level: "B1",
          users: 70000,
          progress: 50,
        },
      ],
    },
  ];

  const packages = [
    {
      level: "A1",
      name: "declinación",
      totalCount: 1500,
    },
    {
      level: "A2",
      name: "declinación...",
      totalCount: 1250,
    },
    {
      level: "A2",
      name: "präteritum",
      totalCount: 999,
    },
    {
      level: "B1",
      name: "präteritum",
      totalCount: 600,
    },
    {
      level: "B1",
      name: "präteritum",
      totalCount: 23,
    },
    {
      level: "B1",
      name: "präteritum",
      totalCount: 23,
    },
  ];

  const countriesData =
    dashboardData?.userCountByCountry &&
    dashboardData?.userCountByCountry?.map((userCountByCountry: any) => ({
      name: userCountByCountry.name,
      users: userCountByCountry.users,
      progress: userCountByCountry.progress,
    }));

  const totalGenderCount = {
    female: 0,
    male: 0,
    other: 0,
  };

  dashboardData?.gender?.forEach((item:any) => {
  if (item?.gender === 'female') {
    totalGenderCount.female = parseInt(item.count, 10);
  } else if (item?.gender === 'male') {
    totalGenderCount.male = parseInt(item.count, 10);
  } else {
    totalGenderCount.other += parseInt(item.count, 10);
  }
});

  useEffect(() => {
    let timeLine = gsap.timeline();

    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 0.3,
      ease: "power2.out",
      stagger: 0.3,
    });

    timeLine.from(".user-data", {
      opacity: 0,
      y: 40,
      duration: 0.3,
      ease: "power2.out",
    });

    timeLine.from(".left-container", {
      opacity: 0,
      x: -40,
      duration: 0.3,
      ease: "power2.out",
      stagger: 0.2,
    });

    timeLine.from(".bottom-container", {
      opacity: 0,
      y: 40,
      duration: 0.3,
      ease: "power2.out",
    });

    timeLine.from(".right-container", {
      opacity: 0,
      x: 40,
      duration: 0.3,
      ease: "power2.out",
      stagger: 0.2,
    });
  }, [isLoading]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="container-fluid">
            <div
              className="p-3 d-flex main-page"
              style={{ gap: "30px", flexDirection: "column" }}
            >
              <ScrollableRowContainer gap="33px">
                <RowContainer
                  bgColor="#37176C"
                  borderRadius="24px"
                  padding="20px"
                  display="unset"
                  className="user row-container"
                >
                  <UsersCounts counts={userCountsData} />
                </RowContainer>
                <RowContainer
                  bgColor="#323235"
                  borderRadius="24px"
                  padding="20px"
                  display="unset"
                  className="user-box row-container"
                >
                  <UsersCounts counts={appCountsData} />
                </RowContainer>
              </ScrollableRowContainer>

              <ScrollableRowContainer gap="33px">
                <RowContainer flexDirection="column" gap="24px">
                  <LessonCard lessons={lessonsData} />
                  <LessonCount lessonsCount={lessonsCount} />
                </RowContainer>
                <NewRowContainer gap="24px">
                  <CountriesCounts
                    countrie={countriesData}
                    gender={totalGenderCount}
                  />
                  <NewRowContainer flexDirection="column" flex="3" gap="24px">
                    <PackagesCount packageCount={packages} />
                    <TicketsCount
                      open={dashboardData?.tickets?.Open || 0}
                      solved={dashboardData?.tickets?.Solved || 0}
                      userImage={user}
                      name={dashboardData?.lastLogin?.name}
                      updatedAt={dotformatDate(
                        dashboardData?.lastLogin?.lastLoginTime
                      )}
                    />
                  </NewRowContainer>
                </NewRowContainer>
              </ScrollableRowContainer>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar ul {
    list-style-type: none;
    padding: 0;
    
}

.sidebar ul li {
    margin-bottom: 12px;
    /* margin-right: 10px; */
     -webkit-user-select: none;
     user-select: none;  
}

.sidebar ul li a {
    display: block;
    text-decoration: none;
}

.sidebar ul li a img {
    width: 20px;
    /* Adjust the width as needed */
    height: 20px;
    /* Adjust the height as needed */
}

/* Set default color to #dadada */
.default-color {
    filter: brightness(0.3) invert(35%) ;
}

/* Change SVG color on hover */
.sidebar ul li a img:hover {
    filter: brightness(0) invert(1);
    /* Change the color to white on hover */
}`, "",{"version":3,"sources":["webpack://./src/assets/css/ImageWithHoverEffect.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,UAAU;;AAEd;;AAEA;IACI,mBAAmB;IACnB,wBAAwB;KACvB,yBAAyB;KACzB,iBAAiB;AACtB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,+BAA+B;IAC/B,YAAY;IACZ,gCAAgC;AACpC;;AAEA,iCAAiC;AACjC;IACI,oCAAoC;AACxC;;AAEA,8BAA8B;AAC9B;IACI,+BAA+B;IAC/B,uCAAuC;AAC3C","sourcesContent":[".sidebar ul {\n    list-style-type: none;\n    padding: 0;\n    \n}\n\n.sidebar ul li {\n    margin-bottom: 12px;\n    /* margin-right: 10px; */\n     -webkit-user-select: none;\n     user-select: none;  \n}\n\n.sidebar ul li a {\n    display: block;\n    text-decoration: none;\n}\n\n.sidebar ul li a img {\n    width: 20px;\n    /* Adjust the width as needed */\n    height: 20px;\n    /* Adjust the height as needed */\n}\n\n/* Set default color to #dadada */\n.default-color {\n    filter: brightness(0.3) invert(35%) ;\n}\n\n/* Change SVG color on hover */\n.sidebar ul li a img:hover {\n    filter: brightness(0) invert(1);\n    /* Change the color to white on hover */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;


import React from "react";
import { Form } from "react-bootstrap";
import "../../assets/css/radiobutton.css";

interface Props {
  checked: boolean;
  onChange?: () => void;
}

const RadioButton: React.FC<Props> = ({ checked, onChange }) => {
  return (
      <Form.Check
        type="radio"
        checked={checked}
        onChange={onChange}
        style={{
          // cursor: "pointer",
          // width: "30px",
          // height: "30px",
        }}
        className="custom-radio"
      />
  );
};

export default RadioButton;

import ColumnContainer from "../Common/ColumnContainer";
import Container from "../Common/Container";
import Label from "../Common/Label";
import RowContainer from "../Common/RowContainer"
import "../../stylesheets/common.css";
import VerticalSpace from "../VerticalSpace";

interface ticketsProgress {
  open: string | number;
  solved: string | number;
  userImage: string;
  name: string;
  updatedAt: string;
}

const TicketsCount: React.FC<ticketsProgress> = ({
  open,
  solved,
  userImage,
  name,
  updatedAt,
}) => {
  return (
    <Container
      className="user-box right-container"
      borderRadius="24px"
      padding="15px"
      bgColor="#FFFFFF"
      width="100%"
    >
      <RowContainer alignItem="center">
        <Label fontSize="20px" fontWeight={700} color="#323235">
          Tickets
        </Label>
      </RowContainer>
      <VerticalSpace height={7} />
      <RowContainer alignItem="center" flexDirection="column" gap="5px">
        <RowContainer alignItem="center" justifyContent="space-between">
          <Label
            fontSize="13px"
            fontWeight={400}
            color="#323235"
            display="flex"
            alignItems="center"
            gap="5px"
          >
            <Label
              bgColor="#EB002C"
              width="13px"
              height="13px"
              borderRadius="50%"
              display="block"
            ></Label>
            Open
          </Label>
          <Label fontSize="15px" fontWeight={700} color="#323235">
            {open}
          </Label>
        </RowContainer>
        <RowContainer alignItem="center" justifyContent="space-between">
          <Label
            fontSize="13px"
            fontWeight={400}
            color="#323235"
            display="flex"
            alignItems="center"
            gap="5px"
          >
            <Label
              bgColor="#28A138"
              width="13px"
              height="13px"
              borderRadius="50%"
              display="block"
            ></Label>
            Solved
          </Label>
          <Label fontSize="15px" fontWeight={700} color="#323235">
            {solved}
          </Label>
        </RowContainer>
      </RowContainer>
      <VerticalSpace height={8} />
      <RowContainer alignItem="center">
        <Label fontSize="20px" fontWeight={700} color="#323235">
          Last logged
        </Label>
      </RowContainer>
      <VerticalSpace height={10} />
      <RowContainer alignItem="center" gap="19px">
        <img
          src={userImage}
          className="user dashboaed-score"
          alt="User"
          width="44px"
          height="44px"
          style={{ borderRadius: "8px" }}
        />
        <RowContainer flexDirection="column" gap="2px">
          <Label className="dashboaed-scale" fontSize="13px" fontWeight={400} color="#100F15">
            {name}
          </Label>
          <Label className="dashboaed-scale" fontSize="13px" fontWeight={400} color="#100F15">
            {updatedAt}
          </Label>
        </RowContainer>
      </RowContainer>
    </Container>
  );
};
export default TicketsCount;
import { useState, useEffect } from "react";
import CustomDropdown from "../../Components/Common/CustomDropdown";
import Container from "../../Components/Common/Container";
import RowContainer from "../../Components/Common/RowContainer";
import Label from "../../Components/Common/Label";
import styled from "styled-components";
import VerticalSpace from "../../Components/VerticalSpace";
import MessageList from "../../Components/Message/Messagelist";
import ColumnContainer from "../../Components/Common/ColumnContainer";
import formatDate from "../../Components/DateFormat";
import PaginationComponent from "../../Components/Common/PaginationComponent";
import { baseURL, getRequest, postRequest } from "../../api/apiClient";
import Toast from "../../Components/Common/Toast";
import DeleteConfirmation from "../../Components/Common/DeleteConfirmation";
import Loader from "../../utils/Loading";
import { useNavigate } from "react-router-dom";
import gsap from 'gsap';
import '../../stylesheets/common.css'
import ShimmerEffect from "../../utils/ShimmerEffect";

const InnerRowContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 1100px;
`;

const ScrollableRowContainer = styled(RowContainer)`
  @media (max-width: 1150px) {
    width: 100%;
    overflow-x: auto;
  }
`;

interface Messages {
  id: number;
  userId: number;
  userFullName: string;
  userEmail: string;
  description: string;
  ratings:string;
  updatedAt: string;
}

const Messages = () => {
  const [messages, setMessages] = useState<Messages[]>([]);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState<number[]>([]);
  const [selectedMessagesId, setSelectedMessagesId] = useState<number | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [filterLoading, setFilterLoading]= useState(false);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(5);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [filters, setFilters] = useState<{
    updatedAt?: number;
  }>({});
  const navigate = useNavigate();

  const handleSelect = (item: string) => {
    setSelectedItem(item);
  };

  const dropdownItems: string[] = ["All", "Latest update"];

  useEffect(() => {
    fetchMessages(page + 1, limit, filters);
  }, [page + 1, limit, filters]);

  const fetchMessages = async (
    page: number,
    perPage: number,
    filters: { updatedAt?: number }
  ) => {
    try {
      if(!filters){
        setLoading(true);
      }else{
        setFilterLoading(true);
      }
     
      let url = `${baseURL}/ratings/get-all-app-ratings?page=${page}&limit=${perPage}`;
      if (filters.updatedAt) {
        url += `&updatedAt=${filters.updatedAt}`;
      }
      const response = await getRequest(url);
      if (response?.data) {
        setMessages(response.data.data.result);
        setCount(response.data.data.totalRatingsCount);
        setPage(response.data.data.page - 1);
        setHasNextPage(response.data.data.hasNextPage);
        setHasPreviousPage(response.data.data.hasPreviousPage);
      }
      setLoading(false);
      setFilterLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching lessons:", error);
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleApplyFilters = () => {
    switch (selectedItem) {
      case "Latest update":
        setFilters({ updatedAt: -1 });
        break;
      default:
        setFilters({});
        break;
    }
  };

  const onCheckboxChange = (id: number) => {
    if (selectedMessages.includes(id)) {
      setSelectedMessages(
        selectedMessages.filter((selectedId) => selectedId !== id)
      );
    } else {
      setSelectedMessages([...selectedMessages, id]);
    }
  };

  const deleteSelectedMessages = () => {
    if (selectedMessages.length > 0) {
      setShowDeleteDialog(true);
    } else {
      Toast("Please select at least one message to delete.", "error");
    }
  };

  const deleteMessages = async (
    messagesIds: number | number[]
  ) => {
      const payload = {
        id: Array.isArray(messagesIds)
          ? messagesIds
          : messagesIds,
      };
      // setLoading(true);
      const response = await postRequest(
        `${baseURL}/ratings/delete-app-ratings-by-id`,
        payload
      );

      if (response?.status === 200) {
        Toast("Message deleted successfully", "success");
        setMessages((prevData) =>
          prevData.filter((message) =>
            Array.isArray(messagesIds)
              ? !messagesIds.includes(message.id)
              : message.id !== messagesIds
          )
        );
        // setLoading(false);
      } else {
        // setLoading(false);
        Toast("Failed to delete message", "error");
      }
  };

  const handleDelete = async () => {
    if (selectedMessagesId !== null) {
      await deleteMessages(selectedMessagesId);
    } else {
      await deleteMessages(selectedMessages);
    }
    // await fetchMessages(page + 1, limit, filters);
    setSelectedMessages([]);
    setSelectedMessagesId(null);
    setShowDeleteDialog(false);
  };

  const toggleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  const handleViewMessage = (id:number)=>{
    navigate(`/message/${id}`);
  }

  useEffect(() => {
    let timeLine = gsap.timeline();
    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 0.3,
      ease: "power2.out",
    });

    timeLine.from(".user-data", {
      opacity: 0,
      y: 40,
      duration: 0.5,
      ease: "power2.out",
      stagger: 0.2
    });


  }, [loading]);

  return (
    <>
      {" "}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="container-fluid">
            <div className="p-3">
              <div className="row mb-3 ">
                <div className="col">
                  <h2 style={{ fontWeight: "bold" }}>Messages</h2>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="filter" className="form-label">
                    Filter
                  </label>
                </div>
              </div>

              <div className="row mb-3 main-responsive">
                <div className="col d-flex responsive">
                  <CustomDropdown
                    items={dropdownItems}
                    onSelect={handleSelect}
                    selectedItem={selectedItem || ''}
                    height="50px"
                  />
                  <button
                    type="button"
                    className="btn btn-primary ms-3 user"
                    style={{
                      backgroundColor: "#100F15",
                      borderRadius: "10px",
                      color: "#FFFFFF",
                      fontSize: "14px",
                      width: "auto",
                      padding: "14px 50px",
                    }}
                    onClick={handleApplyFilters}
                  >
                    Apply
                  </button>
                </div>
                <div className="col d-flex justify-content-end responsive">
                  {/* <button
                    type="button"
                    className="btn btn-outline-secondary me-2 text-center user"
                    style={{
                      color: "#100F15",
                      background: "none",
                      border: "1px solid #100F15",
                      borderRadius: "10px",
                      fontSize: "14px",
                      width: "auto",
                      padding: "14px 40px",
                    }}
                    onClick={deleteSelectedMessages}
                  >
                    Delete all
                  </button> */}
                </div>
              </div>

              <ScrollableRowContainer>
                <InnerRowContainer>
                  <Container width="100%">
                    <RowContainer
                      alignItem="center"
                      padding="8px 12px 8px 12px"
                      gap="8px"
                      className="row-container"
                    >
                      <RowContainer flex="1.5">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        ></Label>
                      </RowContainer>
                      <RowContainer flex="6.6">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Name
                        </Label>
                      </RowContainer>
                      <RowContainer flex="7.5">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          email
                        </Label>
                      </RowContainer>
                      <RowContainer flex="16">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Message
                        </Label>
                      </RowContainer>
                      <RowContainer flex="5.5">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Date
                        </Label>
                      </RowContainer>
                      <RowContainer flex="3.2">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Action
                        </Label>
                      </RowContainer>
                    </RowContainer>
                    {filterLoading ? (
                      <>
                        <RowContainer
                          bgColor="#ffffff"
                          borderRadius="8px"
                          padding="10px 0px"
                        >
                          {Array.from({ length: 6 }, (_, index) => {
                            return (
                              <RowContainer
                                key={index}
                                width={
                                  index === 0 || index === 5 ? "10%" : "20%"
                                }
                                justifyContent="center"
                              >
                                <ShimmerEffect />
                              </RowContainer>
                            );
                          })}
                        </RowContainer>
                      </>
                    ) : (
                      <>
                        {messages && messages.length > 0 ? (
                          <ColumnContainer>
                            {messages.map((message) => (
                              <MessageList
                                key={message.id}
                                id={message.id}
                                name={message.userFullName || "N/A"}
                                email={message.userEmail || "N/A"}
                                message={message.description}
                                updatedAt={formatDate(message.updatedAt)}
                                onView={handleViewMessage}
                                onDelete={() => {
                                  setSelectedMessagesId(message.id);
                                  toggleDeleteDialog();
                                }}
                                isChecked={selectedMessages.includes(
                                  message.id
                                )}
                                onCheckboxChange={onCheckboxChange}
                              />
                            ))}
                          </ColumnContainer>
                        ) : (
                          <>
                            <VerticalSpace height={30} />
                            <Label
                              alignItems="center"
                              display="flex"
                              color="rgba(164, 163, 167, 1)"
                              justifyContent="center"
                            >
                              No results found.
                            </Label>
                          </>
                        )}
                      </>
                    )}
                  </Container>
                </InnerRowContainer>
              </ScrollableRowContainer>
              <VerticalSpace height={10} />
              <RowContainer justifyContent="flex-end" className="user-data">
                <PaginationComponent
                  page={page}
                  rowsPerPage={limit}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  count={count}
                  hasNextPage={hasNextPage}
                  hasPreviousPage={hasPreviousPage}
                />
              </RowContainer>
            </div>
            {showDeleteDialog && (
              <DeleteConfirmation
                showDeletePopup={true}
                setShowDeletePopup={setShowDeleteDialog}
                message={`Are you sure you want to delete ${
                  selectedMessages.length === 0
                    ? "this message ?"
                    : selectedMessages.length === 1
                    ? "this messages ?"
                    : "these messages ?"
                }`}
                deleteButtonLabel="Delete"
                onDelete={handleDelete}
              />
            )}
            <VerticalSpace height={100} />
          </div>
        </>
      )}
    </>
  );
};
export default Messages;

import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import "../../assets/css/ImageWithHoverEffect.css";

interface SidebarProps {
  image: string;
  toScreen: string;
  className: string;
  tooltipText: string;
  onLinkClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

// Custom Tooltip with arrow on the left (tooltip on the right)
const CustomTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(() => ({
  "& .MuiTooltip-tooltip": {
    fontSize: "1rem", // Adjust font size
    padding: "10px 15px", // Add padding for better visibility
    backgroundColor: "rgba(0, 0, 0, 0.6)", // Tooltip background
    color: "#fff", // Text color
    position: "relative",
  },
  "& .MuiTooltip-arrow": {
    color: "rgba(0, 0, 0, 0.6)", // Arrow background matches tooltip
    transform: "translateX(-100%)", // Move the arrow to the left side of the tooltip
  },
}));

const Sidebar: React.FC<SidebarProps> = ({
  image,
  toScreen,
  className,
  tooltipText,
  onLinkClick,
}) => {
  return (
    <div className="sidebar">
      <ul>
        <li>
          <a href={toScreen} onClick={onLinkClick}>
            <CustomTooltip
              title={tooltipText}
              placement="right" // Tooltip will be on the right
              arrow // Enables the arrow
              enterDelay={500} // Delay before the tooltip shows
              leaveDelay={200} // Delay before the tooltip hides
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, 18], // Adjust the tooltip offset to shift it towards the left
                    },
                  },
                ],
              }}
            >
              <img src={image} alt="Image" className={className} />
            </CustomTooltip>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;

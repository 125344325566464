import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ".././../assets/css/style.css";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import Logo from "../../assets/Images/logo/logo.svg";
import Lexik from "../../assets/Images/logo/logoName.svg"; // Assuming you have another logo image for Lexik
import { handleAction } from "../../services/authService";
import { useNavigate } from "react-router-dom";
import logobg from "../../assets/Images/logobg.png";
import VerticalSpace from "../../Components/VerticalSpace";
import { AxiosError } from "axios";
import Toast from "../../Components/Common/Toast";
import { AppDispatch } from "../../redux/store";
import "../../stylesheets/common.css";
import { setDropDown } from "../../redux/lessonsSlice";
import { useDispatch } from "react-redux";
import { baseURL, getRequest } from "../../api/apiClient";
import { setParticles } from "../../redux/particlesReducerSlice";

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const validateEmail = (input: string) => {
    // Regular expression to match Gmail IDs
    const regex = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
    return regex.test(input);
  };

  const handleSubmit = async () => {
    setEmailError("");
    setPasswordError("");

    if (!email || !password) {
      if (!email) {
        Toast("Email is required", "error");
        return;
      }
      if (!password) {
        Toast("Password is required", "error");
        return;
      }
      return;
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      Toast("Invalid email address", "error");
      return;
    }

    if (password.length < 8) {
      Toast("Password must be at least 8 characters long", "error");
      return;
    }

    try {
      setIsLoading(true);
      const response = await handleAction("login", { email, password });

      if (response) {
        setIsLoading(false);
        Toast(response.statusMessage, "success");
        navigate("/");
      }

       const fetchLessons = await getRequest(
         `${baseURL}/notification-challenge/get-lesson-dropdown`
       );

      const fetchParticles = await getRequest(
        `${baseURL}/particle/get-dropdown`
      );
      dispatch(setDropDown(fetchLessons?.data?.data || []));
      dispatch(setParticles(fetchParticles?.data?.data || []));

    } catch (error) {
      setIsLoading(false);
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.status === 401) {
        Toast("Invalid email or password", "error");
      }
    }
  };
  
  const handleKeyDown =(event:any)=>{
     if(event.key ==="Enter"){
      handleSubmit();
     }
  }

  return (
    <div
      className="fullscreen-background"
      style={{
        backgroundImage: `url(${logobg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        className="row justify-content-around align-items-center fullscreen-content"
        style={{ margin: 0 }}
      >
        <div className="col-md-6">
          <div
            className="card-body login-image text-center"
            style={{ padding: "0px 0px 0px 70px" }}
          >
            <div className="col">
              <img
                src={Logo}
                alt="Logo"
                className="logo"
                style={{ verticalAlign: "baseline" }}
              />
              {"  "}
              <img
                src={Lexik}
                alt="Lexik"
                className="lexik_name"
                style={{ verticalAlign: "baseline", marginLeft: "10px" }}
              />
            </div>
          </div>
        </div>

        <div className="col-md-6 ">
          <div
            className="card-body input-box"
            style={{ padding: "0px 130px 0px 100px" }}
          >
            <div className="form-group">
              <label htmlFor="email" style={{ color: "#fff" }}>
                User
              </label>

              <input
                type="email"
                className={`form-control ${emailError ? "is-invalid" : ""}`}
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                // required
              />
            </div>
            <VerticalSpace height={24} />
            <div className="form-group">
              <label htmlFor="password" style={{ color: "#fff" }}>
                Password
              </label>
              <div style={{ position: "relative" }}>
                <input
                  type={showPassword ? "text" : "password"}
                  className={`form-control ${
                    passwordError ? "is-invalid" : ""
                  }`}
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={handleKeyDown}
                  // required
                />
                <span
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </div>

            <VerticalSpace height={40} />
            <div className="button">
              <button
                type="submit"
                className="btn btn-primary bg-transparent"
                style={{
                  backgroundColor: "#7542E4",
                  borderRadius: "10px",
                  padding: "10px 30px",
                }}
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  "Enter"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

import React, { useState, useEffect } from "react";
import user from "../assets/Images/logo/user.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faBars } from "@fortawesome/free-solid-svg-icons";
import "../assets/css/header.css";
import { getToken } from "../services/authService";
import RowContainer from "../Components/Common/RowContainer";
import HorizontalSpace from "../Components/HorizontalSpace";
import { SidebarContext } from "../hooks/UseSidebar";
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Register the ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);


interface User {
  fullName?: string;
  uId?: string;
  status?: string;
}

const Header: React.FC<User> = () => {
  const [userName, setUserName] = useState<string | null>(null);
  const { toggleSide } = React.useContext(SidebarContext);

  useEffect(() => {
    const userData = getToken();
    if (userData && userData.fullName) {
      setUserName(userData.fullName);
    }
  }, []);

  const showSideBar = () => {
    toggleSide();
  };

  useEffect(() => {
    let userTimeLine = gsap.timeline();
    userTimeLine.from(".user", {
      opacity: 0,
      duration: 0.5,
      y: -30,
      stagger: 0.5
    })
  }, []);
  

  return (
    <nav className="navbar navbar-light bg-light">
      <div className="container-fluid">
        <RowContainer alignItem="center">
          <RowContainer alignItem="center" width="max-content">
            
            <FontAwesomeIcon
              icon={faBars}
              className="slide-button"
              style={{
                // backgroundColor: "#D9D9D9",
                // padding: "7px",
                borderRadius: "8px",
                color: "#000",
                cursor: "pointer",
              }}
              onClick={showSideBar}
            />
            {/* <FontAwesomeIcon icon={faMagnifyingGlass} /> */}
            {/* <div className="search-input-container">
              <input
                className="div-control search-input"
                type="search"
                placeholder="Search"
                aria-label="Search"
                style={{
                  backgroundColor: "transparent",
                  border: "0px",
                  fontSize: "14px",
                  // width: "170px",
                  // fontWeight: 400,
                  color: "black",
                  opacity: "40%",
                }}
              />
            </div> */}
          </RowContainer>
          <RowContainer gap="0px" alignItem="center" justifyContent="flex-end">
            <span
              style={{
                backgroundColor: "#009BFF",
                borderRadius: "4px",
                padding: "5px",
                height: "28px",
                color: "#fff",
                display: "flex",
                alignItems: "center",
              }}
              className="user"
            >
              Hello
            </span>
            <span
              style={{
                backgroundColor: "#592ACB",
                borderRadius: "4px 20px 20px 4px",
                padding: "5px 10px",
                height: "28px",
                color: "#fff",
                display: "flex",
                alignItems: "center",
              }}
              className="user"
            >
              {userName || "Antonia"}
            </span>
            <HorizontalSpace width={1} />
            <img src={user} className="user" alt="User" style={{ borderRadius: "7px" }} />
          </RowContainer>
        </RowContainer>
      </div>
    </nav>
  );
};

export default Header;

import React, { useState } from "react";
import Label from "../Common/Label";
import { Link } from "react-router-dom";
import RowContainer from "../Common/RowContainer";
import Container from "../Common/Container";
import HorizontalSpace from "../HorizontalSpace";
import editIcon from "../../assets/editIcon.svg";
import deleteIcon from "../../assets/deleteIcon.svg";
import Switch from "../Common/Switch";
import styled from "styled-components";
import CustomCheckbox from "../Common/CustomCheckbox";

interface Member {
  id: number;
  theme: string;
  point: string;
  created: string;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  onCheckboxChange: (id: number) => void;
  isChecked: boolean;
  status: boolean;
  onStatusChange: (id: number) => void;
}

const NewRowContainer = styled(RowContainer)`
  overflow: hidden;
`;

const NewLabel = styled(Label)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const MultipleOption: React.FC<Member> = ({
  id,
  theme,
  point,
  created,
  onEdit,
  onDelete,
  onCheckboxChange,
  isChecked,
  status,
  onStatusChange,
}) => {
  const [isSwitchOn, setIsSwitchOn] = useState(status);
  const toggleSwitch = () => {
    const newStatus = !isSwitchOn;
    setIsSwitchOn(newStatus);
    onStatusChange(id);
  };

  const handleDelete = () => {
    onDelete(id);
  };

  return (
    <Container
      bgColor="#fff"
      borderRadius="10px"
      height="50px"
      alignItems="center"
      display="flex"
      width="100%"
      className="user-data user-container-hover"
    >
      <RowContainer
        justifyContent="space-between"
        alignItem="center"
        borderRadius="12px"
        margin="10px"
      >
        <RowContainer flex="1.4">
          {/* <CustomCheckbox
            checked={isChecked}
            onChange={() => onCheckboxChange(id)}
          /> */}
        </RowContainer>
        <NewRowContainer flex="13" gap="0px">
          <NewLabel fontSize="16px" fontWeight={400}>
            {theme}
          </NewLabel>
        </NewRowContainer>
        <NewRowContainer flex="6">
          <NewLabel fontSize="16px" fontWeight={400}>
            {point}
          </NewLabel>
        </NewRowContainer>
        <NewRowContainer flex="5">
          <NewLabel fontSize="16px" fontWeight={400}>
            {created}
          </NewLabel>
        </NewRowContainer>
        <RowContainer flex="2.5">
          <div className="d-flex align-items-center" style={{ gap: "10px" }}>
            <Switch isOn={isSwitchOn} onToggle={toggleSwitch} />
            <div style={{ cursor: "pointer" }}>
              <img
                src={editIcon}
                onClick={() => onEdit(id)}
                className="user-scale"
                style={{ WebkitUserSelect: "none", userSelect: "none" }}
              />
            </div>
            {/* <div style={{ cursor: "pointer" }} onClick={handleDelete}>
              <img src={deleteIcon} style={{ WebkitUserSelect: "none", userSelect: "none" }}/>
            </div> */}
          </div>
        </RowContainer>
      </RowContainer>
    </Container>
  );
};
export default MultipleOption;

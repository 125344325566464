import Container from "../Common/Container";
import "../../stylesheets/common.css";
import RowContainer from "../Common/RowContainer";
import Label from "../Common/Label";
import ColumnContainer from "../Common/ColumnContainer";
import "../../stylesheets/LessonCardProgress.css";
import formatUsersCount from "../formatUsersCount";
import styled from "styled-components";
import { useState } from "react";

interface lessonsProgress {
  level: string;
  name: string;
  users: string | number;
  progress: number;
}

interface LessonCardProps {
  lessons: lessonsProgress[];
}

const ScrollableRowContainer = styled(RowContainer)`
  @media (max-width: 420px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

const NewRowContainer = styled(RowContainer)`
  @media (max-width: 610px) {
    align-items: center;
    flex-direction: column;
    gap:15px;
  }
`;

const ResponsiveRowContainer = styled(RowContainer)`
  @media (max-width: 610px) {
    align-items: center;
    justify-content: flex-start;
  }
`;

const NewRowContainers = styled(RowContainer)`
  overflow: hidden;
`;

const NewLabel = styled(Label)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: 670px) {
    width: auto;
  }
`;

const getLevelBackgroundColor = (level: string) => {
  switch (level) {
    case "A1":
      return "#00A3FF";
    case "A2":
      return "#FF9E00";
    case "B1":
      return "#592ACB";
    default:
      return "#121217";
  }
};

const getProgressColor = (progress: number) => {
  return progress < 20 ? "#EB002C" : "#28A138";
};

const LessonCard: React.FC<LessonCardProps> = ({ lessons }) => {
  const [sortOrder, setSortOrder] = useState<"mostPopular" | "lessPopular">(
    "mostPopular"
  );

   const handleMostPopular = () => {
     setSortOrder("mostPopular");
   };

   const handleLessPopular = () => {
     setSortOrder("lessPopular");
   };

   const displayedLessons =
     sortOrder === "lessPopular" ? [...lessons].reverse() : lessons;

  return (
    <Container
      className="user-box left-container"
      borderRadius="24px"
      padding="15px"
      bgColor="#FFFFFF"
      width="100%"
    >
      <ScrollableRowContainer alignItem="center" justifyContent="space-between">
        <Label fontSize="20px" fontWeight={700} color="#121217">
          Lessons
        </Label>
        <ScrollableRowContainer justifyContent="flex-end">
          <button
            type="button"
            className="btn btn-outline-secondary me-2 text-center user"
            style={{
              color: "#FFFFFF",
              background: "#28A138",
              border: "1px solid #28A138",
              borderRadius: "46px",
              fontSize: "13px",
              width: "auto",
              padding: "6px 11px",
            }}
            onClick={handleMostPopular}
          >
            Most popular
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary me-2 text-center user"
            style={{
              color: "#EB002C",
              background: "none",
              border: "1px solid #EB002C",
              borderRadius: "46px",
              fontSize: "13px",
              width: "auto",
              padding: "6px 11px",
            }}
            onClick={handleLessPopular}
          >
            Less popular
          </button>
        </ScrollableRowContainer>
      </ScrollableRowContainer>
      <ColumnContainer padding="10px" className="scrollable-lessons-container">
        {displayedLessons.length === 0 ? (
          <RowContainer
            alignItem="center"
            height="261px"
            justifyContent="center"
          >
            <Label fontSize="26px" color="#7542E4" textAlign="center">
              No lessons available
            </Label>
          </RowContainer>
        ) : (
          displayedLessons.map((lesson, index) => (
            <NewRowContainer
              key={index}
              borderBottom={
                index !== lessons.length - 1
                  ? "1px solid rgba(16, 15, 21, 0.1)"
                  : "none"
              }
              padding="10px"
              alignItem="center"
              // className="dashboaed-scale"
              className="user-box"
            >
              <RowContainer flexDirection="row">
                <RowContainer flex="1">
                  <Label
                    fontSize="12px"
                    fontWeight={700}
                    color="#FFFFFF"
                    borderRadius="12px"
                    bgColor={getLevelBackgroundColor(lesson.level)}
                    width="31px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {lesson.level}
                  </Label>
                </RowContainer>
                <NewRowContainers flex="5">
                  <NewLabel
                    fontSize="13px"
                    fontWeight={400}
                    color="#100F15"
                    width="150px"
                  >
                    {lesson.name}
                  </NewLabel>
                </NewRowContainers>
                <RowContainer flex="3" justifyContent="flex-end">
                  <Label fontSize="13px" fontWeight={700} color="#121217">
                    {formatUsersCount(Number(lesson.users))} users
                  </Label>
                </RowContainer>
              </RowContainer>
              <ResponsiveRowContainer
                alignItem="center"
                justifyContent="flex-end"
              >
                <Label
                  fontSize="13px"
                  fontWeight={700}
                  color={getProgressColor(lesson.progress)}
                >
                  {lesson.progress} %
                </Label>
                <progress
                  value={lesson.progress}
                  max={100}
                  className={`progress-bar ${
                    lesson.progress < 20
                      ? "progress-bar-red"
                      : "progress-bar-green"
                  }`}
                />
              </ResponsiveRowContainer>
            </NewRowContainer>
          ))
        )}
      </ColumnContainer>
    </Container>
  );
};

export default LessonCard;
import React, { useState, useEffect } from "react";
import VerticalSpace from "../../../VerticalSpace";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Label from "../../../Common/Label";
import editIcon from "../../../../assets/editIcon.svg";
import deleteIcon from "../../../../assets/deleteIcon.svg";
import { useNavigate, useLocation } from "react-router-dom";
import RadioButton from "../../../Common/RadioButton";
import HorizontalSpace from "../../../HorizontalSpace";
import DropdownMenu from "../../../Common/Dropdown";
import options from "../../../../MenuItem.json";
import { toast } from "react-toastify";
import { baseURL, getRequest, postRequest } from "../../../../api/apiClient";
import NestedMenu from "../../../ParticlesDropdown/NestedMenu";
import CustomCheckbox from "../../../Common/CustomCheckbox";
import Toast from "../../../Common/Toast";
import "../../../../stylesheets/theme.css";
import DeleteConfirmation from "../../../Common/DeleteConfirmation";
import Loader from "../../../../utils/Loading";
import gsap from 'gsap';
import '../../../../stylesheets/common.css'
import TextEditor from "../../../Common/TextEditor";

interface DropdownValue {
  id: number;
  name: string;
  pId: number;
  particleType: string;
  particleValue: string;
  particleType1: string;
  particleValue1: string;
  children: DropdownValue[];
}

interface Pair {
  id?: number;
  dropdownValue: DropdownValue | null;
  inputValue: string;
  showCheckbox?: boolean;
  isInputChecked?: boolean;
  particleData?: any;
  order?: number;
}

const ExampleComponents = () => {
  const [selectedOption, setSelectedOption] = useState("Light");
  const [size, setSelectedSize] = useState("Full");
  const [pairs, setPairs] = useState<Pair[]>([]);
  const [denkanstoẞ, setDenkanstoẞ] = useState<Pair[]>([]);
  const [header, setHeader] = useState("");
  const [footer, setFooter] = useState("");
  const [exampleId, setExampleId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [denkanstoẞItem, setDenkanstoẞItem] = useState<{
    [key: number]: DropdownValue | null;
  }>({});
  const [deleteDialogMessage, setDeleteDialogMessage] = useState("");
  const [onConfirmDelete, setOnConfirmDelete] = useState<() => void>(() => {});
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [sentenceToDelete, setSentenceToDelete] = useState<{
    index: number;
    id?: number;
  } | null>(null);
  const [validationError, setValidationError] = useState<{
    pairs: {
      [key: number]: {
        dropdownValue?: string;
        inputValue?: string;
      };
    };
  }>({
    pairs: {},
  });
  const navigate = useNavigate();
  const location = useLocation();

  const { flashcardContentId } = location.state || {};

  useEffect(() => {
    fetchExampleDetails(flashcardContentId);
  }, [flashcardContentId]);


  const fetchExampleDetails = async (id: number) => {
    try {
      setIsLoading(true);
      const response = await getRequest(
        `${baseURL}/lesson/get-example-by-fccId/${flashcardContentId}`
      );

      const exampleData = response?.data;

      if (!exampleData) {
        setIsLoading(false);
        return; 
      }

      if (exampleData) {
        setExampleId(exampleData.exampleId || "");
        setHeader(exampleData.header || "");
        setFooter(exampleData.footer || "");
        setSelectedOption(
          exampleData.isDenkanstob
            ? "Denkanstoẞ"
            : exampleData.lightTheme || "Light"
        );
        setSelectedSize(exampleData.size || "Full");
        const fetchedPairs = await Promise.all(
          exampleData.sentence.map(async (item: any, index: number) => {
            const particleData = await fetchParticleById(item.type);
            return {
              id: item.id,
              dropdownValue: {
                id: particleData.id,
                name: particleData.name,
                pId: particleData.pId,
                particleType: particleData.particleType,
                particleValue: particleData.particleValue,
                particleType1: particleData.particleType1,
                particleValue1: particleData.particleValue1,
                children: particleData.children || [],
              },
              inputValue: item.value,
              showCheckbox: particleData?.name === "Text",
              isInputChecked: item.isStrike || false,
              order: item.order || index + 1,
            };
          })
        );
        setPairs(fetchedPairs);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching example details:", error);
    }
  };

  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
  };

  const handleSizeChange = (option: string) => {
    setSelectedSize(option);
  };

  const handleHeaderChange = (value: string) => {
    setHeader(value);
  };
  
  const handleFooterChange = (value: string)=>{
    setFooter(value)
  }; 

  const addNewPair = () => {
    const newOrder = pairs.length + 1;
    setPairs([
      ...pairs,
      {
        dropdownValue: null,
        inputValue: "",
        showCheckbox: false,
        isInputChecked: false,
        order: newOrder,
      },
    ]);
  };

  const handleInputChange = (index: number, value: string) => {
    const updatedPairs = [...pairs];
    updatedPairs[index].inputValue = value;
    const updatedErrors = { ...validationError };
    if (updatedErrors.pairs[index]) {
      updatedErrors.pairs[index].inputValue = "";
    } else {
      updatedErrors.pairs[index] = { inputValue: "" };
    }

    setPairs(updatedPairs);
    setValidationError(updatedErrors);
  };

  const removePair = async (index: number, id?: number) => {

    if (index === undefined && id === undefined) {
      console.error("Both index and id are undefined");
      return;
    }

    if (id !== undefined) {
      try {
        const reqData = {
          modelName: "examplesentences",
          id: id,
        };
        const response = await postRequest(
          `${baseURL}/lesson/delete-bretzel-content`,
          reqData
        );

        if (response?.data) {
          const updatedPairs = [...pairs];
          const pairIndex = updatedPairs.findIndex((pair) => pair.id === id);
          if (pairIndex !== -1) {
            updatedPairs.splice(pairIndex, 1);
            setPairs(updatedPairs);
            Toast(response?.data.statusMessage, "success");
          }
        } else {
          console.error("Error deleting pair:", response?.data);
        }
      } catch (error) {
        console.error("Error deleting pair:", error);
      }
    } else if (index !== undefined) {
      const updatedPairs = [...pairs];
      updatedPairs.splice(index, 1);
      setPairs(updatedPairs);
    }
  };

  const fetchParticleById = async (id: number) => {
    try {
      const response = await getRequest(
        `${baseURL}/particle/get-particleById/${id}`
      );
      return response?.data.data;
    } catch (error) {
      console.error("Error fetching particle by ID:", error);
      throw error;
    }
  };

  const handleSelectItem = (index: number, item: DropdownValue) => {
     const updatedPairs = [...pairs];
     updatedPairs[index].dropdownValue = item;
     updatedPairs[index].showCheckbox = item.name === "Text";
     const updatedErrors = { ...validationError };
     if (updatedErrors.pairs[index]) {
       updatedErrors.pairs[index].dropdownValue = "";
     } else {
       updatedErrors.pairs[index] = { dropdownValue: "" };
     }
     setPairs(updatedPairs);
     setValidationError(updatedErrors);
  };

  const handleCheckboxChange = (index: number) => {
    const updatedPairs = [...pairs];
    updatedPairs[index].isInputChecked = !updatedPairs[index].isInputChecked;
    setPairs(updatedPairs);
  };

  const addNewDenkanstoẞ = () => {
    const newOrder = denkanstoẞ.length + 1;
    setDenkanstoẞ([
      ...denkanstoẞ,
      { dropdownValue: null, inputValue: "", order: newOrder },
    ]);
  };

  const handleDenkanstoẞChange = (index: number, item: DropdownValue) => {
    setDenkanstoẞItem((denkanstoẞ) => ({
      ...denkanstoẞ,
      [index]: item,
    }));
    setDenkanstoẞ((prev) =>
      prev.map((denkanstoẞ, i) =>
        i === index
          ? {
              ...denkanstoẞ,
              dropdownValue: item,
            }
          : denkanstoẞ
      )
    );
  };

  const handlePublishClick = async () => {
    let hasError = false;

    if (pairs.length === 0) {
      Toast("Please add at least one pair.", "error");
      return;
    }

    const newErrors: {
      [key: number]: { dropdownValue?: string; inputValue?: string };
    } = {};
    pairs.forEach((item, index) => {
      newErrors[index] = {};
      if (!item.dropdownValue) {
        newErrors[index].dropdownValue = "required";
        hasError = true;
      }
      // if (!item.inputValue) {
      //   newErrors[index].inputValue = "required";
      //   hasError = true;
      // }
    });

    setValidationError((prevErrors) => ({
      ...prevErrors,
      pairs: newErrors,
    }));

    if (hasError) {
      return;
    }

    type SentenceType = {
      id: number;
      value: string;
      type: string;
      order: number;
      isStrike: boolean;
    }[];

    const isDenkanstoB = selectedOption === "Denkanstoẞ";

    const sentence: SentenceType = (isDenkanstoB ? denkanstoẞ : pairs).map(
      (pair) => ({
        id: pair.id || 0,
        value: pair.inputValue,
        type: pair.dropdownValue?.id.toString() || "",
        order: pair.order || 0,
        isStrike: pair.isInputChecked || false,
      })
    );

    const payload: any = {
      exampleId: exampleId || undefined,
      flashCardContentComponentId: flashcardContentId,
      header,
      sentence,
      size,
      footer,
      isDenkanstob: isDenkanstoB,
    };

    if (selectedOption !== "Denkanstoẞ") {
      payload.lightTheme = selectedOption;
    }

    try {
      setIsLoading(true);
      const response = await postRequest(
        `${baseURL}/lesson/update-or-create-example`,
        payload
      );

       if (response?.data.success) {
         const exampleData = response.data.data;
         const particles = response.data.data.sentences;

         setExampleId(exampleData.id || "");
         setHeader(exampleData.header || "");
         setFooter(exampleData.footer || "");
         setSelectedOption(
           exampleData.isDenkanstob
             ? "Denkanstoẞ"
             : exampleData.lightTheme || "Light"
         );
         setSelectedSize(exampleData.size || "Full");
         const fetchedPairs = await Promise.all(
           particles.map(async (item: any, index: number) => {
             const particleData = await fetchParticleById(item.type);
             return {
               id: item.id,
               dropdownValue: {
                 id: particleData.id,
                 name: particleData.name,
                 pId: particleData.pId,
                 particleType: particleData.particleType,
                 particleValue: particleData.particleValue,
                 particleType1: particleData.particleType1,
                 particleValue1: particleData.particleValue1,
                 children: particleData.children || [],
               },
               inputValue: item.value,
               showCheckbox: particleData?.name === "Text",
               isInputChecked: item.isStrike || false,
               order: item.order || index + 1,
             };
           })
         );
         setPairs(fetchedPairs);
         Toast(response?.data.statusMessage, "success");
         navigate(-1);
       }
      setIsLoading(false);
     
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
      Toast("An error occurred while saving the example.", "error");
    }
  };

  const removeSentence = (index: number) => {
    const updatedPairs = [...pairs];
    updatedPairs.splice(index, 1);
    setPairs(updatedPairs);
  };

  const handleDeletePair = (index: number, id?: number) => {
    if (id !== undefined) {
      setSentenceToDelete({ index, id });
      setDeleteDialogMessage("Are you sure you want to delete this sentence?");
      setOnConfirmDelete(() => () => handleConfirmDeletePair(index, id));
      setShowDeleteDialog(true);
    } else {
      handleConfirmDeletePair(index);
    }
  };

  const handleConfirmDeletePair = async (index: number, id?: number) => {
    if (id !== undefined) {
      try {
        const reqData = {
          modelName: "examplesentences",
          id: id,
        };

        const response = await postRequest(
          `${baseURL}/lesson/delete-bretzel-content`,
          reqData
        );
        if (response?.data) {
          const updatedPairs = [...pairs];
          updatedPairs.splice(index, 1);
          setPairs(updatedPairs);
          Toast(response?.data.statusMessage, "success");
        } else {
          console.error("Error deleting pair:", response?.data);
        }
      } catch (error) {
        console.error("Error deleting pair:", error);
      }
    } else {
      const updatedPairs = [...pairs];
      updatedPairs.splice(index, 1);
      setPairs(updatedPairs);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false);
    setSentenceToDelete(null);
  };

  const handleBackClick = async () => {
    try {
      const response = await getRequest(
        `${baseURL}/lesson/get-fetch-flashcard-components/${flashcardContentId}`
      );
      const flashCardId = response?.data.data[0].flashCardId;
      navigate(`/lessons/theme/subthemes/flashcard/${flashCardId}`);
    } catch (error) {
      console.error("Error fetching themeId:", error);
    }
  };

  useEffect(() => {
    let timeLine = gsap.timeline();
    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 0.3,
      ease: "power2.out",
    });

    timeLine.from(".user-data", {
      opacity: 0,
      y: 40,
      duration: 0.5,
      ease: "power2.out",
      stagger: 0.2
    });


  }, [isLoading]);

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-3 main-page">
          <div
            className="d-flex  align-items-center go-back"
            onClick={handleBackClick}
            style={{ width: "fit-content" }}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="me-2"
              style={{
                backgroundColor: "#D9D9D9",
                padding: "7px",
                borderRadius: "8px",
                color: "#000",
                cursor: "pointer",
              }}
            />
            <label
              style={{
                color: "#FF9E00",
                fontSize: "16px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Back
            </label>
          </div>
          <h4
            className="row-container"
            style={{
              textAlign: "center",
              margin: "auto",
              color: "#7542E4",
              fontWeight: "bold",
            }}
          >
            Example
          </h4>
          <VerticalSpace height={30} />
          <div
            className="container example-container"
            style={{ maxWidth: "1117px" }}
          >
            <div
              className="container p-4"
              style={{
                backgroundColor: "rgb(220 220 220 / 66%)",
                borderRadius: "30px",
              }}
            >
              <div className="col-12">
                <div className="d-flex example-top justify-content-between">
                  <div className="d-flex" style={{ gap: "8px" }}>
                    <RadioButton
                      checked={size === "Full"}
                      onChange={() => handleSizeChange("Full")}
                    />
                    <Label fontSize="14px" fontWeight={400}>
                      full
                    </Label>
                    <HorizontalSpace width={20} className="horizontal-space" />
                    <RadioButton
                      checked={size === "Half"}
                      onChange={() => handleSizeChange("Half")}
                    />
                    <Label fontSize="14px" fontWeight={400}>
                      half
                    </Label>
                  </div>
                  <div className="d-flex" style={{ gap: "8px" }}>
                    <RadioButton
                      checked={selectedOption === "Light"}
                      onChange={() => handleOptionChange("Light")}
                    />
                    <Label fontSize="14px" fontWeight={400}>
                      light
                    </Label>

                    <RadioButton
                      checked={selectedOption === "Dark"}
                      onChange={() => handleOptionChange("Dark")}
                    />
                    <Label fontSize="14px" fontWeight={400}>
                      dark
                    </Label>
                  </div>
                </div>
                <VerticalSpace height={16} />
                <>
                  <Label
                    fontSize="20px"
                    padding="0px 0px 0px 8px"
                    fontWeight={700}
                  >
                    Header (sentence)
                  </Label>

                  <VerticalSpace height={16} />
                  <div className=" header-input align-items-center">
                    <TextEditor
                      value={header}
                      onChange={handleHeaderChange}
                      lightTheme={selectedOption as "Light" | "Dark"}
                      height="auto"
                    />
                    <div
                      className="p-0"
                      style={{ cursor: "pointer", width: "fit-content" }}
                      // onClick={() => handleDeleteSubTheme(subtheme.id)}
                    >
                      {/* <img src={deleteIcon} style={{ WebkitUserSelect: "none", userSelect: "none" }}/> */}
                    </div>
                  </div>
                  <VerticalSpace height={26} />
                  <div className="d-flex justify-content-between">
                    <Label
                      fontSize="20px"
                      padding="0px 0px 0px 8px"
                      fontWeight={700}
                    >
                      Sentence (example)
                    </Label>
                  </div>
                  <VerticalSpace height={16} />

                  <div
                    className="col d-flex flex-column sentence-example align-itmes-center"
                    style={{ padding: "0px 14px 0px 0px", gap: "16px" }}
                  >
                    {pairs.map((pair, index) => (
                      <div
                        key={index}
                        className="row sentence-row"
                        style={{ flexWrap: "unset", alignItems: "center" }}
                      >
                        <div
                          className="col sentence-dropdown"
                          style={{ flex: "1", minWidth: "0" }}
                        >
                          <NestedMenu
                            data={
                              pair.dropdownValue ? [pair.dropdownValue!] : []
                            }
                            onSelectItem={(item) =>
                              handleSelectItem(index, item as DropdownValue)
                            }
                            borderColor={
                              validationError.pairs[index]?.dropdownValue
                                ? "#dc3545"
                                : "#D9D9D9"
                            }
                          />
                          <div
                            className="p-0 delete-icon"
                            style={{
                              cursor: "pointer",
                              width: "fit-content",
                            }}
                            onClick={() => handleDeletePair(index, pair.id)}
                          >
                            <img
                              src={deleteIcon}
                              className="user-scale"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col sentence-input">
                          <div style={{ position: "relative" }}>
                            <input
                              type="text"
                              value={pair.inputValue}
                              onChange={(e) =>
                                handleInputChange(index, e.target.value)
                              }
                              className={`form-control ${
                                validationError.pairs[index]?.inputValue
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder=""
                              style={{
                                borderRadius: "10px",
                                height: "40px",
                                fontSize: "14px",
                                textDecoration: pair.isInputChecked
                                  ? "line-through"
                                  : "none",
                                flex: 1,
                              }}
                            />
                            {pair.showCheckbox && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  position: "absolute",
                                  right: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                }}
                              >
                                <CustomCheckbox
                                  checked={!!pair.isInputChecked}
                                  onChange={() => handleCheckboxChange(index)}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className="p-0 delete-button"
                          style={{
                            cursor: "pointer",
                            width: "fit-content",
                          }}
                          onClick={() => handleDeletePair(index, pair.id)}
                        >
                          <img
                            src={deleteIcon}
                            className="user-scale"
                            style={{
                              WebkitUserSelect: "none",
                              userSelect: "none",
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <VerticalSpace height={16} />
                  <button
                    type="button"
                    className="user"
                    style={{
                      color: "#100F15",
                      background: "none",
                      border: "1px solid #100F15",
                      borderRadius: "10px",
                      padding: "14px 20px",
                      fontSize: "14px",
                      width: "auto",
                    }}
                    onClick={addNewPair}
                  >
                    Add new pair
                  </button>
                  <VerticalSpace height={34} />
                  <div>
                    <Label
                      fontSize="20px"
                      padding="0px 0px 0px 8px"
                      fontWeight={700}
                    >
                      Footer (sentence)
                    </Label>

                    <VerticalSpace height={16} />
                    <div className="header-input align-items-center">
                      <TextEditor
                        value={footer}
                        onChange={handleFooterChange}
                        height="auto"
                        lightTheme={selectedOption as "Light" | "Dark"}
                      />
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
          <VerticalSpace height={210} />
          <div
            className="container row d-flex justify-content-center publish-button"
            style={{
              position: "fixed",
              zIndex: 1,
              height: "12%",
              maxWidth: "100%",
              bottom: "0px",
              background:
                "linear-gradient(178.35deg, rgba(255, 255, 255, 0) 1.4%, rgba(255, 255, 255, 0.69) 26.79%, #FFFFFF 50.22%)",
            }}
          >
            <div
              className="d-flex justify-content-center"
              style={{
                position: "fixed",
                zIndex: 1,
                bottom: "34px",
              }}
            >
              <button
                type="button"
                className="btn btn-success d-flex justify-content-center user"
                style={{
                  backgroundColor: "#FF9E00",
                  color: "#100F15",
                  height: "fit-content",
                  borderRadius: "10px",
                  padding: "10px 20%",
                  width: "400px",
                  fontSize: "14px",
                }}
                onClick={handlePublishClick}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {showDeleteDialog && (
        <DeleteConfirmation
          showDeletePopup={true}
          setShowDeletePopup={setShowDeleteDialog}
          message={deleteDialogMessage}
          deleteButtonLabel="Delete"
          onDelete={onConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};
export default ExampleComponents;

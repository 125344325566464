import React from "react";

interface DeleteConfirmationProps {
  showDeletePopup: boolean;
  setShowDeletePopup: (show: boolean) => void;
  message: string; // New prop to pass the user's name
  deleteButtonLabel: string;
  onDelete?: () => void;
  onCancel?: () => void;
}

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({
  showDeletePopup,
  setShowDeletePopup,
  message,
  deleteButtonLabel,
  onDelete,
  onCancel,
}) => {
  const handleDelete = () => {
    if (onDelete) {
      onDelete();
    }
    // Implement delete logic here
    // You can call an API or perform any other action
    setShowDeletePopup(false); // Close the delete popup after deletion
  };

   const handleCancel = () => {
     if (onCancel) {
       onCancel();
     } else {
       setShowDeletePopup(false); // Default behavior if onCancel is not provided
     }
   };

  return (
    <>
      {showDeletePopup && (
        <div
          className="modal"
          role="dialog"
          style={{
            display: "block",
            position: "fixed",
            zIndex: 1000,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          }}
        >
          <div className="modal-dialog">
            <div
              className="modal-content row-container"
              style={{ borderRadius: "24px", padding: "6px" }}
            >
              <div className="modal-body">
                <h5
                  className="modal-body d-flex align-items-center justify-content-center"
                  style={{ fontWeight: "bold", top: "20px" }}
                >
                  {message}
                </h5>
              </div>
              {/* Assuming VerticalSpace is another component */}
              <div className="modal-body d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary user"
                  onClick={handleCancel}
                  style={{
                    height: "50px",
                    color: "#100F15",
                    background: "none",
                    border: "1px solid #100F15",
                    borderRadius: "10px",
                    fontSize: "14px",
                    width: "auto",
                    padding: "14px 35px",
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger user"
                  style={{
                    backgroundColor: "#EB002C",
                    borderRadius: "10px",
                    fontSize: "14px",
                    width: "auto",
                    padding: "14px 35px",
                  }}
                  onClick={handleDelete}
                >
                  {deleteButtonLabel}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteConfirmation;

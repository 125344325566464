import { useState, useEffect } from "react";
import CustomDropdown from "../../Components/Common/CustomDropdown";
import Container from "../../Components/Common/Container";
import RowContainer from "../../Components/Common/RowContainer";
import Label from "../../Components/Common/Label";
import styled from "styled-components";
import VerticalSpace from "../../Components/VerticalSpace";
import ColumnContainer from "../../Components/Common/ColumnContainer";
import TicketsList from "../../Components/Ticket/TicketsList";
import "../../stylesheets/indexpage.css";
import PaginationComponent from "../../Components/Common/PaginationComponent";
import { baseURL, getRequest, postRequest } from "../../api/apiClient";
import Loader from "../../utils/Loading";
import { useNavigate } from "react-router-dom";
import gsap from 'gsap';
import '../../stylesheets/common.css'
import ShimmerEffect from "../../utils/ShimmerEffect";

const InnerRowContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 1100px;
`;

const ScrollableRowContainer = styled(RowContainer)`
  @media (max-width: 1150px) {
    width: 100%;
    overflow-x: auto;
  }
`;

const StatusContainer = styled(RowContainer)`
  @media (max-width: 1150px) {
    display: none;
  }
`;

interface Ticket {
  id: number;
  status: string;
  ticketId: string;
  problem: string;
  description: string;
  email: string;
  userId: string;
  updatedAt: string;
}

interface statusCount {
  Urgent: number;
  Open: number;
  Cancel: number;
  Medium: number;
  Solved: number;
  todays: number;
}

const Tickets = () => {
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [statusCount, setStatusCount] =useState<statusCount>();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(5);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [filters, setFilters] = useState<{
    status?: string;
  }>({});
  const [loading, setLoading] = useState(true);
  const [filterLoading, setFilterLoading]= useState(false);
  const navigate = useNavigate();


  const handleSelect = (item: string) => {
    setSelectedItem(item);
  };

  const dropdownItems: string[] = [
    "All",
    "Urgent",
    "Open",
    "Cancel",
    "Medium",
    "Solved",
  ];

  useEffect(() => {
    fetchTickets(page +1 ,limit,filters);
  }, [page + 1,limit,filters]);

  const fetchTickets = async (
    page: number,
    perPage: number,
    filters: { status?: string },
    showLoader: boolean = true,
  ) => {
    if (showLoader) {
      if (!filters) {
        setLoading(true);
      } else {
        setFilterLoading(true);
      }
    }

    let url = `${baseURL}/ticket/get-tickets?page=${page}&limit=${perPage}`;
    if (filters.status) {
      url += `&status=${filters.status}`;
    }
    const response = await getRequest(url);
    if (response?.data) {
      setTickets(response?.data.data.tickets);
      setStatusCount(response?.data.data.statusCounts);
      setCount(response?.data.data.totalTicketsCount);
      setPage(response?.data.data.page - 1);
      setHasNextPage(response?.data.data.hasNextPage);
      setHasPreviousPage(response?.data.data.hasPreviousPage);
    }

    setLoading(false);
    setFilterLoading(false);
  };

  const handleFiltes = () => {
    switch (selectedItem) {
      case "Urgent":
        setFilters({ status: "Urgent" });
        break;
      case "Open":
        setFilters({ status: "Open" });
        break;
      case "Cancel":
        setFilters({ status: "Cancel" });
        break;
      case "Medium":
        setFilters({ status: "Medium" });
        break;
      case "Solved":
        setFilters({ status: "Solved" });
        break;
      default:
        setFilters({});
        break;
    }
  }

  const handleDeleteMember = (id: number) => {
    setTickets(tickets.filter((ticket) => ticket.id !== id));
  };

  const handleStatusChange =async (id: number, status: string) => {
    const resData = {
      status: status,
    };
    const response:any = await postRequest(
      `${baseURL}/ticket/update-ticket/${id}`,
      resData
    );
    if (response?.data.statusCode === 200) {
      fetchTickets(page + 1, limit, filters, false);
      setTickets(
        tickets.map((ticket) =>
          ticket.id === id ? { ...ticket, status } : ticket
        )
      );
    }
  };

 const handleChangePage = (
   event: React.MouseEvent<HTMLButtonElement> | null,
   newPage: number
 ) => {
   setPage(newPage);
 };

 const handleChangeRowsPerPage = (
   event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
   setLimit(parseInt(event.target.value, 10));
   setPage(0);
 };

 const handleViewTickets = (id: number)=>{
  navigate(`/ticket/${id}`);
 }

 useEffect(() => {
  let timeLine = gsap.timeline();
  timeLine.from(".row-container", {
    opacity: 0,
    y: -40,
    duration: 0.3,
    ease: "power2.out",
  });

  timeLine.from(".user-data", {
    opacity: 0,
    y: 40,
    duration: 0.5,
    ease: "power2.out",
    stagger: 0.2
  });


}, [loading]);
 
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <div className="p-3">
            <div className="row mb-3 ">
              <div className="col">
                <h2 style={{ fontWeight: "bold" }}>Tickets</h2>
              </div>
            </div>

            <div className="row mb-3 main-responsive">
              <div className="col d-flex align-items-end">
                <ColumnContainer className="tickets-filter">
                  <RowContainer width="fit-content">
                    <label htmlFor="filter" className="form-label">
                      Filter
                    </label>
                  </RowContainer>
                  <RowContainer width="fit-content">
                    <CustomDropdown
                      items={dropdownItems}
                      onSelect={handleSelect}
                      selectedItem={selectedItem || ""}
                      height="50px"
                    />
                    <button
                      type="button"
                      className="btn btn-primary ms-3 user"
                      style={{
                        backgroundColor: "#100F15",
                        borderRadius: "10px",
                        color: "#FFFFFF",
                        fontSize: "14px",
                        width: "auto",
                        padding: "14px 50px",
                      }}
                      onClick={handleFiltes}
                    >
                      Apply
                    </button>
                  </RowContainer>
                </ColumnContainer>
              </div>
              <RowContainer
                justifyContent="flex-end"
                width="fit-content"
                className="tickets-count"
              >
                <Container
                  bgColor="#FFFFFF"
                  width="140px"
                  height="fit-content"
                  borderRadius="10px"
                  padding="10px 0px 0px 0px"
                  className="user-box"
                >
                  <ColumnContainer alignItem="center" gap="0px">
                    <Label fontWeight={500} fontSize="22px" color="#100F15">
                      Today’s
                    </Label>
                    <Label fontWeight={500} fontSize="40px" color="#00A3FF">
                      {statusCount?.todays || 0}
                    </Label>
                  </ColumnContainer>
                </Container>
                <Container
                  bgColor="#FFFFFF"
                  width="140px"
                  height="fit-content"
                  borderRadius="10px"
                  padding="10px 0px 0px 0px"
                  className="user-box"
                >
                  <ColumnContainer alignItem="center" gap="0px" color="#100F15">
                    <Label fontWeight={500} fontSize="22px">
                      Open
                    </Label>
                    <Label fontWeight={500} fontSize="40px" color="#7542E4">
                      {statusCount?.Open || 0}
                    </Label>
                  </ColumnContainer>
                </Container>
                <Container
                  bgColor="#FFFFFF"
                  width="140px"
                  height="fit-content"
                  borderRadius="10px"
                  padding="10px 0px 0px 0px"
                  className="user-box"
                >
                  <ColumnContainer alignItem="center" gap="0px">
                    <Label fontWeight={500} fontSize="22px" color="#100F15">
                      Critical
                    </Label>
                    <Label fontWeight={500} fontSize="40px" color="#EB002C">
                      {statusCount?.Urgent || 0}
                    </Label>
                  </ColumnContainer>
                </Container>
                <Container
                  bgColor="#FFFFFF"
                  width="140px"
                  height="fit-content"
                  borderRadius="10px"
                  padding="10px 0px 0px 0px"
                  className="user-box"
                >
                  <ColumnContainer alignItem="center" gap="0px" color="#100F15">
                    <Label fontWeight={500} fontSize="22px">
                      In progress
                    </Label>
                    <Label fontWeight={500} fontSize="40px" color="#FF9E00">
                      {statusCount?.Medium || 0}
                    </Label>
                  </ColumnContainer>
                </Container>
                <Container
                  bgColor="#FFFFFF"
                  width="140px"
                  height="fit-content"
                  borderRadius="10px"
                  padding="10px 0px 0px 0px"
                  className="user-box"
                >
                  <ColumnContainer alignItem="center" gap="0px" color="#100F15">
                    <Label fontWeight={500} fontSize="22px">
                      Cancelled
                    </Label>
                    <Label fontWeight={500} fontSize="40px" color="#D9D9D9">
                      {statusCount?.Cancel || 0}
                    </Label>
                  </ColumnContainer>
                </Container>
                <Container
                  bgColor="#FFFFFF"
                  width="140px"
                  height="fit-content"
                  borderRadius="10px"
                  padding="10px 0px 0px 0px"
                  className="user-box"
                >
                  <ColumnContainer alignItem="center" gap="0px" color="#100F15">
                    <Label fontWeight={500} fontSize="22px">
                      Solved
                    </Label>
                    <Label fontWeight={500} fontSize="40px" color="#28A138">
                      {statusCount?.Solved || 0}
                    </Label>
                  </ColumnContainer>
                </Container>
              </RowContainer>
              {/* </div> */}
            </div>
            <VerticalSpace height={15} />
            <ScrollableRowContainer>
              <InnerRowContainer>
                <Container width="100%">
                  <RowContainer
                    alignItem="center"
                    padding="8px 12px 8px 12px"
                    gap="8px"
                    className="row-container"
                  >
                    <RowContainer flex="0.1">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      ></Label>
                    </RowContainer>
                    <RowContainer flex="4.5">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        Priority
                      </Label>
                    </RowContainer>
                    <RowContainer flex="4">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        ID Ticket
                      </Label>
                    </RowContainer>
                    <RowContainer flex="5">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        Section
                      </Label>
                    </RowContainer>
                    <RowContainer flex="9">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        Message
                      </Label>
                    </RowContainer>
                    <RowContainer flex="9">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        User
                      </Label>
                    </RowContainer>
                    <RowContainer flex="5">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        User ID
                      </Label>
                    </RowContainer>
                    <RowContainer flex="6">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        Date
                      </Label>
                    </RowContainer>
                    <StatusContainer flex="6">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        Status
                      </Label>
                    </StatusContainer>
                    <RowContainer flex="1">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        View
                      </Label>
                    </RowContainer>
                  </RowContainer>
                  {filterLoading ? (
                    <>
                    <RowContainer bgColor="#ffffff" borderRadius="8px" padding="10px 0px">
                      {Array.from({length: 6},(_,index)=>{
                        return(
                          <RowContainer key={index} width={index === 0 || index === 5 ? "10%" : "20%"} justifyContent="center">
                            <ShimmerEffect />
                          </RowContainer>
                        )
                      })}
                    </RowContainer>
                    </>
                  ) : (
                    <>
                      {tickets && tickets.length > 0 ? (
                        <ColumnContainer>
                          {tickets.map((ticket) => (
                            <TicketsList
                              key={ticket.id}
                              ticket={ticket}
                              onDelete={handleDeleteMember}
                              onStatusChange={handleStatusChange}
                              onEdit={handleViewTickets}
                            />
                          ))}
                        </ColumnContainer>
                      ) : (
                        <>
                          <VerticalSpace height={30} />
                          <Label
                            alignItems="center"
                            display="flex"
                            color="rgba(164, 163, 167, 1)"
                            justifyContent="center"
                          >
                            No results found.
                          </Label>
                        </>
                      )}
                    </>
                  )}
                </Container>
              </InnerRowContainer>
            </ScrollableRowContainer>
            <VerticalSpace height={10} />
            <RowContainer justifyContent="flex-end">
              <PaginationComponent
                page={page}
                rowsPerPage={limit}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                count={count}
                hasNextPage={hasNextPage}
                hasPreviousPage={hasPreviousPage}
              />
            </RowContainer>
          </div>
          <VerticalSpace height={100} />
        </div>
      )}
    </>
  );
};
export default Tickets;

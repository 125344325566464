import React, { useState, useRef, useEffect } from "react";
import VerticalSpace from "../../../VerticalSpace";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Label from "../../../Common/Label";
import deleteIcon from "../../../../assets/deleteIcon.svg";
import { useNavigate, useLocation } from "react-router-dom";
import RadioButton from "../../../Common/RadioButton";
import HorizontalSpace from "../../../HorizontalSpace";
import TextEditor from "../../../Common/TextEditor";
import DropdownMenu from "../../../Common/Dropdown";
import options from "../../../../MenuItem.json";
import { baseURL, getRequest, postRequest } from "../../../../api/apiClient";
import { toast } from "react-toastify";
import Toast from "../../../Common/Toast";
import NestedMenu from "../../../ParticlesDropdown/NestedMenu";
import "../../../../stylesheets/theme.css";
import Loader from "../../../../utils/Loading";

interface DropdownValue {
  id: number;
  name: string;
  pId: number;
  particleType: string;
  particleValue: string;
  particleType1: string;
  particleValue1: string;
  children: DropdownValue[];
}

interface GrammarGroupContent {
  id: number;
  type: string;
  description?: string;
  lightTheme: string | null;
  flashimage?: string | null;
  particles?: any;
  dropdownValue?: DropdownValue | null;
  inputValue?: string;
  order: number;
  grammarGroupId?: string;
}

const GrammarGroupContent = () => {
  const [grammarGroupContent, setGrammarGroupContent] = useState<
    GrammarGroupContent[]
  >([]);
  const [description, setDescription] = useState("");
  const [selectedOption, setSelectedOption] = useState<{
    [id: number]: string;
  }>({});
  const [globalTheme, setGlobalTheme] = useState("Light");
  const [flashImage, setFlashImage] = useState<{
    [id: number]: File | string | null;
  }>({});
  const fileInputRef = useRef<{ [id: number]: HTMLInputElement | null }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Record<number, string[]>>({});
  const navigate = useNavigate();
  const location = useLocation();

  const { type, grammarGroupComponentId } = location.state || {};

  const setGrammarGroupType = (selectedItem: string): string => {
    switch (selectedItem) {
      case "BlockText":
        return "blockText";
      case "GrammarSingle":
        return "grammarSingle";
      case "FlashImage":
        return "flashimage";
      default:
        return "";
    }
  };

  useEffect(() => {
    const fetchGrammarGroupContent = async () => {
      try {
        const reqData = {
          grammarGroupComponentId: grammarGroupComponentId,
          type: type,
        };
        setIsLoading(true);
        const response = await postRequest(
          `${baseURL}/lesson/goto-grammar-group-component`,
          reqData
        );

        const formattedData = response?.data.data.map((item: any) => {
          return {
            ...item,
            type: setGrammarGroupType(item.model),
          };
        });

        formattedData.sort(
          (a: GrammarGroupContent, b: GrammarGroupContent) => a.order - b.order
        );

        setGrammarGroupContent(formattedData);

        formattedData.forEach((item: any) => {
          if (item.description)
            setDescription((prev) => `${prev}\n${item.description}`);
          // setSelectedOption((prev) => ({
          //   ...prev,
          //   [item.id]: item.lightTheme || "Light",
          // }));
        //  console.log("item.lightTheme: ", item.model === "BlockText" || "GrammarSingle");

        //   setGlobalTheme(item.lightTheme || "Light");
          if (item.model === "BlockText" || item.model === "GrammarSingle") {
            setGlobalTheme(item.lightTheme || "Light");
          }

          if (item.flashimage) {
            const file = new File([], item.flashimage.split("/").pop());
            setFlashImage((prev) => ({ ...prev, [item.id]: item.flashimage }));
          }
        });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching grammar group content:", error);
      }
    };
    if (grammarGroupComponentId) {
      fetchGrammarGroupContent();
    }
  }, [grammarGroupComponentId, type]);

  const handleOptionChange = (id: number, option: string) => {
    setSelectedOption((prevOptions) => ({
      ...prevOptions,
      [id]: option,
    }));
  };
  const handleDescriptionChange = (id: number, value: string) => {
    setGrammarGroupContent((prevContent) =>
      prevContent.map((item) =>
        item.id === id ? { ...item, description: value } : item
      )
    );

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (newErrors[id]) {
        newErrors[id] = newErrors[id].filter(
          (error) => error !== "Description is required."
        );
        if (newErrors[id].length === 0) {
          delete newErrors[id];
        }
      }
      return newErrors;
    });
  };

  const handleFlashImageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      setFlashImage((prev) => ({
        ...prev,
        [id]: files[0],
      }));
    }

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[id]; // Remove any existing error for this id
      return newErrors;
    });
  };

  const handleChooseFileClick = (id: number) => {
    const fileInputRefs = fileInputRef.current[id];
    if (fileInputRefs) {
      fileInputRefs.click();
    }
  };
  const handleThemeChange = (option: string) => {
    setGlobalTheme(option);
  };

  const fetchParticleById = async (id: number) => {
    try {
      const response = await getRequest(
        `${baseURL}/particle/get-particleById/${id}`
      );
      return response?.data.data;
    } catch (error) {
      console.error("Error fetching particle by ID:", error);
      throw error;
    }
  };

  const handleParticlesDropdownChange = (id: number, value: DropdownValue) => {
    setGrammarGroupContent((prevContent) =>
      prevContent.map((item) =>
        item.id === id
          ? {
              ...item,
              particles: {
                ...item.particles,
                dropdownValue: value,
              },
            }
          : item
      )
    );
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (newErrors[id]) {
        newErrors[id] = newErrors[id].filter(
          (error) => error !== "Particle dropdown value is required."
        );
        if (newErrors[id].length === 0) {
          delete newErrors[id];
        }
      }
      return newErrors;
    });
  };

  const handleParticlesInputChange = (id: number, value: string) => {
    setGrammarGroupContent((prevContent) =>
      prevContent.map((item) =>
        item.id === id
          ? {
              ...item,
              particles: {
                ...item.particles,
                inputValue: value,
              },
            }
          : item
      )
    );

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (newErrors[id]) {
        newErrors[id] = newErrors[id].filter(
          (error) => error !== "Particle input is required."
        );
        if (newErrors[id].length === 0) {
          delete newErrors[id];
        }
      }
      return newErrors;
    });
  };

  const handleSave = async () => {
    const newErrors: Record<number, string[]> = {};

    grammarGroupContent.forEach((item) => {
      if (item.type === "blockText" && !item.description) {
        newErrors[item.id] = [
          ...(newErrors[item.id] || []),
          "Description is required.",
        ];
      }
      if (item.type === "grammarSingle") {
        if (!item.particles?.dropdownValue) {
          newErrors[item.id] = [
            ...(newErrors[item.id] || []),
            "Particle dropdown value is required.",
          ];
        }
      }
      if (item.type === "flashimage" && !flashImage[item.id]) {
        newErrors[item.id] = [
          ...(newErrors[item.id] || []),
          "Flash image is required.",
        ];
      }
    });

    // Set errors state with new validation errors
    setErrors(newErrors);

    // If there are validation errors, do not proceed with saving
    if (Object.keys(newErrors).length > 0) {
      return;
    }

    try {
      const formData = new FormData();
      const updatedItems = await Promise.all(
        grammarGroupContent.map((item) => {
          if (item.type === "blockText") {
            return {
              id: item.id,
              order: item.order,
              type: item.type,
              description: item.description,
              lightTheme: globalTheme || "Light",
            };
          } else if (item.type === "grammarSingle") {
            return {
              id: item.id,
              order: item.order,
              type: item.type,
              lightTheme: globalTheme || "Light",
              particles: item.particles,
              description: item.description,
            };
          } else if (item.type === "flashimage") {
            const flashImages = flashImage[item.id];
            if (flashImages && flashImages instanceof File) {
              const uniqueKey = `flashimage_${item.id}`;
              formData.append(uniqueKey, flashImages);
              return {
                id: item.id,
                order: item.order,
                type: item.type,
                flashimage: uniqueKey,
              };
            } else {
              console.error("flashImage is not a valid File");
              return item;
            }
          }
          return item;
        })
      );
      formData.append("data", JSON.stringify(updatedItems));

      setIsLoading(true);
      const response = await postRequest(
        `${baseURL}/lesson/update-grammar-group-component`,
        formData
      );
      setIsLoading(false);
      Toast(response?.data.statusMessage, "success");
      navigate(-2);
    } catch (error) {
       setIsLoading(false);
      console.error("Error saving data:", error);
    }
  };

  const handleBackClick = async () => {
    try {
      const response = await getRequest(
        `${baseURL}/lesson/get-fetch-grammar-content/${grammarGroupContent[0].grammarGroupId}`
      );

      const flashCardContentComponentId =
        response?.data.data.flashCardContentComponentId;
    

      navigate(`/lessons/theme/subthemes/flashcard/content/grammar-group`, {
        state: {
          flashcardContentId: flashCardContentComponentId,
        },
      });
    } catch (error) {
      console.error("Error fetching themeId:", error);
    }
  };

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-3 main-page">
          <div
            className="d-flex  align-items-center go-back"
            onClick={handleBackClick}
            style={{ width: "fit-content" }}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="me-2"
              style={{
                backgroundColor: "#D9D9D9",
                padding: "7px",
                borderRadius: "8px",
                color: "#000",
                cursor: "pointer",
              }}
            />
            <label
              style={{
                color: "#FF9E00",
                fontSize: "16px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Back
            </label>
          </div>
          <h4
            style={{
              textAlign: "center",
              margin: "auto",
              color: "#7542E4",
              fontWeight: "bold",
            }}
          >
            Grammar group
          </h4>
          <VerticalSpace height={30} />
          <div
            className="d-flex container"
            style={{ gap: "10px", justifyContent: "end", maxWidth: "1065px" }}
          >
            <RadioButton
              checked={globalTheme === "Light"}
              onChange={() => handleThemeChange("Light")}
            />
            <Label fontSize="14px" fontWeight={400}>
              light
            </Label>
            <RadioButton
              checked={globalTheme === "Dark"}
              onChange={() => handleThemeChange("Dark")}
            />
            <Label fontSize="14px" fontWeight={400}>
              dark
            </Label>
          </div>

          <VerticalSpace height={30} />
          {grammarGroupContent.map((item) => (
            <div key={item.id}>
              {item.type === "blockText" && (
                <>
                  <div className="container" style={{ maxWidth: "1117px" }}>
                    <div
                      className="container p-4"
                      style={{
                        backgroundColor: "rgb(220 220 220 / 66%)",
                        borderRadius: "30px",
                      }}
                    >
                      <div className="col-12">
                        <div className="d-flex justify-content-between align-items-center">
                          <Label
                            fontSize="20px"
                            fontWeight={700}
                            padding="0px 0px 0px 10px"
                          >
                            Description
                          </Label>
                          <div className="d-flex" style={{ gap: "8px" }}>
                            <RadioButton
                              checked={globalTheme === "Light"}
                              // onChange={() => handleThemeChange("Light")}
                            />
                            <Label fontSize="14px" fontWeight={400}>
                              light
                            </Label>
                            <HorizontalSpace width={20} />
                            <RadioButton
                              checked={globalTheme === "Dark"}
                              // onChange={() => handleThemeChange("Dark")}
                            />
                            <Label fontSize="14px" fontWeight={400}>
                              dark
                            </Label>
                          </div>
                        </div>
                        <VerticalSpace height={16} />
                        <div>
                          <TextEditor
                            onChange={(value) =>
                              handleDescriptionChange(item.id, value)
                            }
                            value={item.description}
                            lightTheme={globalTheme as "Light" | "Dark"}
                            height="auto"
                          />
                          {errors[item.id]?.includes(
                            "Description is required."
                          ) && (
                            <p
                              className="text-danger"
                              style={{ marginLeft: "10px" }}
                            >
                              Description is required.
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <VerticalSpace height={16} />
                </>
              )}
              {item.type === "grammarSingle" && (
                <>
                  <div className="container" style={{ maxWidth: "1117px" }}>
                    <div
                      className="container p-4"
                      style={{
                        backgroundColor: "rgb(220 220 220 / 66%)",
                        borderRadius: "30px",
                      }}
                    >
                      <div className="col-12">
                        <div className="d-flex justify-content-between align-items-center">
                          <Label
                            fontSize="20px"
                            fontWeight={700}
                            padding="0px 0px 0px 10px"
                          >
                            Particle
                          </Label>
                          <div className="d-flex" style={{ gap: "8px" }}>
                            <RadioButton
                              checked={globalTheme === "Light"}
                              // onChange={() => handleThemeChange("Light")}
                            />
                            <Label fontSize="14px" fontWeight={400}>
                              light
                            </Label>
                            <HorizontalSpace width={20} />
                            <RadioButton
                              checked={globalTheme === "Dark"}
                              // onChange={() => handleThemeChange("Dark")}
                            />
                            <Label fontSize="14px" fontWeight={400}>
                              dark
                            </Label>
                          </div>
                        </div>
                        <VerticalSpace height={16} />
                        <div
                          className="row sentence-row"
                          style={{ flexWrap: "unset", alignItems: "center" }}
                        >
                          <div
                            className="col sentence-dropdown"
                            style={{ flex: "1" }}
                          >
                            <NestedMenu
                              data={
                                item.particles?.dropdownValue
                                  ? [item.particles.dropdownValue]
                                  : []
                              }
                              onSelectItem={(value) =>
                                handleParticlesDropdownChange(
                                  item.id,
                                  value as DropdownValue
                                )
                              }
                              borderColor={
                                errors[item.id] ? "#dc3545" : "#D9D9D9"
                              }
                            />
                          </div>
                          <div className="col sentence-input">
                            <input
                              type="text"
                              value={item.particles?.inputValue || ""}
                              onChange={(e) =>
                                handleParticlesInputChange(
                                  item.id,
                                  e.target.value
                                )
                              }
                              className="form-control"
                              placeholder=""
                              style={{
                                borderRadius: "10px",
                                height: "40px",
                                fontSize: "14px",
                              }}
                            />
                          </div>
                        </div>
                        <VerticalSpace height={16} />
                        <Label
                          fontSize="20px"
                          fontWeight={700}
                          padding="0px 0px 0px 10px"
                        >
                          Description
                        </Label>
                        <VerticalSpace height={16} />
                        <div>
                          <TextEditor
                            // onChange={handleDescriptionChange}
                            onChange={(value) =>
                              handleDescriptionChange(item.id, value)
                            }
                            value={item.description}
                            lightTheme={globalTheme as "Light" | "Dark"}
                            height="auto"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <VerticalSpace height={16} />
                </>
              )}

              {item.type === "flashimage" && (
                <>
                  <div className="container" style={{ maxWidth: "1117px" }}>
                    <div
                      style={{
                        backgroundColor: "rgb(220 220 220 / 66%)",
                        borderRadius: "30px",
                        padding: "18px 20px",
                      }}
                    >
                      {!flashImage[item.id] && (
                        <div>
                          <VerticalSpace height={4} />
                          <div
                            className="drop_box"
                            style={{
                              margin: "0px",
                              padding: "29px 29px 16px 29px",
                            }}
                          >
                            <header>
                              <h4>Upload a image jpg, png or svg</h4>
                            </header>

                            <input
                              type="file"
                              // ref={fileInputRef}
                              ref={(el) => (fileInputRef.current[item.id] = el)}
                              hidden
                              accept="image/*"
                              // onChange={handleFlashImageChange}
                              onChange={(e) =>
                                handleFlashImageChange(e, item.id)
                              }
                            />
                            <VerticalSpace height={20} />
                            <button
                              type="button"
                              className="btn btn-outline-secondary me-2 text-center"
                              style={{
                                color: "#100F15",
                                background: "none",
                                border: "1px solid #100F15",
                                borderRadius: "10px",
                                padding: "14px 30px",
                                fontSize: "14px",
                                width: "auto",
                              }}
                              // onClick={handleChooseFileClick}
                              onClick={() => handleChooseFileClick(item.id)}
                            >
                              Choose file
                            </button>
                            {errors[item.id]?.map((error, index) => (
                              <p
                                key={index}
                                className="text-danger"
                                style={{ marginTop: "10px" }}
                              >
                                {error}
                              </p>
                            ))}
                          </div>
                        </div>
                      )}
                      {flashImage[item.id] && (
                        <div
                          className="drop_box"
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            {typeof flashImage[item.id] === "string" ? (
                              <>
                                <img
                                  src={flashImage[item.id] as string}
                                  alt="flash-image"
                                  style={{
                                    maxWidth: "100px",
                                    maxHeight: "100px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    document
                                      .getElementById(`fileInput-${item.id}`)
                                      ?.click()
                                  }
                                />
                                <input
                                  type="file"
                                  id={`fileInput-${item.id}`}
                                  style={{ display: "none" }}
                                  onChange={(e) =>
                                    handleFlashImageChange(e, item.id)
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  // src={URL.createObjectURL(flashImage)}
                                  src={
                                    flashImage[item.id]
                                      ? URL.createObjectURL(
                                          flashImage[item.id] as File
                                        )
                                      : ""
                                  }
                                  alt="flash"
                                  style={{
                                    maxWidth: "100px",
                                    maxHeight: "100px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    document
                                      .getElementById(`fileInput-${item.id}`)
                                      ?.click()
                                  }
                                />
                                <input
                                  type="file"
                                  id={`fileInput-${item.id}`}
                                  style={{ display: "none" }}
                                  onChange={(e) =>
                                    handleFlashImageChange(e, item.id)
                                  }
                                />
                              </>
                            )}
                            {typeof flashImage[item.id] !== "string" &&
                            flashImage[item.id] ? (
                              <p
                                style={{
                                  color: "#000000",
                                  marginTop: "0%",
                                  marginBottom: "0%",
                                }}
                              >
                                {(flashImage[item.id] as File).name}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <VerticalSpace height={16} />
                </>
              )}
            </div>
          ))}
          <VerticalSpace height={210} />
          <div
            className="container row d-flex justify-content-center publish-button"
            style={{
              position: "fixed",
              zIndex: 2,
              height: "12%",
              maxWidth: "100%",
              bottom: "0px",
              background:
                "linear-gradient(178.35deg, rgba(255, 255, 255, 0) 1.4%, rgba(255, 255, 255, 0.69) 26.79%, #FFFFFF 50.22%)",
            }}
          >
            <div
              className="d-flex justify-content-center"
              style={{
                position: "fixed",
                zIndex: 1,
                bottom: "34px",
              }}
            >
              <button
                type="button"
                className="btn btn-success d-flex justify-content-center"
                style={{
                  backgroundColor: "#FF9E00",
                  color: "#100F15",
                  height: "fit-content",
                  borderRadius: "10px",
                  padding: "10px 20%",
                  width: "400px",
                  fontSize: "14px",
                }}
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default GrammarGroupContent;

import { useEffect, useState } from "react";
import VerticalSpace from "../../../VerticalSpace";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Label from "../../../Common/Label";
import ArrowIcon from "../../../../assets/ArrowIcon.svg";
import editIcon from "../../../../assets/editIcon.svg";
import deleteIcon from "../../../../assets/deleteIcon.svg";
import CustomDropdown from "../../../Common/CustomDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { baseURL, getRequest, postRequest } from "../../../../api/apiClient";
import Toast from "../../../Common/Toast";
import "../../../../stylesheets/theme.css";
import DeleteConfirmation from "../../../Common/DeleteConfirmation";
import Loader from "../../../../utils/Loading";
import "../../../../stylesheets/common.css";

interface GrammarGroup {
  id: number;
  name: string;
  type: string;
  order: number;
}

const GrammarGroup = () => {
  // const [grammarGroupId , setGrammarGroupId] = useState("");
  const [grammarGroup, setGrammarGroup] = useState<GrammarGroup[]>([]);
  const [addGrammarGroup, setNewGrammarGroup] = useState(false);
  const [group, setGroup] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [grammarGroupId, setGrammarGroupId] = useState<number | null>(null);
  const [deleteDialogMessage, setDeleteDialogMessage] = useState("");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [onConfirmDelete, setOnConfirmDelete] = useState<() => void>(() => {});
  const [grammarGroupToDelete, setGrammarGroupToDelete] = useState<
    number | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { flashcardContentId } = location.state || {};

  const dropdownItems: string[] = [
    "Select Item",
    "Block text",
    "Grammar single",
    "Image / svg",
  ];

  const handleSelect = (item: string) => {
    setGroup(item);
    setSelectedItem(item);
  };

  const handleNewGrammarGroup = () => {
    setNewGrammarGroup(true);
  };

  const getGrammarGroup = (selectedItem: string): string => {
    switch (selectedItem) {
      case "Block text":
        return "blockText";
      case "Grammar single":
        return "grammarSingle";
      case "Image / svg":
        return "flashimage";
      default:
        return "";
    }
  };

  const setGrammarGroupName = (selectedItem: string): string => {
    switch (selectedItem) {
      case "BlockText":
        return "Block Text";
      case "GrammarSingle":
        return "Grammar single";
      case "FlashImage":
        return "Image / svg";
      default:
        return "";
    }
  };

  const setGrammarGroupType = (selectedItem: string): string => {
    switch (selectedItem) {
      case "BlockText":
        return "blockText";
      case "GrammarSingle":
        return "grammarSingle";
      case "FlashImage":
        return "flashimage";
      default:
        return "";
    }
  };

  const getModelName = (type: string): string => {
    switch (type) {
      case "blockText":
        return "blockTexts";
      case "grammarSingle":
        return "grammarSingles";
      case "flashimage":
        return "flashimages";
      default:
        throw new Error(`Unknown type: ${type}`);
    }
  };

  useEffect(() => {
    const fetchGrammarGroup = async () => {
      try {
        setIsLoading(true);
        const response = await getRequest(
          `${baseURL}/lesson/get-grammar-group-component-flashcard-content-componentbyid/${flashcardContentId}`
        );
      
        const responseData = response?.data?.data.sortedData;
        const responseGrammarGroupId = response?.data?.data.grammarGroupId;
        setGrammarGroupId(responseGrammarGroupId);

        if (responseData && responseData.length > 0) {
          setGrammarGroup(
            responseData.map((group: any, index: number) => ({
              id: group.id,
              name: setGrammarGroupName(group.model),
              type: setGrammarGroupType(group.model),
              order: index + 1,
            }))
          );
          setGrammarGroupId(responseData[0].grammarGroupId);
        } else {
          setGrammarGroup([]);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching grammar group:", error);
      }
    };
    if (flashcardContentId) {
      fetchGrammarGroup();
    }
  }, [flashcardContentId]);

  const handleSaveGrammarGroup = async () => {
    const grammarGroupType = getGrammarGroup(selectedItem);

    try {
      let currentGrammarGroupId = grammarGroupId;

      if (!currentGrammarGroupId) {
        let data = { flashCardContentComponentId: flashcardContentId };
        const createResponse = await postRequest(
          `${baseURL}/lesson/create-grammar-group`,
          data
        );

        currentGrammarGroupId = createResponse?.data.data.id;
        setGrammarGroupId(currentGrammarGroupId);
      }

      const reqData = {
        grammarGroupId: currentGrammarGroupId,
        type: grammarGroupType,
        order: grammarGroup.length + 1,
      };
      setIsLoading(true);
      const response = await postRequest(
        `${baseURL}/lesson/add-grammar-group-component`,
        reqData
      );
      setIsLoading(false);
      const newGroup = response?.data.data.map(
        (grammarGroupContent: any, index: number) => ({
          id: grammarGroupContent.id,
          name: setGrammarGroupName(grammarGroupContent.model),
          type: setGrammarGroupType(grammarGroupContent.model),
          order: index + 1,
        })
      );
      setGrammarGroup(newGroup);
      setGroup("");
      setNewGrammarGroup(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error creating grammar group:", error);
    }
  };

  const handleEditGrammarGroup = async (id: number) => {
    const selectedGroup = grammarGroup.find((group) => group.id === id);
    if (selectedGroup) {
      const { id, type } = selectedGroup;

      const payload = { grammarGroupComponentId: id, type };
     

      const response = await postRequest(
        `${baseURL}/lesson/goto-grammar-group-component`,
        payload
      );
      navigate(
        "/lessons/theme/subthemes/flashcard/content/grammar-group/content",
        {
          state: {
            type: selectedGroup.type,
            grammarGroupComponentId: selectedGroup.id,
          },
        }
      );
    }
  };

  const handleConfirmDeleteGrammarGroup = async (id: number) => {
    try {
      const selectedGrammarGroup = grammarGroup.find((item) => item.id === id);

      if (!selectedGrammarGroup) {
        throw new Error("Grammar group item not found");
      }

      const modelName = getModelName(selectedGrammarGroup.type);

      const reqData = {
        modelName: modelName,
        id: id,
      };
      const response = await postRequest(
        `${baseURL}/lesson/delete-bretzel-content`,
        reqData
      );
      Toast(response?.data.statusMessage, "success");
      const updatedGrammarGroup = grammarGroup.filter(
        (group) => group.id !== id
      );
      setShowDeleteDialog(false);
      setGrammarGroupToDelete(null);
      setGrammarGroup(updatedGrammarGroup);
    } catch (error) {
      console.error("Error deleting grammar group Content:", error);
      setShowDeleteDialog(false);
    }
  };

   const handleDeleteGrammarGroup = (id: number, name: string) => {
     setGrammarGroupToDelete(id);
     setDeleteDialogMessage(`Are you sure you want to delete this "${name}"?`);
     setOnConfirmDelete(() => () => handleConfirmDeleteGrammarGroup(id));
     setShowDeleteDialog(true);
   };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false);
    setGrammarGroupToDelete(null);
  };
  
  const handleBackClick = async () => {
    try {
      const response = await getRequest(
        `${baseURL}/lesson/get-fetch-flashcard-components/${flashcardContentId}`
      );
      const flashCardId = response?.data.data[0].flashCardId;
      navigate(`/lessons/theme/subthemes/flashcard/${flashCardId}`);
    } catch (error) {
      console.error("Error fetching themeId:", error);
    }
  };

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="p-3 main-page">
            <div
              className="d-flex  align-items-center go-back"
              onClick={handleBackClick}
              style={{ width: "fit-content" }}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="me-2"
                style={{
                  backgroundColor: "#D9D9D9",
                  padding: "7px",
                  borderRadius: "8px",
                  color: "#000",
                  cursor: "pointer",
                }}
              />
              <label
                style={{
                  color: "#FF9E00",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Back
              </label>
            </div>
            <VerticalSpace height={10} />
            <h4
              style={{
                textAlign: "center",
                margin: "auto",
                color: "#7542E4",
                fontWeight: "bold",
              }}
            >
              Grammar group
            </h4>
            <VerticalSpace height={20} />
            <div className="row d-flex grammar-group-container">
              <div className="col grammar-group-content">
                <div
                  className="container d-flex justify-content-center"
                  style={{ maxWidth: "666px" }}
                >
                  <div
                    className="container p-4"
                    style={{
                      backgroundColor: "rgb(220 220 220 / 66%)",
                      borderRadius: "30px",
                    }}
                  >
                    <div className="col-12">
                      {/* <div className="d-flex justify-content-between align-items-center"> */}
                      <Label fontSize="20px" fontWeight={700}>
                        Grammar group
                      </Label>
                      {/* </div> */}
                      <div>
                        {grammarGroup.length === 0 ? (
                          <div className="p-5 d-flex justify-content-center align-items-center ">
                            <label
                              htmlFor="theme"
                              className="form-label"
                              style={{ fontWeight: "bold", color: "#7542E4" }}
                            >
                              There are no Grammar Group yet in this FlashCard
                              Content.
                            </label>
                          </div>
                        ) : (
                          grammarGroup.map((grammar) => (
                            <div
                              className="d-flex justify-content-center align-items-center "
                              style={{ gap: "10px" }}
                              key={grammar.id}
                            >
                              <div
                                className="drop_box grammar-group-type col-10"
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <Label
                                    fontSize="14px"
                                    color="#000"
                                    fontWeight={400}
                                  >
                                    {grammar.name}
                                  </Label>
                                </div>

                                <div>
                                  <img
                                    src={ArrowIcon}
                                    style={{
                                      WebkitUserSelect: "none",
                                      userSelect: "none",
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                className="p-0"
                                style={{
                                  cursor: "pointer",
                                  width: "fit-content",
                                }}
                                onClick={() =>
                                  handleEditGrammarGroup(grammar.id)
                                }
                              >
                                <img
                                  src={editIcon}
                                  style={{
                                    WebkitUserSelect: "none",
                                    userSelect: "none",
                                  }}
                                />
                              </div>

                              <div
                                className="p-0"
                                style={{
                                  cursor: "pointer",
                                  width: "fit-content",
                                }}
                                onClick={() =>
                                  handleDeleteGrammarGroup(
                                    grammar.id,
                                    grammar.name
                                  )
                                }
                              >
                                <img
                                  src={deleteIcon}
                                  style={{
                                    WebkitUserSelect: "none",
                                    userSelect: "none",
                                  }}
                                />
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                      <VerticalSpace height={20} />
                      <div className="d-flex justify-content-between align-items-center">
                        <button
                          type="button"
                          className="btn btn-outline-secondary me-2 text-center"
                          style={{
                            color: "#100F15",
                            background: "none",

                            border: "1px solid #100F15",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            padding: "10px",
                            borderRadius: "10px",
                          }}
                          onClick={handleNewGrammarGroup}
                        >
                          Add new
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <VerticalSpace height={5} />
            </div>
          </div>

          {addGrammarGroup && (
            <div
              className="modal"
              role="dialog"
              style={{
                display: "block",
                position: "fixed",
                zIndex: 1,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="modal-dialog">
                <div className="modal-content" style={{ borderRadius: "20px" }}>
                  <div className="modal-body p-3">
                    <h5 style={{ fontWeight: "bold" }}>
                      Add a Flashcard Content Component
                    </h5>
                    <VerticalSpace height={10} />
                    <CustomDropdown
                      items={dropdownItems}
                      onSelect={handleSelect}
                      width="-webkit-fill-available"
                    />
                  </div>
                  <div className="modal-body d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn btn-secondary user"
                      onClick={() => setNewGrammarGroup(false)}
                      style={{
                        padding: "12px",
                        color: "#100F15",
                        background: "none",
                        border: "1px solid #100F15",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger user"
                      style={{
                        backgroundColor: "#FF9E00",
                        color: "#100F15",
                      }}
                      onClick={handleSaveGrammarGroup}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {showDeleteDialog && (
        <DeleteConfirmation
          showDeletePopup={true}
          setShowDeletePopup={setShowDeleteDialog}
          message={deleteDialogMessage}
          deleteButtonLabel="Delete"
          onDelete={onConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};
export default GrammarGroup;

import React, { useState, useRef, useEffect } from "react";
import "../../assets/css/members.css";
import RowContainer from "../../Components/Common/RowContainer";
import Container from "../../Components/Common/Container";
import Label from "../../Components/Common/Label";
import ColumnContainer from "../../Components/Common/ColumnContainer";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CustomDropdown from "../../Components/Common/CustomDropdown";
import formatDate from "../../Components/DateFormat";
import VerticalSpace from "../../Components/VerticalSpace";
import MultipleOption from "../../Components/MultipleOptions/MultipleOption";
import "../../stylesheets/indexpage.css";
import PaginationComponent from "../../Components/Common/PaginationComponent";
import { baseURL, getRequest, postRequest } from "../../api/apiClient";
import Toast from "../../Components/Common/Toast";
import DeleteConfirmation from "../../Components/Common/DeleteConfirmation";
import { setDropDown } from "../../redux/lessonsSlice";
import { setParticles } from "../../redux/particlesReducerSlice";
import { useDispatch } from "react-redux";
import Loader from "../../utils/Loading";
import gsap from 'gsap';
import '../../stylesheets/common.css'
import ShimmerEffect from "../../utils/ShimmerEffect";

const InnerRowContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 1100px;
`;

const ScrollableRowContainer = styled(RowContainer)`
  @media (max-width: 1150px) {
    width: 100%;
    overflow-x: auto;
  }
`;

interface MultipleOptionsList {
  id: number;
  themeName: string;
  points: string;
  updatedAt: string;
  active: boolean;
}

const MultipleOptionsList = () => {
  const [multipleOptions, setMultipleOptions] = useState<MultipleOptionsList[]>(
    []
  );
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
   const [showDeleteDialog, setShowDeleteDialog] = useState(false);
   const [selectedMultipleOptions, setSelectedMultipleOptions] = useState<number[]>([]);
   const [selectedMultipleOptionsId, setSelectedMultipleOptionsId] = useState<number | null>(
     null
   );
  const [loading, setLoading] = useState<boolean>(true);
  const [filterLoading, setFilterLoading]= useState(false);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(5);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [filters, setFilters] = useState<{
    level?: string;
    updatedAt?: number;
    active?: string;
  }>({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSelect = (item: string) => {
    setSelectedItem(item);
  };

  const dropdownItems: string[] = [
    "All",
    "Level",
    "Active",
    "InActive",
    "Latest update",
  ];

  useEffect(() => {
    fetchMultipleOptions(page + 1, limit, filters);
  }, [page, limit, filters]);

  //fetch Colors list
  const fetchMultipleOptions = async (
    page: number,
    perPage: number,
    filters: { level?: string; updatedAt?: number; active?: string }
  ) => {
    if (!filters) {
      setLoading(true);
    } else {
      setFilterLoading(true);
    }

    try {
      let url = `${baseURL}/multiple-option-challenge/get-all-multiple-option-challenge?page=${page}&limit=${perPage}`;
      if (filters.level) {
        url += `&level=${filters.level}`;
      }
      if (filters.updatedAt) {
        url += `&updatedAt=${filters.updatedAt}`;
      }
      if (typeof filters.active !== "undefined") {
        url += `&active=${filters.active}`;
      }
      const response = await getRequest(url);
      if (response?.data) {
        setMultipleOptions(response.data.data.result);
        setCount(response.data.data.totalChallengesCount);
        setPage(response.data.data.page - 1);
        setHasNextPage(response.data.data.hasNextPage);
        setHasPreviousPage(response.data.data.hasPreviousPage);
      }
      setLoading(false);
      setFilterLoading(false);

      const fetchLessons = await getRequest(
        `${baseURL}/notification-challenge/get-lesson-dropdown`
      );

      const fetchParticles = await getRequest(
        `${baseURL}/particle/get-dropdown`
      );
      dispatch(setDropDown(fetchLessons?.data?.data || []));
      dispatch(setParticles(fetchParticles?.data?.data || []));
    } catch (error) {
      setLoading(false);
      console.error("Error fetching lessons:", error);
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleApplyFilters = () => {
    switch (selectedItem) {
      case "Level":
        setFilters({ level: "A1,A2,B1" });
        break;
      case "Latest update":
        setFilters({ updatedAt: -1 });
        break;
      case "Active":
        setFilters({ active: "true" });
        break;
      case "InActive":
        setFilters({ active: "false" });
        break;
      default:
        setFilters({});
        break;
    }
  };


  const handleEditMultipleOptions = (id: number) => {
    navigate(`/multiple-options/edit-multiple-options/${id}`);
  };

  const handleCreateMultipleOption = () => {
    navigate("/multiple-options/create-multiple-options");
  };

  const handleStatusChange = async (id: number, active: boolean) => {
    const reqData = {
      modelName: "multipleOptionChallenge",
      id: id,
      isActive: !active,
    };
    await postRequest(`${baseURL}/change-status`, reqData);
    // await fetchMultipleOptions(page + 1, limit, filters);
  };

  const onCheckboxChange = (id: number) => {
    if (selectedMultipleOptions.includes(id)) {
      setSelectedMultipleOptions(
        selectedMultipleOptions.filter((selectedId) => selectedId !== id)
      );
    } else {
      setSelectedMultipleOptions([...selectedMultipleOptions, id]);
    }
  };

  const deleteSelectedMultipleOptions = () => {
    if (selectedMultipleOptions.length > 0) {
      setShowDeleteDialog(true);
    } else {
      Toast("Please select at least one challenge to delete.", "error");
    }
  };

  const deleteMultipleOptions = async (
    multipleOptionsIds: number | number[]
  ) => {
    try {
      const payload = {
        id: Array.isArray(multipleOptionsIds)
          ? multipleOptionsIds
          : multipleOptionsIds,
      };
      //  setLoading(true);
      const response = await postRequest(
        `${baseURL}/multiple-option-challenge/delete-multiple-option-challenge-by-id`,
        payload
      );

      if (response?.status === 200) {
        Toast("Challenge deleted successfully", "success");
        setMultipleOptions((prevData) =>
          prevData.filter((options) =>
            Array.isArray(multipleOptionsIds)
              ? !multipleOptionsIds.includes(options.id)
              : options.id !== multipleOptionsIds
          )
        );
        // setLoading(false);
      } else {
        // setLoading(false);
        Toast("Failed to delete challenge", "error");
      }
    } catch (error) {
      console.error("Error deleting challenge:", error);
    }
  };

  const handleDelete = async () => {
    if (selectedMultipleOptionsId !== null) {
      await deleteMultipleOptions(selectedMultipleOptionsId);
    } else {
      await deleteMultipleOptions(selectedMultipleOptions);
    }
    // await fetchMultipleOptions(page + 1, limit, filters);
    setSelectedMultipleOptions([]);
    setSelectedMultipleOptionsId(null);
    setShowDeleteDialog(false);
  };

  const toggleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  useEffect(() => {
    let timeLine = gsap.timeline();
    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 0.3,
      ease: "power2.out",
    });

    timeLine.from(".user-data", {
      opacity: 0,
      y: 40,
      duration: 0.5,
      ease: "power2.out",
      stagger: 0.2
    });


  }, [loading]);

  return (
    <>
      {" "}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="container-fluid">
            <div className="p-3">
              <div className="row mb-3 ">
                <div className="col">
                  <h2 style={{ fontWeight: "bold" }}>
                    Challenges multiple option
                  </h2>
                </div>
              </div>

              {/* Filter Label */}
              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="filter" className="form-label">
                    Filter
                  </label>
                </div>
              </div>

              {/* Buttons Row */}
              <div className="row mb-3 main-responsive">
                <div className="col d-flex responsive">
                  <CustomDropdown
                    items={dropdownItems}
                    onSelect={handleSelect}
                    selectedItem={selectedItem || ""}
                    height="50px"
                  />
                  <button
                    type="button"
                    className="btn btn-primary ms-3 user"
                    style={{
                      backgroundColor: "#100F15",
                      borderRadius: "10px",
                      color: "#FFFFFF",
                      fontSize: "14px",
                      width: "auto",
                      padding: "14px 50px",
                    }}
                    onClick={handleApplyFilters}
                  >
                    Apply
                  </button>
                </div>
                <div className="col d-flex justify-content-end responsive">
                  {/* <button
              type="button"
              className="btn btn-outline-secondary me-2 text-center"
              style={{
                color: "#100F15",
                background: "none",
                border: "1px solid #100F15",
                borderRadius: "10px",
                fontSize: "14px",
                width: "auto",
                padding: "14px 40px",
              }}
              onClick={deleteSelectedMultipleOptions}
            >
              Delete all
            </button> */}
                  <button
                    type="button"
                    className="btn btn-success user"
                    style={{
                      backgroundColor: "#FF9E00",
                      borderRadius: "10px",
                      color: "#100F15",
                      fontSize: "14px",
                      width: "auto",
                      padding: "14px 40px",
                    }}
                    onClick={handleCreateMultipleOption}
                  >
                    Add New
                  </button>
                </div>
              </div>

              <ScrollableRowContainer>
                <InnerRowContainer>
                  <Container width="100%">
                    <RowContainer
                      alignItem="center"
                      padding="8px 12px 8px 12px"
                      gap="8px"
                      className="row-container"
                    >
                      <RowContainer flex="1.4">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        ></Label>
                      </RowContainer>
                      <RowContainer flex="13">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Theme
                        </Label>
                      </RowContainer>
                      <RowContainer flex="6">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Point
                        </Label>
                      </RowContainer>
                      <RowContainer flex="5">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Latest updated
                        </Label>
                      </RowContainer>
                      <RowContainer flex="2.5">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Action
                        </Label>
                      </RowContainer>
                    </RowContainer>
                    {filterLoading ? (
                      <>
                      <RowContainer bgColor="#ffffff" borderRadius="8px" padding="10px 0px">
                        {Array.from({length: 6},(_,index)=>{
                          return(
                            <RowContainer key={index} width={index === 0 || index === 5 ? "10%":"20%"} justifyContent="center">
                              <ShimmerEffect/>
                            </RowContainer>
                          )
                        })}
                      </RowContainer>
                      </>
                    ) : (
                      <>
                        {multipleOptions && multipleOptions.length > 0 ? (
                          <ColumnContainer>
                            {multipleOptions.map((multipleOption) => (
                              <MultipleOption
                                key={multipleOption.id}
                                id={multipleOption.id}
                                theme={multipleOption.themeName}
                                point={`${multipleOption.points} pts`}
                                created={formatDate(multipleOption.updatedAt)}
                                onEdit={handleEditMultipleOptions}
                                onDelete={() => {
                                  setSelectedMultipleOptionsId(
                                    multipleOption.id
                                  );
                                  toggleDeleteDialog();
                                }}
                                isChecked={selectedMultipleOptions.includes(
                                  multipleOption.id
                                )}
                                onCheckboxChange={onCheckboxChange}
                                status={multipleOption.active}
                                onStatusChange={() =>
                                  handleStatusChange(
                                    multipleOption.id,
                                    multipleOption.active
                                  )
                                }
                              />
                            ))}
                          </ColumnContainer>
                        ) : (
                          <>
                            <VerticalSpace height={30} />
                            <Label
                              alignItems="center"
                              display="flex"
                              color="rgba(164, 163, 167, 1)"
                              justifyContent="center"
                            >
                              No results found.
                            </Label>
                          </>
                        )}
                      </>
                    )}
                  </Container>
                </InnerRowContainer>
              </ScrollableRowContainer>
              <VerticalSpace height={10} />
              <RowContainer justifyContent="flex-end" className="user-data">
                <PaginationComponent
                  page={page}
                  rowsPerPage={limit}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  count={count}
                  hasNextPage={hasNextPage}
                  hasPreviousPage={hasPreviousPage}
                />
              </RowContainer>
            </div>
            {showDeleteDialog && (
              <DeleteConfirmation
                showDeletePopup={true}
                setShowDeletePopup={setShowDeleteDialog}
                message={`Are you sure you want to delete ${
                  selectedMultipleOptions.length === 0
                    ? "this challenge ?"
                    : selectedMultipleOptions.length === 1
                    ? "this challenges ?"
                    : "these challenges ?"
                }`}
                deleteButtonLabel="Delete"
                onDelete={handleDelete}
              />
            )}
            <VerticalSpace height={100} />
          </div>
        </>
      )}
    </>
  );
};

export default MultipleOptionsList;

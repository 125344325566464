import React from "react";
import Container from "../Common/Container";
import RowContainer from "../Common/RowContainer";
import Label from "../Common/Label";
import ColumnContainer from "../Common/ColumnContainer";
import isUpIcon from "../../assets/Images/isUp.png";
import isLossIcon from "../../assets/Images/isLoss.png";
import styled from "styled-components";

interface UserCounts {
  name: string;
  users: number | string;
  progress: number;
  isLoss: boolean;
}

interface UsersCountsProps {
  counts: UserCounts[];
}

const NewRowContainer = styled(RowContainer)`
  @media (max-width: 510px) {
    flex-wrap: wrap;
    gap: 30px;
  }
`;

// Create the UsersCounts component
const UsersCounts: React.FC<UsersCountsProps> = ({ counts }) => {
  return (
    <Container>
      <NewRowContainer justifyContent="space-between">
        {counts.map((count, index) => (
          <ColumnContainer flex="auto" gap="10px">
            <Label fontSize="16px" fontWeight={700} color="#FFFFFF">
              {count.name}
            </Label>
            <Label className="dashboaed-score" fontSize="20px" fontWeight={700} color="#FFFFFF">
              {count.users}
            </Label>
            <Label
              fontSize="11px"
              fontWeight={700}
              color="#FFFFFF"
              bgColor={count.isLoss ? "#EB002C" : "#28A138"}
              borderRadius="12px"
              padding="1px 5px"
              display="flex"
              alignItems="center"
              className="dashboaed-scale"
            >
              {count.isLoss ? (
                <img
                  src={isLossIcon}
                  alt="Loss Icon"
                  width="20px"
                  height="20px"
                />
              ) : (
                <img src={isUpIcon} alt="Up Icon" width="20px" height="20px" />
              )}
              {`${count.isLoss ? "-" : "+"} ${count.progress} last month`}
            </Label>
          </ColumnContainer>
        ))}
      </NewRowContainer>
    </Container>
  );
};

export default UsersCounts;

import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import deleteIcon from "../../../assets/deleteIcon.svg";
import VerticalSpace from "../../VerticalSpace";
import Label from "../../Common/Label";
import editIcon from "../../../assets/editIcon.svg";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "../../../stylesheets/theme.css";
import {
  baseURL,
  deleteRequest,
  getRequest,
  multiPostRequest,
  postRequest,
} from "../../../api/apiClient";
import Toast from "../../Common/Toast";
import DeleteConfirmation from "../../Common/DeleteConfirmation";
import Loader from "../../../utils/Loading";
import gsap from "gsap";
import "../../../stylesheets/common.css";
import DeleteLoader from "../../../utils/deleteLoader";
import RowContainer from "../../Common/RowContainer";
import TextEditor from "../../Common/TextEditor";
import NestedMenu from "../../ParticlesDropdown/NestedMenu";
import HorizontalSpace from "../../HorizontalSpace";

interface SubTheme {
  id: number;
  name: string;
  cards: number;
  order?: number;
}

interface DropdownValue {
  id: number;
  name: string;
  pId: number;
  particleType: string;
  particleValue: string;
  particleType1: string;
  particleValue1: string;
  children: DropdownValue[];
}

interface Pair {
  id?: number;
  dropdownValue: DropdownValue | null;
  inputValue: string;
  order?: number;
}

const SubThemes = () => {
  const { id } = useParams();
  const [addSubThemes, setNewThemes] = useState(false);
  const [themeName, setThemeName] = useState("");
  const [intro, setIntro] = useState("");
  const [introTitle, setIntroTitle] = useState("");
  const [subthemes, setSubThemes] = useState<SubTheme[]>([]);
  const [editSubThemes, setEditSubThemes] = useState<SubTheme | null>(null);
  const [subThemeError, setSubThemeError] = useState("");
  const [parentThemeName, setParentThemeName] = useState("");
  const [deleteDialogMessage, setDeleteDialogMessage] = useState("");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [onConfirmDelete, setOnConfirmDelete] = useState<() => void>(() => { });
  const [subThemeToDelete, setSubThemeToDelete] = useState<number | null>(null);
  const [pairs, setPairs] = useState<Pair[]>([]);
  const [startingLessonImage, setStartingLessonImage] = useState("");
  const [startingLessonImageLoading, setStartingLessonImageLoading] =
    useState(false);
  const [pairToDelete, setPairToDelete] = useState<{
    index: number;
    id?: number;
  } | null>(null);
  const [validationError, setValidationError] = useState<{
    pairs: {
      [key: number]: {
        dropdownValue?: string;
        inputValue?: string;
      };
    };
  }>({
    pairs: {},
  });
  const [errors, setErrors] = useState({
    title: "",
    startingLessonImage: "",
    myLessonsImage: "",
    introTitle: "",
    intro: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [myLessonsImage, setMyLessonsImage] = useState("");
  const [myLessonsImageLoading, setMyLessonsImageLoading] = useState(false);
  const startingLessonInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const myLessonsInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const { themeId: themeId } = location.state || {};

  useEffect(() => {
    const fetchSubThemes = async () => {
      try {
        setIsLoading(true);
        const response = await getRequest(
          `${baseURL}/lesson/get-subthemes/${id}`
        );

        const SubThemeData = response?.data.data.subTheme;
        const IntroData = response?.data.data.themeData.themes;
        const particles = response?.data.data.themeData.sortedParticles;

        const handlecardsCount = SubThemeData.map(async (subTheme: any) => {
          const reqData = {
            modelName: "flashcards",
            field: "subthemeId",
            id: subTheme.id.toString(),
          };
          const cardCountResponse = await postRequest(
            `${baseURL}/lesson/counts`,
            reqData
          );
          if (cardCountResponse) {
            return { ...subTheme, cards: cardCountResponse.data.data.count };
          } else {
            return { ...subTheme, cards: 0 };
          }
        });
        const resolvedCardsCount = await Promise.all(handlecardsCount);
        setSubThemes(resolvedCardsCount);
        setParentThemeName(IntroData.name);
        setStartingLessonImage(IntroData.startingLessonImage);
        setIntro(IntroData.intro);
        setIntroTitle(IntroData.introTitle);
        setMyLessonsImage(IntroData.myLessonsImage);
        const fetchedPairs = await Promise.all(
          particles.map(async (item: any, index: number) => {
            const particleData = await fetchParticleById(item.type);
            return {
              id: item.id,
              dropdownValue: {
                id: particleData.id,
                name: particleData.name,
                pId: particleData.pId,
                particleType: particleData.particleType,
                particleValue: particleData.particleValue,
                particleType1: particleData.particleType1,
                particleValue1: particleData.particleValue1,
                children: particleData.children || [],
              },
              inputValue: item.value,
              order: item.order || index + 1,
            };
          })
        );
        setPairs(fetchedPairs);

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching sub-themes:", error);
      }
    };

    fetchSubThemes();
  }, [id]);

  const fetchParticleById = async (id: number) => {
    try {
      const response = await getRequest(
        `${baseURL}/particle/get-particleById/${id}`
      );
      return response?.data.data;
    } catch (error) {
      console.error("Error fetching particle by ID:", error);
      throw error;
    }
  };

  const handleNewSubThemes = () => {
    setThemeName("");
    setSubThemeError("");
    setNewThemes(true);
    setEditSubThemes(null);
  };

  const handleIntro = (value: string) => {
    setIntro(value);
  };

  const addNewPair = () => {
    const newOrder = pairs.length + 1;
    setPairs([
      ...pairs,
      {
        dropdownValue: null,
        inputValue: "",
        order: newOrder,
      },
    ]);
  };

  const handleInputChange = (index: number, value: string) => {
    const updatedPairs = [...pairs];
    updatedPairs[index].inputValue = value;
    const updatedErrors = { ...validationError };
    if (updatedErrors.pairs[index]) {
      updatedErrors.pairs[index].inputValue = "";
    } else {
      updatedErrors.pairs[index] = { inputValue: "" };
    }
    setPairs(updatedPairs);
    setValidationError(updatedErrors);
  };

  const handleDropdownChange = (index: number, item: DropdownValue) => {
    const updatedPairs = [...pairs];
    updatedPairs[index].dropdownValue = item;
    const updatedErrors = { ...validationError };
    if (updatedErrors.pairs[index]) {
      updatedErrors.pairs[index].dropdownValue = "";
    } else {
      updatedErrors.pairs[index] = { dropdownValue: "" };
    }
    setPairs(updatedPairs);
    setValidationError(updatedErrors);
  };

  const handlePublishClick = async () => {
    let hasError = false;

    const validationErrors = {
      title: "",
      startingLessonImage: !startingLessonImage ? "starting lesson image is required" : "",
      myLessonsImage: !myLessonsImage ? "my lessons image is required" : "",
      introTitle: !introTitle ? "Intro title is required" : "",
      intro: !intro ? "Intro content is required" : "",
    };


    setErrors(validationErrors);

    // If any validation error exists, stop the form submission
    if (Object.values(validationErrors).some((error) => error)) {
      return;
    }

    if (pairs.length === 0) {
      Toast("Please add at least one intro partículas.", "error");
      return;
    }

    const newErrorsColumn1: {
      [key: number]: { dropdownValue?: string; inputValue?: string };
    } = {};
    pairs.forEach((item, index) => {
      newErrorsColumn1[index] = {};
      if (!item.dropdownValue) {
        newErrorsColumn1[index].dropdownValue = "required";
        hasError = true;
      }
      if (!item.inputValue) {
        newErrorsColumn1[index].inputValue = "required";
        hasError = true;
      }
    });

    setValidationError((prevErrors) => ({
      ...prevErrors,
      pairs: newErrorsColumn1,
    }));

    if (hasError) {
      return;
    }
    setIsLoading(true);
    const payload = {
      themeId: themeId || id,
      introTitle,
      intro,
      startingLessonImage: startingLessonImage || null,
      myLessonsImage: myLessonsImage || null,
      particles: pairs.map((pair) => ({
        id: pair.id || null,
        type: pair.dropdownValue?.id || null,
        value: pair.inputValue || "",
        order: pair.order || 0,
      })),
    };

    const response = await postRequest(
      `${baseURL}/lesson/create-update-themeintro`,
      payload
    );
    if (response?.data.success) {
      const themesData = response?.data.data.theme;
      const sortedParticles = response?.data.data.sortedParticles;

      setIntro(themesData.intro);
      setIntroTitle(themesData.introTitle);
      setStartingLessonImage(themesData.startingLessonImage);
      setMyLessonsImage(themesData.myLessonsImage);
      const fetchedPairs = await Promise.all(
        sortedParticles.map(async (item: any, index: number) => {
          const particleData = await fetchParticleById(item.type);
          return {
            id: item.id,
            dropdownValue: {
              id: particleData.id,
              name: particleData.name,
              pId: particleData.pId,
              particleType: particleData.particleType,
              particleValue: particleData.particleValue,
              particleType1: particleData.particleType1,
              particleValue1: particleData.particleValue1,
              children: particleData.children || [],
            },
            inputValue: item.value,
            order: item.order || index + 1,
          };
        })
      );
      setPairs(fetchedPairs);
      setIsLoading(false);
      Toast(response?.data.statusMessage, "success");
    }
    setIsLoading(false);
  };

  const handleSaveSubTheme = async () => {
    if (!themeName) {
      setSubThemeError("Please enter sub-theme name");
      return;
    }
    const activeThemeId = themeId || id;

    if (editSubThemes) {
      await handleUpdateSubTheme(editSubThemes.id, themeName);
    } else {
      await handleCreateSubTheme(activeThemeId, themeName);
    }
  };

  const handleCreateSubTheme = async (
    themeId: number,
    subThemeName: string
  ) => {
    try {
      const nextOrder = subthemes.length + 1;

      const reqData = {
        themeId,
        name: subThemeName,
        order: nextOrder,
      };
      setIsLoading(true);
      const response = await postRequest(
        `${baseURL}/lesson/create-subtheme`,
        reqData
      );

      const newTheme: SubTheme = {
        id: response?.data.data.id, // Generate a unique ID (you might want to use a more robust approach)
        name: response?.data.data.name,
        cards: 0, // Initialize cards count
        order: response?.data.data.order,
      };
      setSubThemes([...subthemes, newTheme]);
      setThemeName("");
      setNewThemes(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setSubThemeError("Error creating Sub-Theme");
    }
  };

  const handleUpdateSubTheme = async (
    subthemeId: number,
    subThemeName: string
  ) => {
    try {
      const reqData = {
        name: subThemeName,
      };
      setIsLoading(true);
      const response = await postRequest(
        `${baseURL}/lesson/update-subthemes/${subthemeId}`,
        reqData
      );
      setIsLoading(false);

      const updatedSubThemes = subthemes.map((subTheme) =>
        subTheme.id === subthemeId
          ? { ...subTheme, name: subThemeName }
          : subTheme
      );
      setSubThemes(updatedSubThemes);
      setThemeName("");
      setNewThemes(false);
      setEditSubThemes(null);
      Toast(response?.data.statusMessage, "success");
    } catch (error) {
      setIsLoading(false);
      setSubThemeError("Error updateing Sub-Theme");
    }
  };

  const handleEditSubTheme = (subTheme: SubTheme) => {
    setThemeName(subTheme.name);
    setEditSubThemes(subTheme);
    setNewThemes(true);
  };

  const handleAddFlashcard = (id: number, name: string) => {
    navigate(`/lessons/theme/subthemes/${id}`, {
      state: { subThemeId: id, subtheme: name },
    });
  };

  const handleBackClick = async () => {
    try {
      const response = await getRequest(
        `${baseURL}/lesson/get-fetch-themes/${id}`
      );

      const lessonId = response?.data.data[0].lessonId;
      navigate(`/lessons/edit-lessons/${lessonId}`);
    } catch (error) {
      console.error("Error fetching themeId:", error);
    }
  };

  const handleConfirmDeleteSubTheme = async (id: number) => {
    try {
      setLoading(true);
      const response = await deleteRequest(
        `${baseURL}/lesson/delete-subthemes/${id}`
      );
      setLoading(false);
      const updatedSubThemes = subthemes.filter((theme) => theme.id !== id);
      setSubThemes(updatedSubThemes);
      setShowDeleteDialog(false);
      setSubThemeToDelete(null);
      Toast(response?.data.statusMessage, "success");
    } catch (error) {
      console.error("Error deleting flashcard:", error);
      setSubThemeError("Error deleting flashcard");
      setLoading(false);
      setShowDeleteDialog(false);
    }
  };

  const handleDeleteSubTheme = (id: number, name: string) => {
    setSubThemeToDelete(id);
    setDeleteDialogMessage(`Are you sure you want to delete this "${name}"?`);
    setOnConfirmDelete(() => () => handleConfirmDeleteSubTheme(id));
    setShowDeleteDialog(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false);
    setSubThemeToDelete(null);
  };

  const removePair = (index: number) => {
    const updatedPairs = [...pairs];
    updatedPairs.splice(index, 1);
    setPairs(updatedPairs);
  };

  const handleDeletePair = (index: number, id?: number) => {
    if (id !== undefined) {
      setPairToDelete({ index, id });
      setDeleteDialogMessage("Are you sure you want to delete this pair?");
      setOnConfirmDelete(() => () => handleConfirmDeletePair(index, id)); // Pass index and ID to confirm function
      setShowDeleteDialog(true);
    } else {
      removePair(index);
    }
  };

  const handleConfirmDeletePair = async (index: number, id?: number) => {
    if (id !== undefined) {
      try {
        const reqData = {
          modelName: "themesintro",
          id: id,
        };

        const response = await postRequest(
          `${baseURL}/lesson/delete-bretzel-content`,
          reqData
        );
        if (response?.data) {
          const updatedPairs = [...pairs];
          updatedPairs.splice(index, 1);
          setPairs(updatedPairs);
          Toast(response?.data.statusMessage, "success");
        } else {
          console.error("Error deleting pair:", response?.data);
        }
      } catch (error) {
        console.error("Error deleting pair:", error);
      }
    } else {
      const updatedPairs = [...pairs];
      updatedPairs.splice(index, 1);
      setPairs(updatedPairs);
    }
  };

  const handleChooseFileClick = (inputType: string) => {
    const inputRef =
      inputType === "cover"
        ? fileInputRef
        : inputType === "startingLesson"
          ? startingLessonInputRef
          : myLessonsInputRef;
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleStartingLessonImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setStartingLessonImageLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      const response = await multiPostRequest(
        `${baseURL}/file-upload`,
        formData
      );

      if (response?.data.success) {
        setStartingLessonImage(response?.data?.data[0].uri);
      }
      setStartingLessonImageLoading(false);
    }
  };

  const handleMyLessonsImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setMyLessonsImageLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      const response = await multiPostRequest(
        `${baseURL}/file-upload`,
        formData
      );

      if (response?.data.success) {
        setMyLessonsImage(response?.data?.data[0].uri);
      }
      setMyLessonsImageLoading(false);
    }
  };

  const handleDeleteImage = async (imageType: string) => {
    let fileUri = "";

    switch (imageType) {
      // case "cover":
      //   fileUri = coverImage;
      //   break;
      case "startingLesson":
        fileUri = startingLessonImage;
        break;
      case "myLessons":
        fileUri = myLessonsImage;
        break;
      default:
        return;
    }

    try {
      const response = await postRequest(`${baseURL}/remove-file`, {
        fileUri,
      });

      if (response?.data.success) {
        switch (imageType) {
          // case "cover":
          //   setCoverImage("");
          //   break;
          case "startingLesson":
            setStartingLessonImage("");
            break;
          // case "myLessons":
          //   setMyLessonsImage("");
          //   break;
          default:
            break;
        }
      } else {
        console.error("Failed to delete the image");
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  useEffect(() => {
    let timeLine = gsap.timeline();
    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 1,
      ease: "power2.out",
    });
  }, [isLoading]);

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="p-3 main-page">
            <div
              className="d-flex  align-items-center go-back"
              onClick={handleBackClick}
              style={{ width: "fit-content" }}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="me-2"
                style={{
                  backgroundColor: "#D9D9D9",
                  padding: "7px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  color: "#000",
                }}
              />
              <label
                style={{
                  color: "#FF9E00",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Back
              </label>
            </div>
            <Label
              fontSize="22px"
              alignItems="center"
              display="flex"
              justifyContent="center"
              color="#7542E4"
              fontWeight={700}
              className="row-container"
            >
              THEME NAME [{parentThemeName}]
            </Label>
            <VerticalSpace height={20} />

            <div className="row  p-3 d-flex main-container justify-content-between">
              <div className="col lesson-container left-container">
                <div
                  className="container p-4"
                  style={{
                    backgroundColor: "rgb(220 220 220 / 66%)",
                    borderRadius: "30px",
                  }}
                >
                  <div className="col-12">
                    <Label fontSize="20px" fontWeight={700}>
                      Intro Title
                    </Label>
                    <VerticalSpace height={10} />
                    <input
                      type="text"
                      placeholder="Intro title"
                      className="form-control"
                      id="introtitle"
                      value={introTitle}
                      onChange={(e) => setIntroTitle(e.target.value)}
                      style={{
                        borderRadius: "10px",
                        height: "40px",
                        fontSize: "14px",
                      }}
                    />
                    {errors.introTitle && (
                      <span style={{ color: "red", fontSize: "12px" }}>{errors.introTitle}</span>
                    )}
                  </div>
                  <div className="col-12">
                    <Label fontSize="20px" fontWeight={700}>
                      Intro
                    </Label>{" "}
                    <VerticalSpace height={10} />
                    <TextEditor
                      value={intro}
                      onChange={handleIntro}
                      height="auto"
                    />
                    {errors.intro && (
                      <span style={{ color: "red", fontSize: "12px" }}>{errors.intro}</span>
                    )}
                  </div>
                  <VerticalSpace height={10} />
                  <div className="col-12">
                    {!startingLessonImage && (
                      <div className="col-12">
                        <Label fontSize="20px" fontWeight={700}>
                          Starting Theme Image
                        </Label>
                        <VerticalSpace height={4} />
                        <div className="drop_box">
                          {startingLessonImageLoading ? (
                            <RowContainer
                              alignItem="center"
                              justifyContent="center"
                              padding="30px"
                            >
                              <Loader />
                            </RowContainer>
                          ) : (
                            <>
                              <header>
                                <h4 className="image-text">
                                  Upload a&nbsp;
                                  <span style={{ color: "#7542E4" }}>
                                    starting lesson
                                  </span>
                                  &nbsp;image for the lesson section
                                </h4>
                                <h4>jpg, png or svg</h4>
                              </header>

                              <input
                                type="file"
                                hidden
                                ref={startingLessonInputRef}
                                className={`form-control ${errors.startingLessonImage
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                accept="image/*"
                                onChange={handleStartingLessonImageChange}
                              />
                              <VerticalSpace height={10} />
                              <button
                                type="button"
                                className="user"
                                style={{
                                  color: "#100F15",
                                  background: "none",
                                  border: "1px solid #100F15",
                                  borderRadius: "10px",
                                  padding: "14px 30px",
                                  fontSize: "14px",
                                  width: "auto",
                                }}
                                onClick={() =>
                                  handleChooseFileClick("startingLesson")
                                }
                              >
                                Choose file
                              </button>
                              {errors.startingLessonImage && (
                                <div className="invalid-feedback  d-flex justify-content-center">
                                  {errors.startingLessonImage}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    {startingLessonImage && (
                      <div className="col-12">
                        <Label fontSize="20px" fontWeight={700}>
                          Starting Lesson Image
                        </Label>
                        <VerticalSpace height={4} />
                        <div
                          className="drop_box"
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          {startingLessonImageLoading ? (
                            <RowContainer
                              alignItem="center"
                              justifyContent="center"
                              padding="30px"
                            >
                              <Loader />
                            </RowContainer>
                          ) : (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                {typeof startingLessonImage === "string" ? (
                                  <>
                                    <img
                                      src={startingLessonImage}
                                      alt="starting-lessons-image"
                                      style={{
                                        maxWidth: "100px",
                                        maxHeight: "100px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleChooseFileClick(
                                          "startingLesson"
                                        )
                                      }
                                    />
                                    <input
                                      type="file"
                                      hidden
                                      ref={startingLessonInputRef}
                                      accept="image/*"
                                      onChange={
                                        handleStartingLessonImageChange
                                      }
                                    />
                                  </>
                                ) : (
                                  <img
                                    src={URL.createObjectURL(
                                      startingLessonImage
                                    )}
                                    alt="starting-lessons-image"
                                    style={{
                                      maxWidth: "100px",
                                      maxHeight: "100px",
                                    }}
                                  />
                                )}
                                {/* <p
                                style={{
                                  color: "#000000",
                                  marginTop: "0%",
                                  marginBottom: "0%",
                                }}
                              >
                                {startingLessonImage.name}
                              </p>{" "} */}
                              </div>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleDeleteImage("startingLesson");
                                }}
                              >
                                <img
                                  src={deleteIcon}
                                  className="user-scale"
                                  alt="Delete"
                                  style={{
                                    WebkitUserSelect: "none",
                                    userSelect: "none",
                                  }}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    <VerticalSpace height={10} />
                    {!myLessonsImage && (
                      <div className="col-12">
                        <Label fontSize="20px" fontWeight={700}>
                          My Lessons Image
                        </Label>
                        <VerticalSpace height={4} />
                        <div className="drop_box">
                          {myLessonsImageLoading ? (
                            <RowContainer
                              alignItem="center"
                              justifyContent="center"
                              padding="30px"
                            >
                              <Loader />
                            </RowContainer>
                          ) : (
                            <>
                              <header>
                                <h4 className="image-text">
                                  Upload a&nbsp;
                                  <span style={{ color: "#7542E4" }}>
                                    my lessons
                                  </span>
                                  &nbsp;image for the lesson section
                                </h4>
                                <h4>jpg, png or svg</h4>
                              </header>

                              <input
                                type="file"
                                hidden
                                ref={myLessonsInputRef}
                                className={`form-control ${errors.myLessonsImage ? "is-invalid" : ""
                                  }`}
                                accept="image/*"
                                onChange={handleMyLessonsImageChange}
                              />
                              <VerticalSpace height={10} />
                              <button
                                type="button"
                                className="user"
                                style={{
                                  color: "#100F15",
                                  background: "none",
                                  border: "1px solid #100F15",
                                  borderRadius: "10px",
                                  padding: "14px 30px",
                                  fontSize: "14px",
                                  width: "auto",
                                }}
                                onClick={() =>
                                  handleChooseFileClick("myLessons")
                                }
                              >
                                Choose file
                              </button>
                              {errors.myLessonsImage && (
                                <div className="invalid-feedback  d-flex justify-content-center">
                                  {errors.myLessonsImage}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    {myLessonsImage && (
                      <div className="col-12">
                        <Label fontSize="20px" fontWeight={700}>
                          My Lessons Image
                        </Label>
                        <VerticalSpace height={4} />
                        <div
                          className="drop_box"
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          {myLessonsImageLoading ? (
                            <RowContainer
                              alignItem="center"
                              justifyContent="center"
                              padding="30px"
                            >
                              <Loader />
                            </RowContainer>
                          ) : (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                {typeof myLessonsImage === "string" ? (
                                  <>
                                    <img
                                      src={myLessonsImage}
                                      alt="my-lessoms-image"
                                      style={{
                                        maxWidth: "100px",
                                        maxHeight: "100px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleChooseFileClick("myLessons")
                                      }
                                    />
                                    <input
                                      type="file"
                                      hidden
                                      ref={myLessonsInputRef}
                                      accept="image/*"
                                      onChange={handleMyLessonsImageChange}
                                    />
                                  </>
                                ) : (
                                  <img
                                    src={URL.createObjectURL(myLessonsImage)}
                                    alt="my-lessoms-image"
                                    style={{
                                      maxWidth: "100px",
                                      maxHeight: "100px",
                                    }}
                                  />
                                )}
                                {/* <p
                                style={{
                                  color: "#000000",
                                  marginTop: "0%",
                                  marginBottom: "0%",
                                }}
                              >
                                {myLessonsImage.name}
                              </p>{" "} */}
                              </div>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleDeleteImage("myLessons");
                                }}
                              >
                                <img
                                  src={deleteIcon}
                                  className="user-scale"
                                  alt="Delete"
                                  style={{
                                    WebkitUserSelect: "none",
                                    userSelect: "none",
                                  }}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <VerticalSpace height={20} />
                  <div className="col-12">
                    <Label fontSize="20px" fontWeight={700}>
                      Partículas
                    </Label>
                    <VerticalSpace height={10} />
                    <div
                      className="col d-flex flex-column align-itmes-center particle-example"
                      style={{ padding: "0px 14px 0px 0px", gap: "16px" }}
                    >
                      {pairs.map((pair, index) => (
                        <div
                          key={index}
                          className="row particle-row"
                          style={{
                            flexWrap: "unset",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="col particle-dropdown"
                            style={{ flex: "1", minWidth: "0" }}
                          >
                            <NestedMenu
                              data={
                                pair.dropdownValue ? [pair.dropdownValue!] : []
                              }
                              onSelectItem={(value) =>
                                handleDropdownChange(
                                  index,
                                  value as DropdownValue
                                )
                              }
                              borderColor={
                                validationError.pairs[index]?.dropdownValue
                                  ? "#dc3545"
                                  : "#D9D9D9"
                              }
                            />
                            <div
                              className="p-0 delete-icon"
                              style={{
                                cursor: "pointer",
                                width: "fit-content",
                              }}
                              onClick={() => handleDeletePair(index, pair.id)}
                            >
                              <img
                                src={deleteIcon}
                                className="user-scale"
                                style={{
                                  WebkitUserSelect: "none",
                                  userSelect: "none",
                                }}
                              />
                            </div>
                          </div>
                          <div className="col particle-input">
                            <input
                              type="text"
                              value={pair.inputValue || ""}
                              onChange={(e) =>
                                handleInputChange(index, e.target.value)
                              }
                              className={`form-control ${validationError.pairs[index]?.inputValue
                                ? "is-invalid"
                                : ""
                                }`}
                              placeholder=""
                              style={{
                                borderRadius: "10px",
                                height: "40px",
                                fontSize: "14px",
                              }}
                            />
                          </div>
                          <div
                            className="p-0 delete-button"
                            style={{
                              cursor: "pointer",
                              width: "fit-content",
                            }}
                            onClick={() => handleDeletePair(index, pair.id)}
                          >
                            <img src={deleteIcon} className="user-scale" />
                          </div>
                        </div>
                      ))}
                    </div>
                    <VerticalSpace height={16} />

                    <button
                      type="button"
                      className="btn btn-outline-secondary me-2 text-center user"
                      style={{
                        color: "#100F15",
                        background: "none",
                        border: "1px solid #100F15",
                        borderRadius: "10px",
                        padding: "14px 40px",
                        fontSize: "14px",
                        width: "auto",
                      }}
                      onClick={addNewPair}
                    >
                      Add new
                    </button>
                  </div>

                </div>

              </div>
              <HorizontalSpace width={5} />
              <div className="col theme-container right-container">
                <div
                  className="container p-4"
                  style={{
                    backgroundColor: "rgb(220 220 220 / 66%)",
                    borderRadius: "30px",
                  }}
                >
                  <div className="col-12 theme-content">
                    <div className="d-flex justify-content-between">
                      <Label fontSize="20px" fontWeight={700}>
                        Content
                      </Label>
                    </div>

                    <div>
                      {subthemes.length === 0 ? (
                        <div className="p-5 d-flex justify-content-center align-items-center ">
                          <label
                            htmlFor="theme"
                            className="form-label"
                            style={{ fontWeight: "bold", color: "#7542E4" }}
                          >
                            There are no sub-themes yet in this theme.
                          </label>
                        </div>
                      ) : (
                        subthemes.map((subtheme) => (
                          <div
                            className="row d-flex justify-content-center align-items-center "
                            style={{ gap: "10px" }}
                            key={subtheme.id}
                          >
                            <div
                              className="drop_box theme-box col-10 user"
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                cursor: "pointer",
                              }}
                              onClick={() => handleEditSubTheme(subtheme)}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <Label
                                  fontSize="14px"
                                  color="#000"
                                  fontWeight={400}
                                >
                                  {subtheme.name}
                                </Label>
                              </div>

                              <div style={{ cursor: "pointer" }}>
                                <Label
                                  fontSize="16px"
                                  color="#000"
                                  fontWeight={400}
                                  cursor="pointer"
                                  padding="8px 13px 8px 13px"
                                  bgColor="#DAD7FE"
                                  justifyContent="center"
                                  display="flex"
                                  borderRadius="40px"
                                  className="user"
                                >
                                  {subtheme.cards} cards
                                </Label>
                              </div>
                            </div>
                            <div
                              className="p-0"
                              style={{
                                cursor: "pointer",
                                width: "fit-content",
                              }}
                              onClick={() =>
                                handleAddFlashcard(subtheme.id, subtheme.name)
                              }
                            >
                              <img
                                src={editIcon}
                                className="user-scale"
                                style={{
                                  WebkitUserSelect: "none",
                                  userSelect: "none",
                                }}
                              />
                            </div>
                            <div
                              className="p-0"
                              style={{
                                cursor: "pointer",
                                width: "fit-content",
                              }}
                              onClick={() =>
                                handleDeleteSubTheme(subtheme.id, subtheme.name)
                              }
                            >
                              <img
                                src={deleteIcon}
                                className="user-scale"
                                style={{
                                  WebkitUserSelect: "none",
                                  userSelect: "none",
                                }}
                              />
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                    <VerticalSpace height={12} />
                    <button
                      type="button"
                      className="user"
                      style={{
                        color: "#100F15",
                        background: "none",
                        border: "1px solid #100F15",
                        alignItems: "center",
                        borderRadius: "10px",
                        padding: "14px 40px",
                        width: "auto",
                      }}
                      onClick={handleNewSubThemes}
                    >
                      Add sub-theme
                    </button>
                  </div>
                </div>
              </div>
              <VerticalSpace height={200} />
              <div
                className="container row d-flex justify-content-center publish-button"
                style={{
                  position: "fixed",
                  height: "12%",
                  bottom: "0px",
                  zIndex: 1,
                  background:
                    "linear-gradient(178.35deg, rgba(255, 255, 255, 0) 1.4%, rgba(255, 255, 255, 0.69) 26.79%, #FFFFFF 50.22%)",
                }}
              >
                <div
                  className="d-flex justify-content-center"
                  style={{
                    position: "fixed",
                    zIndex: 1,
                    bottom: "34px",

                    backgroundColor: "#FFFFFF",
                  }}
                >
                  {" "}
                  <button
                    type="button"
                    className="btn btn-success d-flex justify-content-center user"
                    style={{
                      backgroundColor: "#FF9E00",
                      color: "#100F15",
                      borderRadius: "10px",
                      height: "fit-content",
                      padding: "10px 20%",
                      width: "400px",
                      fontSize: "14px",
                    }}
                    onClick={handlePublishClick}
                  >
                    Publish
                  </button>
                </div>
              </div>
            </div>
          </div>

          {addSubThemes && (
            <div
              className="modal"
              role="dialog"
              style={{
                display: "block",
                position: "fixed",
                zIndex: 1,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="modal-dialog">
                <div className="modal-content" style={{ borderRadius: "20px" }}>
                  <div className="modal-body p-3">
                    <h5 style={{ fontWeight: "bold" }}>Add a sub-theme</h5>
                    <VerticalSpace height={10} />
                    <input
                      type="text"
                      className="form-control"
                      id="inputthemes"
                      placeholder="New sub-theme name"
                      value={themeName}
                      onChange={(e) => {
                        setThemeName(e.target.value)
                        if(subThemeError && e.target.value){
                          setSubThemeError('');
                        }
                      }}
                    />
                    {subThemeError && (
                      <div className="error-message">{subThemeError}</div>
                    )}
                  </div>
                  <div className="modal-body d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn btn-secondary user"
                      onClick={() => setNewThemes(false)}
                      style={{
                        padding: "12px",
                        color: "#100F15",
                        background: "none",
                        border: "1px solid #100F15",
                        alignItems: "center",
                        borderRadius: "10px",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger user"
                      style={{
                        backgroundColor: "#FF9E00",
                        borderRadius: "10px",
                        color: "#100F15",
                      }}
                      onClick={handleSaveSubTheme}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {showDeleteDialog && (
        <DeleteConfirmation
          showDeletePopup={true}
          setShowDeletePopup={setShowDeleteDialog}
          message={deleteDialogMessage}
          deleteButtonLabel="Delete"
          onDelete={onConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}

      {loading && <DeleteLoader />}
      <VerticalSpace height={120} />
    </div>
  );
};

export default SubThemes;

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faCcVisa } from "@fortawesome/free-brands-svg-icons";
// import '../../assets/css/editMember.css'
import RowContainer from "../../Components/Common/RowContainer";
import Container from "../../Components/Common/Container";
import Label from "../../Components/Common/Label";
import ColumnContainer from "../../Components/Common/ColumnContainer";
import MembershipActivity from "../../Components/MembersActivity";
import VerticalSpace from "../../Components/VerticalSpace";
import MembershipLessons from "../../Components/LessonsRedeemed";
import HorizontalSpace from "../../Components/HorizontalSpace";
import Switch from "../../Components/Common/Switch";
import { useNavigate, useParams } from "react-router-dom";
import DeleteConfirmation from "../../Components/Common/DeleteConfirmation";
import styled from "styled-components";
import "../../stylesheets/theme.css";
import CustomDropdown from "../Common/CustomDropdown";
import "../../stylesheets/common.css";
import gsap from 'gsap';
import { baseURL, getRequest } from "../../api/apiClient";
import formatDate from "../DateFormat";
import Loader from "../../utils/Loading";
import DatePicker from "react-datepicker";
import Toast from "../Common/Toast";
import StatusChangeConfirmation from "../Common/StatusUpdateConfirmation";

interface Activity {
  id: number;
  title: string;
  progress: string;
  durationInDays: string;
  createdAt: string;
  type: boolean;
  level: string;
}

interface lessons {
  id: number;
  type: string;
  points: string;
  createdAt: string;
  action: boolean;
}

interface Members {
  id?: number;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  memberSince?: string;
  points?: number;
  level?: number;
  redeemPoints?: number;
  active?: boolean;
}

const InnerRowContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 720px;
`;

const ScrollableRowContainer = styled(RowContainer)`
  @media (max-width: 1150px) {
    width: 100%;
    overflow-x: auto;
  }
`;

const StyledInput = styled.input`
  border-radius: 10px;
  border: none;
  padding: 10px;
  height: 40px;
  font-size: 14px;
  width: 100%;
`;

const EditMemberShipUser = () => {
  const { id } = useParams();
  const [activity, setActivity] = useState<Activity[]>([]);
  const [lessons, setLessons] = useState<lessons[]>([]);
  const [members, setMembers] = useState <Members>();
  const [memberSince, setMemberSince] = useState<Date | null>(new Date());
  const [showGeneralForm, setShowGeneralForm] = useState(true);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [selectedButton, setSelectedButton] = useState("General");
  const [showStatusPopup, setShowStatusPopup] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(()=>{
    fetchUserDetails();
  },[id])

  const fetchUserDetails = async () => {
      setIsLoading(true);
      if (id) {
        const response = await getRequest(`${baseURL}/users/${id}`);
        if (response?.data?.success) {
          const userDataFromApi = response.data.data.user;
          const userActivity = response?.data?.data?.userActivity;
          const userLessonsRedeemed = response?.data?.data?.userLessonsRedeemed;
          userDataFromApi.formattedCreatedAt = new Date(
            userDataFromApi.memberSince
          ).toLocaleDateString("en-GB");
          setMembers(userDataFromApi);
          setIsSwitchOn(userDataFromApi.active);
          setActivity(userActivity);
          setLessons(userLessonsRedeemed);
        }
        setIsLoading(false);
      } 
      setIsLoading(false);
  };
  

  // const handleDeleteConfirm = () => {
  //   // Perform delete action here
  //   // Once delete action is performed, close the modal
  //   setShowDeletePopup(false);
  // };
   
  const dropdownItems: string[] = [
    "3 months free",
    "6 months free",
    "1 Year free",
  ];

   const handleSelect = (item: string) => {
     setSelectedItem(item);
   };

  const toggleSwitch = () => {
     setShowStatusPopup(true);
  };

  const handleUpdateStatus = async () => {
    const response = await getRequest(`${baseURL}/users/change-status/${id}`);
    if (response?.status === 200) {
      Toast(response.data.statusMessage, "success");
      navigate("/members");
    }
  };

  const handleCancelStatusChange = () => {
    setShowStatusPopup(false);
  };

  // const handleDeleteCancel = () => {
  //   // Cancel the delete action and close the modal
  //   setShowDeletePopup(false);
  // };
  

  // const handleDeleteButtonClick = () => {
  //   // Show the delete confirmation modal
  //   setShowDeletePopup(true);
  // };

  const handleButtonClick = (buttonName: string) => {
    if (buttonName === "General") {
      setShowGeneralForm(true);
      setShowPaymentForm(false);
    } else {
      setShowGeneralForm(false);
      setShowPaymentForm(true);
    }
    setSelectedButton(buttonName);
  };

  const handleBackClick = async () => {
    navigate(`/members`);
  };

   const splitFullName = (fullName: string) => {
     const names = fullName.split(" ");
     const firstName = names[0];
     const lastName = names.length > 1 ? names.slice(1).join(" ") : "";
     return { firstName, lastName };
   };

  useEffect(() => {
    let timeLine = gsap.timeline();

    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 0.3,
      ease: "power2.out",
      stagger: 0.3
    });

    timeLine.from(".user-data", {
      opacity: 0,
      y: 40,
      duration: 0.3,
      ease: "power2.out",
    });

    timeLine.from(".left-container", {
      opacity: 0,
      x: -40,
      duration: 0.3,
      ease: "power2.out",
      stagger: 0.2
    });


    timeLine.from(".right-container", {
      opacity: 0,
      x: 40,
      duration: 0.3,
      ease: "power2.out",
      stagger: 0.2
    });


  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div
            className="container-fluid"
            // style={{
            //   backgroundColor: "#F8F8F8",
            // }}
          >
            <div className="p-3 main-page">
              <div
                className="d-flex  align-items-center go-back"
                onClick={handleBackClick}
                style={{ width: "fit-content" }}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="me-2"
                  style={{
                    backgroundColor: "#D9D9D9",
                    padding: "7px",
                    borderRadius: "8px",
                    cursor: "pointer",
                    color: "#000",
                  }}
                />
                <label
                  style={{
                    color: "#FF9E00",
                    fontSize: "16px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  Back
                </label>
              </div>
              <h4
                className="row-container"
                style={{
                  textAlign: "center",
                  margin: "auto",
                  color: "#7542E4",
                  fontWeight: "bold",
                }}
              >
                Memberships premium
              </h4>
              <VerticalSpace height={20} />
              <div className="row p-3 profile-main">
                <RowContainer>
                  <RowContainer></RowContainer>
                  <RowContainer></RowContainer>
                </RowContainer>
                <div className="col user-profile">
                  <div className="row align-items-center mb-3 left-container">
                    <div className="col-auto">
                      <h3 style={{ fontWeight: "bold" }}>Profile</h3>
                    </div>

                    <Switch isOn={isSwitchOn} onToggle={toggleSwitch} />
                  </div>
                  <div
                    className="container left-container"
                    style={{
                      backgroundColor: "rgb(220 220 220 / 66%)",
                      borderRadius: "30px",
                      padding: "20px",
                    }}
                  >
                    <div className="row p-2">
                      <button
                        type="button"
                        className={`btn ${
                          selectedButton === "General"
                            ? "btn-success"
                            : "btn-outline-dark"
                        } me-2 text-center user`}
                        style={{
                          color:
                            selectedButton === "General"
                              ? "#FFFFFF"
                              : "#100F15",
                          backgroundColor:
                            selectedButton === "General"
                              ? "#000000"
                              : "transparent",
                          border:
                            selectedButton === "General"
                              ? "none"
                              : "1px solid #100F15",
                          borderRadius: "46px",
                          padding: "8px 20px",
                          fontSize: "10px",
                          width: "auto",
                        }}
                        onClick={() => handleButtonClick("General")}
                      >
                        General
                      </button>
                      {/* <button
                        type="button"
                        className={`btn ${
                          selectedButton === "Payment"
                            ? "btn-success"
                            : "btn-outline-dark"
                        } me-2 text-center user`}
                        style={{
                          color:
                            selectedButton === "Payment"
                              ? "#FFFFFF"
                              : "#100F15",
                          backgroundColor:
                            selectedButton === "Payment"
                              ? "#000000"
                              : "transparent",
                          border:
                            selectedButton === "Payment"
                              ? "none"
                              : "1px solid #100F15",
                          borderRadius: "46px",
                          fontSize: "10px",
                          width: "auto",
                          padding: "8px 20px",
                        }}
                        onClick={() => handleButtonClick("Payment")}
                      >
                        Payment
                      </button> */}
                    </div>
                    <VerticalSpace height={14} />
                    {showGeneralForm && (
                      <div className="col">
                        <form className="row g-3">
                          <div className="col-md-6">
                            <Label
                              fontSize="14px"
                              padding="0px 0px 0px 8px"
                              fontWeight={400}
                            >
                              ID
                            </Label>
                            <VerticalSpace height={4} />
                            <input
                              type="text"
                              className="form-control"
                              id="id"
                              value={members?.id || "N/A"}
                              style={{
                                borderRadius: "10px",
                                height: "40px",
                                fontSize: "14px",
                              }}
                              readOnly
                            />
                          </div>
                          <div className="col-md-6">
                            <Label
                              fontSize="14px"
                              padding="0px 0px 0px 8px"
                              fontWeight={400}
                            >
                              Member since
                            </Label>
                            <VerticalSpace height={4} />
                            <DatePicker
                              selected={memberSince}
                              dateFormat="dd/MM/yyyy"
                              minDate={new Date()}
                              customInput={<StyledInput />}
                              readOnly
                            />
                          </div>
                          <div className="col-12">
                            <Label
                              fontSize="14px"
                              padding="0px 0px 0px 8px"
                              fontWeight={400}
                            >
                              Name
                            </Label>
                            <VerticalSpace height={4} />
                            <input
                              type="text"
                              className="form-control"
                              id="inputName"
                              value={
                                splitFullName(members?.fullName || "")
                                  .firstName || ""
                              }
                              placeholder="Name"
                              style={{
                                borderRadius: "10px",
                                height: "40px",
                                fontSize: "14px",
                              }}
                              readOnly
                            />
                          </div>
                          <div className="col-12">
                            <Label
                              fontSize="14px"
                              padding="0px 0px 0px 8px"
                              fontWeight={400}
                            >
                              Last Name
                            </Label>
                            <VerticalSpace height={4} />
                            <input
                              type="text"
                              className="form-control"
                              id="inputLastName"
                              value={
                                splitFullName(members?.fullName || "")
                                  .lastName || ""
                              }
                              placeholder="Last name"
                              style={{
                                borderRadius: "10px",
                                height: "40px",
                                fontSize: "14px",
                              }}
                              readOnly
                            />
                          </div>
                          <div className="col-12">
                            <Label
                              fontSize="14px"
                              padding="0px 0px 0px 8px"
                              fontWeight={400}
                            >
                              Email
                            </Label>
                            <VerticalSpace height={4} />
                            <input
                              type="email"
                              className="form-control"
                              id="inputEmail"
                              value={members?.email}
                              placeholder="antonia@lexik.app"
                              style={{
                                borderRadius: "10px",
                                height: "40px",
                                fontSize: "14px",
                              }}
                              readOnly
                            />
                          </div>
                          <div className="col-md-6">
                            <Label
                              fontSize="14px"
                              padding="0px 0px 0px 8px"
                              fontWeight={400}
                            >
                              Total Points
                            </Label>
                            <VerticalSpace height={4} />
                            <input
                              type="text"
                              className="form-control"
                              id="inputpoints"
                              value={members?.points || "0"}
                              style={{
                                borderRadius: "10px",
                                height: "40px",
                                fontSize: "14px",
                              }}
                              readOnly
                            />
                          </div>
                          <div className="col-md-6">
                            <Label
                              fontSize="14px"
                              padding="0px 0px 0px 8px"
                              fontWeight={400}
                            >
                              Points redeemed
                            </Label>
                            <VerticalSpace height={4} />
                            <input
                              type="text"
                              className="form-control"
                              id="inputredeemPoints"
                              value={members?.redeemPoints || "0"}
                              style={{
                                color: "#7542E4",
                                borderRadius: "10px",
                                height: "40px",
                                fontSize: "14px",
                              }}
                              readOnly
                            />
                          </div>
                          <div className="d-flex justify-content-between align-items-end">
                            <div>
                              <Label
                                fontSize="14px"
                                padding="0px 0px 0px 8px"
                                fontWeight={400}
                              >
                                Level
                              </Label>
                              <VerticalSpace height={4} />
                              <input
                                type="text"
                                className="form-control"
                                id="inputlevel"
                                value={members?.level || 0}
                                style={{
                                  borderRadius: "10px",
                                  height: "40px",
                                  fontSize: "14px",
                                }}
                                readOnly
                              />
                            </div>
                            {/* <button
                              type="button"
                              className="btn btn-outline-secondary me-2 text-center user"
                              style={{
                                color: "#100F15",
                                background: "none",
                                border: "1px solid #100F15",

                                borderRadius: "10px",
                                padding: "8px 20px",
                                fontSize: "14px",
                                width: "auto",
                              }}
                              onClick={handleDeleteButtonClick}
                            >
                              Delete user
                            </button> */}
                          </div>
                        </form>
                      </div>
                    )}

                    {/* {showPaymentForm && (
                      <div className="col">
                        <form className="row g-3">
                          <div>
                            <div className="col-md-4">
                              <Label
                                fontSize="14px"
                                padding="0px 0px 0px 8px"
                                fontWeight={400}
                              >
                                Membership
                              </Label>
                              <VerticalSpace height={4} />
                              <CustomDropdown
                                items={dropdownItems}
                                onSelect={handleSelect}
                                height="40px"
                                border="none"
                              />
                              {/* <select
                          id="inputpack"
                          className="form-select"
                          style={{
                            borderRadius: "10px",
                            height: "40px",
                            fontSize: "14px",
                          }}
                        >
                          <option selected>Yearly</option>
                          <option>Monthly</option>
                        </select> */}
                    {/* </div>
                          </div>
                          <div className="col-md-6">
                            <Label
                              fontSize="14px"
                              padding="0px 0px 0px 8px"
                              fontWeight={400}
                            >
                              From
                            </Label>
                            <VerticalSpace height={4} />
                            <input
                              type="date"
                              className="form-control"
                              id="inputDate"
                              style={{
                                borderRadius: "10px",
                                height: "40px",
                                fontSize: "14px",
                              }}
                            />
                          </div>
                          <div className="col-md-6">
                            <Label
                              fontSize="14px"
                              padding="0px 0px 0px 8px"
                              fontWeight={400}
                            >
                              Until
                            </Label>
                            <VerticalSpace height={4} />
                            <input
                              type="date"
                              className="form-control"
                              id="inputDate"
                              style={{
                                color: "#28A138",
                                borderRadius: "10px",
                                height: "40px",
                                fontSize: "14px",
                              }}
                            />
                          </div> */}
                    {/* <br /> */}
                    {/* <div className="col-12">
                            <Label
                              fontSize="14px"
                              padding="0px 0px 0px 8px"
                              fontWeight={400}
                            >
                              Billing Contact
                            </Label>
                            <VerticalSpace height={4} />
                            <input
                              type="email"
                              className="form-control"
                              id="inputEmail"
                              placeholder="antonia@lexik.app"
                              style={{
                                borderRadius: "10px",
                                height: "40px",
                                fontSize: "14px",
                              }}
                            />
                          </div> */}

                    {/* <section style={{backgroundColor: "#eee"}}> */}
                    {/* <div className="col-12">
                            <div className="row">
                              <div className="col">
                                <Label
                                  fontSize="14px"
                                  padding="0px 0px 0px 8px"
                                  fontWeight={400}
                                >
                                  Paymenet method
                                </Label>
                              </div>
                              <div className="col-md-auto ">
                                <Label
                                  fontSize="14px"
                                  padding="0px 8px 0px 0px"
                                  fontWeight={400}
                                  color="#7542e4"
                                >
                                  Update your plan payments details
                                </Label>
                              </div>
                              <VerticalSpace height={4} />
                            </div> */}
                    {/* <div className="container">
                              <div
                                className="row d-flex justify-content-center"
                                style={{
                                  padding: "40px 19px",
                                  backgroundColor: "#FFF",
                                  borderRadius: "10px",
                                }}
                              > */}
                    {/* <div className="card rounded-3"> */}
                    {/* <div className="card-body d-flex justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <div className="row">
                                      <Label
                                        fontSize="14px"
                                        // padding="0px 0px 0px 8px"
                                        fontWeight={400}
                                        color="#00A3FF"
                                      >
                                        Visa
                                      </Label>
                                      <Label
                                        fontSize="14px"
                                        padding="0px 0px 0px 18px"
                                        fontWeight={400}
                                      >
                                        **** **** **** 1234
                                      </Label>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center pb-1">
                                    <button
                                      type="button"
                                      data-mdb-button-init
                                      data-mdb-ripple-init
                                      className="btn btn-primary btn-lg"
                                      style={{
                                        backgroundColor: "#7542E4",
                                        fontSize: "14px",
                                        borderRadius: "10px",
                                        // borderRadius: "46px",
                                        // fontSize: "10px",
                                        width: "auto",
                                        padding: "6px 30px",
                                      }}
                                    >
                                      Change
                                    </button>
                                  </div>
                                </div> */}
                    {/* </div> */}
                    {/* </div>
                            </div>
                          </div> */}
                    {/* <div className="col-md-6">
                            <Label
                              fontSize="14px"
                              padding="0px 0px 0px 8px"
                              fontWeight={400}
                            >
                              Payment successfully completed
                            </Label>
                            <VerticalSpace height={4} />
                            <input
                              type="date"
                              className="form-control"
                              id="inputDate"
                              style={{
                                borderRadius: "10px",
                                height: "40px",
                                fontSize: "14px",
                              }}
                            />
                          </div> */}
                    {/* <div className="col-md-6">
                            <Label
                              fontSize="14px"
                              padding="0px 0px 0px 8px"
                              fontWeight={400}
                            >
                              Payment amount
                            </Label>
                            <VerticalSpace height={4} />
                            <input
                              type="text"
                              className="form-control"
                              id="inputpayment"
                              style={{
                                color: "#7542E4",
                                borderRadius: "10px",
                                height: "40px",
                                fontSize: "14px",
                              }}
                            />
                          </div> */}
                    {/* </form>
                      </div> */}
                    {/* )} */}
                  </div>
                </div>
                {/* <HorizontalSpace width={3} /> */}
                <div className="col user-activity">
                  <h3
                    className="right-container"
                    style={{ fontWeight: "bold" }}
                  >
                    Activity
                  </h3>
                  <VerticalSpace height={2} />
                  <ScrollableRowContainer>
                    <InnerRowContainer>
                      <Container width="100%" className="right-container">
                        <RowContainer
                          alignItem="center"
                          padding="8px 12px 8px 12px"
                          gap="8px"
                        >
                          <RowContainer flex="6.4">
                            <Label
                              fontWeight={400}
                              fontSize="14px"
                              color="rgba(164, 163, 167, 1)"
                            >
                              Name
                            </Label>
                          </RowContainer>
                          <RowContainer flex="4">
                            <Label
                              fontWeight={400}
                              fontSize="14px"
                              color="rgba(164, 163, 167, 1)"
                            >
                              Progress
                            </Label>
                          </RowContainer>
                          <RowContainer flex="4">
                            <Label
                              fontWeight={400}
                              fontSize="14px"
                              color="rgba(164, 163, 167, 1)"
                            >
                              Duration
                            </Label>
                          </RowContainer>
                          <RowContainer flex="6">
                            <Label
                              fontWeight={400}
                              fontSize="14px"
                              color="rgba(164, 163, 167, 1)"
                            >
                              Start day
                            </Label>
                          </RowContainer>
                          <RowContainer flex="4">
                            <Label
                              fontWeight={400}
                              fontSize="14px"
                              color="rgba(164, 163, 167, 1)"
                            >
                              Type
                            </Label>
                          </RowContainer>
                          <RowContainer flex="2">
                            <Label
                              fontWeight={400}
                              fontSize="14px"
                              color="rgba(164, 163, 167, 1)"
                            >
                              Level
                            </Label>
                          </RowContainer>
                        </RowContainer>
                        {activity && activity.length > 0 ? (
                          <ColumnContainer>
                            {activity.map((activity) => (
                              <MembershipActivity
                                key={activity.id}
                                id={activity.id}
                                name={activity.title}
                                progress={`${activity.progress}%`}
                                duration={activity.durationInDays}
                                startdate={formatDate(activity.createdAt)}
                                type={activity.type}
                                level={activity.level}
                              />
                            ))}
                          </ColumnContainer>
                        ) : (
                          <>
                            <VerticalSpace height={10} />
                            <Label
                              alignItems="center"
                              display="flex"
                              color="rgba(164, 163, 167, 1)"
                              justifyContent="center"
                            >
                              No records found.
                            </Label>
                          </>
                        )}
                      </Container>
                    </InnerRowContainer>
                  </ScrollableRowContainer>
                  <VerticalSpace height={34} />
                  <h3
                    className="right-container"
                    style={{ fontWeight: "bold" }}
                  >
                    Lessons redeemed
                  </h3>
                  <VerticalSpace height={2} />
                  <ScrollableRowContainer>
                    <InnerRowContainer>
                      <Container width="100%" className="right-container">
                        <RowContainer
                          alignItem="center"
                          padding="8px 12px 8px 12px"
                          gap="8px"
                        >
                          <RowContainer flex="8">
                            <Label
                              fontWeight={400}
                              fontSize="14px"
                              color="rgba(164, 163, 167, 1)"
                            >
                              Paquete
                            </Label>
                          </RowContainer>
                          <RowContainer flex="7">
                            <Label
                              fontWeight={400}
                              fontSize="14px"
                              color="rgba(164, 163, 167, 1)"
                            >
                              Points
                            </Label>
                          </RowContainer>
                          <RowContainer flex="8">
                            <Label
                              fontWeight={400}
                              fontSize="14px"
                              color="rgba(164, 163, 167, 1)"
                            >
                              Date
                            </Label>
                          </RowContainer>
                          <RowContainer flex="5">
                            <Label
                              fontWeight={400}
                              fontSize="14px"
                              color="rgba(164, 163, 167, 1)"
                            >
                              Operation
                            </Label>
                          </RowContainer>
                        </RowContainer>

                        {lessons && lessons.length > 0 ? (
                          <ColumnContainer>
                            {lessons.map((lessons: any) => (
                              <MembershipLessons
                                key={lessons.id}
                                id={lessons.id}
                                paquete={lessons.type}
                                level={lessons.points}
                                date={formatDate(lessons.createdAt)}
                                operation={lessons.action}
                              />
                            ))}
                          </ColumnContainer>
                        ) : (
                          <>
                            <VerticalSpace height={10} />
                            <Label
                              alignItems="center"
                              display="flex"
                              color="rgba(164, 163, 167, 1)"
                              justifyContent="center"
                            >
                              No records found.
                            </Label>
                          </>
                        )}
                      </Container>
                    </InnerRowContainer>
                  </ScrollableRowContainer>
                </div>
              </div>
              <VerticalSpace height={100} />
            </div>
            {showStatusPopup && (
              <StatusChangeConfirmation
                showStatusPopup={showStatusPopup}
                setShowStatusPopup={setShowStatusPopup}
                message={`Are you sure you want to ${
                  isSwitchOn ? "deactivate" : "activate"
                } this user?`}
                statusButtonLabel={isSwitchOn ? "Deactivate" : "Activate"}
                onStatus={handleUpdateStatus}
                onCancel={handleCancelStatusChange}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default EditMemberShipUser;

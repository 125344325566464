const formatUsersCount = (users: number): string => {
  if (users >= 1000) {
    const roundedUsers = (users / 1000).toFixed(1);
    return users % 1000 === 0
      ? `${Math.floor(users / 1000)}k`
      : `${roundedUsers.replace(".", ",")}k`;
  }
  return users.toString();
};

export default formatUsersCount;

import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../utils/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import VerticalSpace from "../VerticalSpace";
import Label from "../Common/Label";
import { baseURL, getRequest, postRequest } from "../../api/apiClient";
import gsap from "gsap";
import "../../stylesheets/common.css";
import Toast from "../Common/Toast";

interface AppSupport {
  id: number;
  supportEmail: string;
  userDateDonwloadPolicyUrl: string;
  faqUrl: string;
  privacyPocilyUrl: string;
  stripeKey: string;
  updatedAt: string;
}

const CreateAppSupport = () => {
  const [appSupport, setAppSupport] = useState<AppSupport>();
  const [loading, setLoading] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAppSupportById();
  }, []);

  useEffect(() => {
    if (textareaRef.current && appSupport?.stripeKey) {
      // Auto resize textarea based on content
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [appSupport?.stripeKey]);

  const fetchAppSupportById = async () => {
    setLoading(true);
    const response = await getRequest(`${baseURL}/get-app-support`);
    if (response?.data?.success) {
      setAppSupport(response.data.data[0]);
    }
    setLoading(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>{
    const {name, value} = e.target;
      setAppSupport((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
  }

  const handleSubmit = async () => {
    setLoading(true);
    const response = await postRequest(
      `${baseURL}/create-update-appsupport`,    
      appSupport
    );
    if(response?.data?.success){
        setAppSupport(response?.data?.data);
        Toast(response.data.statusMessage, "success");
        setLoading(false);
    }
    setLoading(false);
  }; 

  const handleBackClick = () => {
    navigate("/app-supports");
  };

  useEffect(() => {
    let timeLine = gsap.timeline();
    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 1,
      ease: "power2.out",
    });
  }, [loading]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <div className="p-3 main-page">
            <div
              className="d-flex  align-items-center go-back"
              onClick={handleBackClick}
              style={{ width: "fit-content" }}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="me-2"
                style={{
                  backgroundColor: "#D9D9D9",
                  padding: "7px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  color: "#000",
                }}
              />
              <label
                style={{
                  color: "#FF9E00",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Back
              </label>
            </div>
            <h4
              className="row-container"
              style={{
                textAlign: "center",
                margin: "auto",
                color: "#7542E4",
                fontWeight: "bold",
              }}
            >
              App Support
            </h4>
            <VerticalSpace height={20} />
            <div className="row p-3 d-flex align-items-center justify-content-center">
              <div
                className="container col-10"
                style={{
                  backgroundColor: "rgb(220 220 220 / 66%)",
                  borderRadius: "30px",
                  padding: "20px",
                }}
              >
                <VerticalSpace height={24} />
                <div className="col">
                  <div className="row g-3">
                    <div className="col-md-6">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        Support email
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="text"
                        className="form-control"
                        name="supportEmail"
                        value={appSupport?.supportEmail || ""}
                        onChange={handleInputChange}
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        User Data Download Policy URL
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="text"
                        className="form-control"
                        name="userDateDonwloadPolicyUrl"
                        value={appSupport?.userDateDonwloadPolicyUrl || ""}
                        onChange={handleInputChange}
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                    <br />
                    <div className="col-md-6">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        FAQ URL
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="text"
                        className="form-control"
                        name="faqUrl"
                        value={appSupport?.faqUrl || ""}
                        onChange={handleInputChange}
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                    <br />
                    <div className="col-md-6">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        Privacy Policy URL
                      </Label>
                      <VerticalSpace height={4} />
                      <input
                        type="text"
                        className="form-control"
                        name="privacyPocilyUrl"
                        value={appSupport?.privacyPocilyUrl || ""}
                        onChange={handleInputChange}
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                    <br />
                    <div className="col-12">
                      <Label
                        fontSize="14px"
                        padding="0px 0px 0px 8px"
                        fontWeight={400}
                      >
                        Stripe Key
                      </Label>
                      <VerticalSpace height={4} />
                      <textarea
                        ref={textareaRef}
                        name="stripeKey"
                        className="form-control"
                        data-kt-autosize="true"
                        value={appSupport?.stripeKey || ""}
                        onChange={handleInputChange}
                        style={{
                          resize: "none",
                          overflow: "hidden",
                        }}
                      />
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <VerticalSpace height={100} />
            <div
              className="container row d-flex justify-content-center publish-button"
              style={{
                position: "fixed",
                zIndex: 1,
                height: "12%",
                maxWidth: "100%",
                bottom: "0px",
                background:
                  "linear-gradient(178.35deg, rgba(255, 255, 255, 0) 1.4%, rgba(255, 255, 255, 0.69) 26.79%, #FFFFFF 50.22%)",
              }}
            >
              <div
                className="d-flex justify-content-center"
                style={{
                  position: "fixed",
                  zIndex: 1,
                  bottom: "34px",
                }}
              >
                <button
                  type="button"
                  className="btn btn-success d-flex justify-content-center user"
                  style={{
                    backgroundColor: "#FF9E00",
                    color: "#100F15",
                    height: "fit-content",
                    borderRadius: "10px",
                    padding: "10px 20%",
                    width: "400px",
                    fontSize: "14px",
                  }}
                  onClick={handleSubmit}
                >
                  Publish
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CreateAppSupport;

import React from "react";
import Container from "../Common/Container";
import RowContainer from "../Common/RowContainer";
import Particles from "../Common/ParticleDesign";

interface ConjugationItem {
  id: number;
  oneListId: number;
  value1?: string;
  value2?: string;
  value3?: string;
  column1?: string;
  column2?: string;
  column3?: string;
}

interface ConjugationListData {
  id: number;
  flashCardContentComponentId?: number;
  column1?: string;
  column2?: string;
  column3?: string;
  isC1Visible: boolean;
  isC2Visible: boolean;
  isC3Visible: boolean;
  lightTheme: string;
  conjugationList?: ConjugationItem[];
}

interface ConjugationListProps {
  data: ConjugationListData;
  onClick?: () => void;
}

const ConjugationList: React.FC<ConjugationListProps> = ({ data, onClick }) => {
  const {
    column1,
    column2,
    column3,
    isC1Visible,
    isC2Visible,
    isC3Visible,
    lightTheme,
    conjugationList,
  } = data;
  return (
    <Container
      bgColor={lightTheme === "Light" ? "#FFFFFF" : "#0C0C13"}
      color={lightTheme === "Light" ? "#121217" : "#FFFFFF"}
      borderRadius="24px"
      padding="12px 16px"
      width="100%"
      cursor="pointer"
      onClick={onClick}
    >
      <RowContainer flexDirection="column">
        {conjugationList &&
          conjugationList.map((item) => (
            <RowContainer
              key={item.id}
              alignItem="center"
              justifyContent="space-between"
            >
              {isC1Visible && (
                <Particles
                  key={item.id}
                  text={[item.value1 ?? "", ""]}
                  type={[item.column1 ?? "", ""]}
                />
              )}
              {isC2Visible && (
                <Particles
                  key={item.id}
                  text={[item.value2 ?? "", ""]}
                  type={[item?.column2 ?? "", ""]}
                />
              )}
              {isC3Visible && (
                <Particles
                  key={item.id}
                  text={[item.value3 ?? "", ""]}
                  type={[item?.column3 ?? "", ""]}
                />
              )}
            </RowContainer>
          ))}
      </RowContainer>
    </Container>
  );
};

export default ConjugationList;

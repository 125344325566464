import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { baseURL, getRequest, postRequest } from "../../../../api/apiClient";
import { useLocation, useNavigate } from "react-router-dom";
import VerticalSpace from "../../../VerticalSpace";
import RadioButton from "../../../Common/RadioButton";
import Label from "../../../Common/Label";
import HorizontalSpace from "../../../HorizontalSpace";
import RowContainer from "../../../Common/RowContainer";
import CustomCheckbox from "../../../Common/CustomCheckbox";
import deleteIcon from "../../../../assets/deleteIcon.svg";
import NestedMenu from "../../../ParticlesDropdown/NestedMenu";
import ColumnContainer from "../../../Common/ColumnContainer";
import Toast from "../../../Common/Toast";
import "../../../../stylesheets/theme.css";
import DeleteConfirmation from "../../../Common/DeleteConfirmation";
import Loader from "../../../../utils/Loading";
import gsap from "gsap";
import "../../../../stylesheets/common.css";

interface DropdownValue {
  id: number;
  name: string;
  pId: number;
  particleType: string;
  particleValue: string;
  particleType1: string;
  particleValue1: string;
  children: DropdownValue[];
}

interface Pair {
  id?: number;
  dropdownValue: DropdownValue | null;
  inputValue: string;
  order: number;
}

const ParticlesList = () => {
  const [titleColumn1, setTitleColumn1] = useState("");
  const [titleColumn2, setTitleColumn2] = useState("");
  const [isColumn1, setIsColumn1] = useState(true);
  const [isColumn2, setIsColumn2] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [pairs, setPairs] = useState<Pair[]>([]);
  const [pairs1, setPairs1] = useState<Pair[]>([]);
  const [selectedOption, setSelectedOption] = useState("Light");
  const [particleListId, setParticleListId] = useState<number | null>(null);
  const [deleteDialogMessage, setDeleteDialogMessage] = useState("");
  const [onConfirmDelete, setOnConfirmDelete] = useState<() => void>(() => {});
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [themeToDelete, setThemeToDelete] = useState<number | null>(null);
  const [pairToDelete, setPairToDelete] = useState<{
    index: number;
    id?: number;
  } | null>(null);
  //  const [validationError, setValidationError] = useState<string>("");
  const [errors, setErrors] = useState({
    titleColumn1: "",
    titleColumn2: "",
  });
  const [validationError, setValidationError] = useState<{
    pairs: {
      [key: number]: {
        dropdownValue?: string;
        inputValue?: string;
      };
    };
    pairs1: {
      [key: number]: {
        dropdownValue?: string;
        inputValue?: string;
      };
    };
  }>({
    pairs: {},
    pairs1: {},
  });

  const navigate = useNavigate();
  const location = useLocation();

  const { flashcardContentId } = location.state || {};

  const fetchParticleById = async (id: number) => {
    try {
      const response = await getRequest(
        `${baseURL}/particle/get-particleById/${id}`
      );
      return response?.data.data;
    } catch (error) {
      console.error("Error fetching particle by ID:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reqId = {
          flashCardContentComponentId: flashcardContentId,
        };
        setIsLoading(true);
        const response = await postRequest(
          `${baseURL}/particlelist/get-partical-list`,
          reqId
        );

        if (!response) {
          setIsLoading(false);
          return;
        }

        if (response?.data.data) {
          const data = response?.data.data;

          setParticleListId(data.particalDetails.id);
          setSelectedOption(data.particalDetails.lightTheme);
          setTitleColumn1(data.columnOne.details.title);
          setIsColumn1(data.columnOne.details.active);
          setTitleColumn2(data.columnTwo.details.title);
          setIsColumn2(data.columnTwo.details.active);

          const fetchedPairs = await Promise.all(
            data.columnOne.iteams.map(async (item: any, index: number) => {
              const particleData = await fetchParticleById(item.type);
              return {
                id: item.id,
                dropdownValue: {
                  id: particleData.id,
                  name: particleData.name,
                  pId: particleData.pId,
                  particleType: particleData.particleType,
                  particleValue: particleData.particleValue,
                  particleType1: particleData.particleType1,
                  particleValue1: particleData.particleValue1,
                  children: particleData.children || [],
                },
                inputValue: item.value,
                order: item.order || index + 1,
              };
            })
          );

          const fetchedPairs1 = await Promise.all(
            data.columnTwo.iteams.map(async (item: any, index: number) => {
              const particleData = await fetchParticleById(item.type);
              return {
                id: item.id,
                dropdownValue: {
                  id: particleData.id,
                  name: particleData.name,
                  pId: particleData.pId,
                  particleType: particleData.particleType,
                  particleValue: particleData.particleValue,
                  particleType1: particleData.particleType1,
                  particleValue1: particleData.particleValue1,
                  children: particleData.children || [],
                },
                inputValue: item.value,
                order: item.order || index + 1,
              };
            })
          );

          setPairs(fetchedPairs);
          setPairs1(fetchedPairs1);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
  };

  const handleCheckboxColumn1 = () => {
    setIsColumn1(!isColumn1);
  };

  const handleCheckboxColumn2 = () => {
    setIsColumn2(!isColumn2);
  };

  const addNewPair = () => {
    const newOrder = pairs.length + 1;
    setPairs([
      ...pairs,
      { dropdownValue: null, inputValue: "", order: newOrder },
    ]);
  };

  const addNewPair1 = () => {
    const newOrder = pairs1.length + 1;
    setPairs1([
      ...pairs1,
      { dropdownValue: null, inputValue: "", order: newOrder },
    ]);
  };

  const handleInputChange = (index: number, value: string) => {
    const updatedPairs = [...pairs];
    updatedPairs[index].inputValue = value;
    const updatedErrors = { ...validationError };
    if (updatedErrors.pairs[index]) {
      updatedErrors.pairs[index].inputValue = "";
    } else {
      updatedErrors.pairs[index] = { inputValue: "" };
    }
    setPairs(updatedPairs);
    setValidationError(updatedErrors);
  };

  const handleInputChange1 = (index: number, value: string) => {
    const updatedPairs = [...pairs1];
    updatedPairs[index].inputValue = value;
    const updatedErrors = { ...validationError };
    if (updatedErrors.pairs1[index]) {
      updatedErrors.pairs1[index].inputValue = "";
    } else {
      updatedErrors.pairs1[index] = { inputValue: "" };
    }

    setPairs1(updatedPairs);
    setValidationError(updatedErrors);
  };

  const removePair = async (index: number, id?: number) => {
    if (id !== undefined) {
      try {
        const reqData = {
          modelName: "item",
          id: id,
        };
        const response = await postRequest(
          `${baseURL}/lesson/delete-bretzel-content`,
          reqData
        );
        if (response?.data) {
          const updatedPairs = [...pairs];
          updatedPairs.splice(index, 1);
          setPairs(updatedPairs);
          Toast(response?.data.statusMessage, "success");
        } else {
          console.error("Error deleting pair:", response?.data);
        }
      } catch (error) {
        console.error("Error deleting pair:", error);
      }
    } else {
      const updatedPairs = [...pairs];
      updatedPairs.splice(index, 1);
      setPairs(updatedPairs);
    }
  };

  const removePair1 = async (index: number, id?: number) => {
    if (id !== undefined) {
      try {
        const reqData = {
          modelName: "item",
          id: id,
        };
        const response = await postRequest(
          `${baseURL}/lesson/delete-bretzel-content`,
          reqData
        );
        if (response?.data) {
          const updatedPairs = [...pairs1];
          updatedPairs.splice(index, 1);
          setPairs1(updatedPairs);
        }
        Toast(response?.data.statusMessage, "success");
      } catch (error) {
        console.error("Error deleting pair:", error);
      }
    } else {
      const updatedPairs = [...pairs1];
      updatedPairs.splice(index, 1);
      setPairs1(updatedPairs);
    }
  };

  const handleSelectItem = (index: number, item: DropdownValue) => {
    const updatedPairs = [...pairs];
    updatedPairs[index].dropdownValue = item;
    const updatedErrors = { ...validationError };
    if (updatedErrors.pairs[index]) {
      updatedErrors.pairs[index].dropdownValue = "";
    } else {
      updatedErrors.pairs[index] = { dropdownValue: "" };
    }
    setPairs(updatedPairs);
    setValidationError(updatedErrors);
  };

  const handleSelectItem1 = (index: number, item: DropdownValue) => {
    const updatedPairs = [...pairs1];
    updatedPairs[index].dropdownValue = item;
    const updatedErrors = { ...validationError };
    if (updatedErrors.pairs1[index]) {
      updatedErrors.pairs1[index].dropdownValue = "";
    } else {
      updatedErrors.pairs1[index] = { dropdownValue: "" };
    }
    setPairs1(updatedPairs);
    setValidationError(updatedErrors);
  };

  const handleSave = async () => {
    let hasError = false;

    // const validationErrors = {
    //   titleColumn1:
    //     isColumn1 && !titleColumn1 ? "Title for Column 1 is required." : "",
    //   titleColumn2:
    //     isColumn2 && !titleColumn2 ? "Title for Column 2 is required." : "",
    // };

    // setErrors(validationErrors);

    if (pairs.length === 0 && isColumn1) {
      Toast("Please add at least one pair.", "error");
      return;
    }

    if (pairs1.length === 0 && isColumn2) {
      Toast("Please add at least one pair.", "error");
      return;
    }

    const newErrorsColumn1: {
      [key: number]: { dropdownValue?: string; inputValue?: string };
    } = {};
    pairs.forEach((item, index) => {
      newErrorsColumn1[index] = {};
      if (!item.dropdownValue && isColumn1) {
        newErrorsColumn1[index].dropdownValue = "required";
        hasError = true;
      }
      if (!item.inputValue && isColumn1) {
        newErrorsColumn1[index].inputValue = "required";
        hasError = true;
      }
    });

    const newErrorsColumn2: {
      [key: number]: { dropdownValue?: string; inputValue?: string };
    } = {};
    pairs1.forEach((item, index) => {
      newErrorsColumn2[index] = {};
      if (!item.dropdownValue && isColumn2) {
        newErrorsColumn2[index].dropdownValue = "required";
        hasError = true;
      }
      if (!item.inputValue && isColumn2) {
        newErrorsColumn2[index].inputValue = "required";
        hasError = true;
      }
    });

    setValidationError((prevErrors) => ({
      ...prevErrors,
      pairs: isColumn1 ? newErrorsColumn1 : {},
      pairs1: isColumn2 ? newErrorsColumn2 : {},
    }));

    if (hasError) {
      return;
    }

    // if (Object.values(validationErrors).some((error) => error)) {
    //   return;
    // }

    try {
      const column1Items = pairs.map((pair) => ({
        id: pair.id,
        value: pair.inputValue,
        type: pair.dropdownValue?.id || "",
        order: pair.order,
      }));

      const column2Items = pairs1.map((pair) => ({
        id: pair.id,
        value: pair.inputValue,
        type: pair.dropdownValue?.id || "",
        order: pair.order,
      }));

      const requestData = {
        particleListId: particleListId,
        flashCardContentComponentId: flashcardContentId,
        lightTheme: selectedOption,

        columnOne: {
          column: 1,
          title: titleColumn1,
          items: column1Items,
          active: isColumn1,
        },
        columnTwo: {
          column: 2,
          title: titleColumn2,
          items: column2Items,
          active: isColumn2,
        },
      };

      setIsLoading(true);

      const response = await postRequest(
        `${baseURL}/particlelist/update-partical-list`,
        requestData
      );

       if (response?.data.data) {
         const data = response?.data.data;

         setParticleListId(data.particalDetails.id);
         setSelectedOption(data.particalDetails.lightTheme);
         setTitleColumn1(data.columnOne.details.title);
         setIsColumn1(data.columnOne.details.active);
         setTitleColumn2(data.columnTwo.details.title);
         setIsColumn2(data.columnTwo.details.active);

         const fetchedPairs = await Promise.all(
           data.columnOne.iteams.map(async (item: any, index: number) => {
             const particleData = await fetchParticleById(item.type);
             return {
               id: item.id,
               dropdownValue: {
                 id: particleData.id,
                 name: particleData.name,
                 pId: particleData.pId,
                 particleType: particleData.particleType,
                 particleValue: particleData.particleValue,
                 particleType1: particleData.particleType1,
                 particleValue1: particleData.particleValue1,
                 children: particleData.children || [],
               },
               inputValue: item.value,
               order: item.order || index + 1,
             };
           })
         );

         const fetchedPairs1 = await Promise.all(
           data.columnTwo.iteams.map(async (item: any, index: number) => {
             const particleData = await fetchParticleById(item.type);
             return {
               id: item.id,
               dropdownValue: {
                 id: particleData.id,
                 name: particleData.name,
                 pId: particleData.pId,
                 particleType: particleData.particleType,
                 particleValue: particleData.particleValue,
                 particleType1: particleData.particleType1,
                 particleValue1: particleData.particleValue1,
                 children: particleData.children || [],
               },
               inputValue: item.value,
               order: item.order || index + 1,
             };
           })
         );

         setPairs(fetchedPairs);
         setPairs1(fetchedPairs1);
         Toast(response?.data.statusMessage, "success");
         navigate(-1);
       }
       
      setIsLoading(false);
      
    } catch (error) {
      console.error("Error saving data:", error);
      setIsLoading(false);
    }
  };

  const handleDeletePair = (index: number, id?: number) => {
    if (id !== undefined) {
      setPairToDelete({ index, id });
      setDeleteDialogMessage("Are you sure you want to delete this items?");
      setOnConfirmDelete(() => () => removePair(index, id)); // Pass index and ID to confirm function
      setShowDeleteDialog(true);
    } else {
      removePair(index);
    }
  };

  const handleDeletePair1 = (index: number, id?: number) => {
    if (id !== undefined) {
      setPairToDelete({ index, id });
      setDeleteDialogMessage("Are you sure you want to delete this items?");
      setOnConfirmDelete(() => () => removePair1(index, id)); // Pass index and ID to confirm function
      setShowDeleteDialog(true);
    } else {
      removePair1(index);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false);
    setThemeToDelete(null);
    setPairToDelete(null);
  };

  const handleBackClick = async () => {
    try {
      const response = await getRequest(
        `${baseURL}/lesson/get-fetch-flashcard-components/${flashcardContentId}`
      );
      const flashCardId = response?.data.data[0].flashCardId;
      navigate(`/lessons/theme/subthemes/flashcard/${flashCardId}`);
    } catch (error) {
      console.error("Error fetching themeId:", error);
    }
  };

  useEffect(() => {
    let timeLine = gsap.timeline();
    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 0.3,
      ease: "power2.out",
    });

    timeLine.from(".user-data", {
      opacity: 0,
      y: 40,
      duration: 0.5,
      ease: "power2.out",
      stagger: 0.2,
    });

    timeLine.from(".left-container", {
      opacity: 0,
      x: -40,
      duration: 0.3,
      ease: "power2.out",
      stagger: 0.2,
    });

    timeLine.from(".right-container", {
      opacity: 0,
      x: 40,
      duration: 0.5,
      ease: "power2.out",
      stagger: 0.2,
    });
  }, [isLoading]);

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-3 main-page">
          <div
            className="d-flex  align-items-center go-back"
            onClick={handleBackClick}
            style={{ width: "fit-content" }}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="me-2"
              style={{
                backgroundColor: "#D9D9D9",
                padding: "7px",
                borderRadius: "8px",
                color: "#000",
                cursor: "pointer",
              }}
            />
            <label
              style={{
                color: "#FF9E00",
                fontSize: "16px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Back
            </label>
          </div>
          <h4
            style={{
              textAlign: "center",
              margin: "auto",
              color: "#7542E4",
              fontWeight: "bold",
            }}
          >
            Particles List
          </h4>
          <VerticalSpace height={30} />
          <div className="container" style={{ maxWidth: "1257px" }}>
            <div
              className="container p-4"
              style={{
                backgroundColor: "rgb(220 220 220 / 66%)",
                borderRadius: "30px",
              }}
            >
              <div className="col-12">
                <div className="d-flex justify-content-end">
                  <div className="d-flex" style={{ gap: "8px" }}>
                    <RadioButton
                      checked={selectedOption === "Light"}
                      onChange={() => handleOptionChange("Light")}
                    />
                    <Label fontSize="14px" fontWeight={400}>
                      light
                    </Label>
                    <HorizontalSpace width={20} />
                    <RadioButton
                      checked={selectedOption === "Dark"}
                      onChange={() => handleOptionChange("Dark")}
                    />
                    <Label fontSize="14px" fontWeight={400}>
                      dark
                    </Label>
                  </div>
                </div>
              </div>
              <VerticalSpace height={10} />
              <div
                className="col-10 d-flex particle-list-content"
                style={{ gap: "35px" }}
              >
                <div className="col-md-7 particle-list-one">
                  <RowContainer>
                    <CustomCheckbox
                      checked={isColumn1}
                      onChange={handleCheckboxColumn1}
                    />
                    <Label fontSize="20px" fontWeight={700}>
                      Column 1
                    </Label>
                  </RowContainer>
                  <VerticalSpace height={20} />
                  <Label fontSize="20px" fontWeight={700}>
                    Title column
                  </Label>
                  <VerticalSpace height={16} />
                  <input
                    type="text"
                    value={titleColumn1}
                    onChange={(e) => setTitleColumn1(e.target.value)}
                    className={`form-control ${
                      errors.titleColumn1 ? "is-invalid" : ""
                    }`}
                    placeholder=""
                    style={{
                      borderRadius: "10px",
                      height: "40px",
                      fontSize: "14px",
                    }}
                    disabled={!isColumn1}
                  />
                  {errors.titleColumn1 && isColumn1 && (
                    <div className="invalid-feedback">
                      {errors.titleColumn1}
                    </div>
                  )}
                  <VerticalSpace height={24} />
                  <Label fontSize="20px" fontWeight={700}>
                    Items
                  </Label>
                  <VerticalSpace height={24} />
                  <ColumnContainer gap="20px">
                    {pairs.map((pair, index) => (
                      <RowContainer
                        key={index}
                        alignItem="center"
                        gap="16px"
                        className="particles-list-row"
                      >
                        <div className="col particle-list-dropdown">
                          <NestedMenu
                            data={
                              pair.dropdownValue ? [pair.dropdownValue!] : []
                            }
                            onSelectItem={(item) =>
                              handleSelectItem(index, item as DropdownValue)
                            }
                            borderColor={
                              validationError.pairs[index]?.dropdownValue
                                ? "#dc3545"
                                : "#D9D9D9"
                            }
                            disabled={!isColumn1}
                          />
                          <div
                            className="p-0 delete-icon"
                            style={{ cursor: "pointer", width: "fit-content" }}
                            onClick={() => handleDeletePair(index, pair.id)}
                          >
                            <img
                              src={deleteIcon}
                              className="user-scale"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col particle-list-input">
                          <input
                            type="text"
                            value={pair.inputValue}
                            onChange={(e) =>
                              handleInputChange(index, e.target.value)
                            }
                            className={`form-control ${
                              validationError.pairs[index]?.inputValue
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder=""
                            style={{
                              borderRadius: "10px",
                              height: "40px",
                              fontSize: "14px",
                            }}
                            disabled={!isColumn1}
                          />
                        </div>
                        <div
                          className="p-0 delete-button"
                          style={{ cursor: "pointer", width: "fit-content" }}
                          onClick={() => handleDeletePair(index, pair.id)}
                        >
                          <img
                            src={deleteIcon}
                            className="user-scale"
                            style={{
                              WebkitUserSelect: "none",
                              userSelect: "none",
                            }}
                          />
                        </div>
                      </RowContainer>
                    ))}
                  </ColumnContainer>
                  <VerticalSpace height={20} />
                  <button
                    type="button"
                    className="user"
                    style={{
                      color: "#100F15",
                      background: "none",
                      border: "1px solid #100F15",
                      borderRadius: "10px",
                      padding: "14px 19px",
                      fontSize: "14px",
                      width: "auto",
                    }}
                    onClick={addNewPair}
                  >
                    Add new pair
                  </button>
                </div>
                <div className="col-md-7 particle-list-two">
                  <RowContainer>
                    <CustomCheckbox
                      checked={isColumn2}
                      onChange={handleCheckboxColumn2}
                    />
                    <Label fontSize="20px" fontWeight={700}>
                      Column 2
                    </Label>
                  </RowContainer>
                  <VerticalSpace height={20} />
                  <Label fontSize="20px" fontWeight={700}>
                    Title column
                  </Label>
                  <VerticalSpace height={16} />
                  <input
                    type="text"
                    value={titleColumn2}
                    onChange={(e) => setTitleColumn2(e.target.value)}
                    className={`form-control ${
                      errors.titleColumn2 ? "is-invalid" : ""
                    }`}
                    placeholder=""
                    style={{
                      borderRadius: "10px",
                      height: "40px",
                      fontSize: "14px",
                    }}
                    disabled={!isColumn2}
                  />
                  {errors.titleColumn2 && isColumn2 && (
                    <div className="invalid-feedback">
                      {errors.titleColumn2}
                    </div>
                  )}
                  <VerticalSpace height={24} />
                  <Label fontSize="20px" fontWeight={700}>
                    Items
                  </Label>
                  <VerticalSpace height={24} />
                  <ColumnContainer gap="20px">
                    {pairs1.map((pair, index) => (
                      <RowContainer
                        key={index}
                        alignItem="center"
                        gap="16px"
                        className="particles-list-row"
                      >
                        <div className="col particle-list-dropdown">
                          <NestedMenu
                            data={
                              pair.dropdownValue ? [pair.dropdownValue!] : []
                            }
                            onSelectItem={(item) =>
                              handleSelectItem1(index, item as DropdownValue)
                            }
                            borderColor={
                              validationError.pairs1[index]?.dropdownValue
                                ? "#dc3545"
                                : "#D9D9D9"
                            }
                            disabled={!isColumn2}
                          />
                          <div
                            className="p-0 delete-icon"
                            style={{ cursor: "pointer", width: "fit-content" }}
                            onClick={() => handleDeletePair1(index, pair.id)}
                          >
                            <img
                              src={deleteIcon}
                              className="user-scale"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col particle-list-input">
                          <input
                            type="text"
                            value={pair.inputValue}
                            onChange={(e) =>
                              handleInputChange1(index, e.target.value)
                            }
                            className={`form-control ${
                              validationError.pairs1[index]?.inputValue
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder=""
                            style={{
                              borderRadius: "10px",
                              height: "40px",
                              fontSize: "14px",
                            }}
                            disabled={!isColumn2}
                          />
                        </div>
                        <div
                          className="p-0 delete-button"
                          style={{ cursor: "pointer", width: "fit-content" }}
                          onClick={() => handleDeletePair1(index, pair.id)}
                        >
                          <img
                            src={deleteIcon}
                            className="user-scale"
                            style={{
                              WebkitUserSelect: "none",
                              userSelect: "none",
                            }}
                          />
                        </div>
                      </RowContainer>
                    ))}
                  </ColumnContainer>
                  <VerticalSpace height={20} />
                  <RowContainer>
                    <button
                      type="button"
                      className="user"
                      style={{
                        color: "#100F15",
                        background: "none",
                        border: "1px solid #100F15",
                        borderRadius: "10px",
                        padding: "14px 19px",
                        fontSize: "14px",
                        width: "auto",
                      }}
                      onClick={addNewPair1}
                    >
                      Add new pair
                    </button>
                  </RowContainer>
                </div>
              </div>
            </div>
          </div>
          <VerticalSpace height={250} />
          <div
            className="container row d-flex justify-content-center publish-button"
            style={{
              position: "fixed",
              zIndex: 1,
              maxWidth: "100%",
              height: "12%",
              bottom: "0px",
              background:
                "linear-gradient(178.35deg, rgba(255, 255, 255, 0) 1.4%, rgba(255, 255, 255, 0.69) 26.79%, #FFFFFF 50.22%)",
            }}
          >
            {" "}
            <div
              className="d-flex justify-content-center"
              style={{
                position: "fixed",
                zIndex: 1,
                bottom: "34px",
              }}
            >
              <button
                type="button"
                className="btn btn-success d-flex justify-content-center user"
                style={{
                  backgroundColor: "#FF9E00",
                  color: "#100F15",
                  height: "fit-content",
                  borderRadius: "10px",
                  padding: "10px 20%",
                  width: "400px",
                  fontSize: "14px",
                }}
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {showDeleteDialog && (
        <DeleteConfirmation
          showDeletePopup={true}
          setShowDeletePopup={setShowDeleteDialog}
          message={deleteDialogMessage}
          deleteButtonLabel="Delete"
          onDelete={onConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};
export default ParticlesList;

import React, { useState, useRef, useEffect } from "react";
import "../../assets/css/members.css";
import RowContainer from "../../Components/Common/RowContainer";
import Container from "../../Components/Common/Container";
import Label from "../../Components/Common/Label";
import ColumnContainer from "../../Components/Common/ColumnContainer";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CustomDropdown from "../../Components/Common/CustomDropdown";
import Question from "../../Components/Questions";
import formatDate from "../../Components/DateFormat";
import VerticalSpace from "../../Components/VerticalSpace";
import { toast, ToastContainer } from "react-toastify";
import DeleteConfirmation from "../../Components/Common/DeleteConfirmation";
import { baseURL, getRequest, postRequest } from "../../api/apiClient";
import PaginationComponent from "../../Components/Common/PaginationComponent";
import "../../stylesheets/indexpage.css";
import Toast from "../../Components/Common/Toast";
import Loader from "../../utils/Loading";
import gsap from 'gsap';
import '../../stylesheets/common.css'
import ShimmerEffect from "../../utils/ShimmerEffect";
import { useDebounce } from "../../hooks/useDebounce";

const ScrollableRowContainer = styled(RowContainer)`
  @media (max-width: 1150px) {
    width: 100%;
    overflow-x: auto;
  }
`;

const InnerRowContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 1100px; /* Ensures minimum width to trigger horizontal scrolling */
`;

interface Quesiton {
  id: number;
  question: string;
  questionLevel: string;
  updatedAt: string;
  active: boolean;
}

const Quesiton = () => {
  const [quetion, setquetion] = useState<Quesiton[]>([]); // State to store user data
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedQuetions, setSelectedQuestions] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filterLoading, setFilterLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState<number | null>(
    null
  );

  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(5);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [filters, setFilters] = useState<{
    level?: string;
    updatedAt?: number;
    active?: string;
  }>({});

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };
  const navigate = useNavigate();

  const dropdownItems: string[] = [
    "All",
    "Level",
    "Active",
    "InActive",
    "Latest update",
  ];

  const debouncedSearch = useDebounce(searchQuery, 500);

  const handleSelect = (item: string) => {
    setSelectedItem(item);
  };

  const fetchquetions = async (
    page: number,
    perPage: number,
    filters: { level?: string; updatedAt?: number; active?: string; search?: string }
  ) => {
    if (!filters) {
      setLoading(true);
    } else {
      setFilterLoading(true);
    }

    try {
      const url = `${baseURL}/question/get-questions`

      const body = {
        page,
        limit: perPage,
        ...filters,
      }

      const response = await postRequest(url, body);

      if (response?.data) {
        setquetion(response.data.data.questions);
        setCount(response.data.data.totalQuestionsCount);
        setPage(response.data.data.page - 1);
        setHasNextPage(response.data.data.hasNextPage);
        setHasPreviousPage(response.data.data.hasPreviousPage);
      }
      setLoading(false);
      setFilterLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching lessons:", error);
    }
  };

  useEffect(() => {
    const updatedFilters = {
      ...filters,
      search: debouncedSearch,
    };
    fetchquetions(page + 1, limit, updatedFilters);
  }, [debouncedSearch, page, limit, filters]);

  const handleSearchChange = (query: string)=>{
    setSearchQuery(query);
  }

  const handleApplyFilters = () => {
    switch (selectedItem) {
      case "Level":
        setFilters({ level: "A1,A2,B1" });
        break;
      case "Latest update":
        setFilters({ updatedAt: -1 });
        break;
      case "Active":
        setFilters({ active: "true" });
        break;
      case "InActive":
        setFilters({ active: "false" });
        break;
      default:
        setFilters({});
        break;
    }
  };

  const handleEditLessons = (id: number) => {
    navigate(`/questions/edit/${id}`);
  };

  const handleCreateLessons = () => {
    navigate("/questions/create");
  };

  const handleStatusChange = async (id: number) => {
      await getRequest(`${baseURL}/question/status/changed-status/${id}`);
  };

  const onCheckboxChange = (id: number) => {
    if (selectedQuetions.includes(id)) {
      setSelectedQuestions(
        selectedQuetions.filter((selectedId) => selectedId !== id)
      );
    } else {
      setSelectedQuestions([...selectedQuetions, id]);
    }
  };

  const deleteSelectedQuestions = () => {
    if (selectedQuetions.length > 0) {
      setShowDeleteDialog(true);
    } else {
      Toast("Please select at least one question to delete.", "error");
    }
  };

  const deleteQuetions = async (questionIds: number | number[]) => {
    try {
      const payload = {
        questionId: Array.isArray(questionIds) ? questionIds : questionIds,
      };
      const response = await postRequest(
        `${baseURL}/question/deleteById`,
        payload
      );

      if (response?.status === 200) {
        setquetion((prevData) =>
          prevData.filter((question) =>
            Array.isArray(questionIds)
              ? !questionIds.includes(question.id)
              : question.id !== questionIds
          )
        );
      } else {
        toast.error("Failed to delete question");
      }
    } catch (error) {
      console.error("Error deleting question:", error);
      toast.error("An error occurred while deleting the Questions");
    }
  };

  const handleDelete = async () => {
    if (selectedQuestionId !== null) {
      await deleteQuetions(selectedQuestionId);
    } else {
      await deleteQuetions(selectedQuetions);
    }
    // await fetchquetions(page + 1, limit, filters);
    setSelectedQuestions([]);
    setSelectedQuestionId(null);
    setShowDeleteDialog(false);
  };

  const toggleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  useEffect(() => {
    let timeLine = gsap.timeline();
    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 0.3,
      ease: "power2.out",
    });

    timeLine.from(".user-data", {
      opacity: 0,
      y: 40,
      duration: 0.5,
      ease: "power2.out",
      stagger: 0.2
    });


  }, [loading]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="container-fluid">
            <div className="p-3">
              {/* Heading */}
              <div className="row mb-3 ">
                <div className="col">
                  <h2 style={{ fontWeight: "bold" }}>Questionnaire Level</h2>
                </div>
              </div>

              {/* Filter Label */}
              <div className="row mb-3 ">
                <div className="col">
                  <label htmlFor="filter" className="form-label">
                    Filter
                  </label>
                </div>
              </div>

              {/* Buttons Row */}
              <div className="row mb-3 all-button-responsive">
                <div className="col-3 search-input-div">
                  <input
                    type="search"
                    className="form-control rounded"
                    placeholder="Search"
                    onChange={(e) => handleSearchChange(e.target.value)}
                    aria-label="Search"
                    aria-describedby="search-addon"
                    style={{
                      height: "100%",
                      boxShadow: "none",
                    }}
                  />
                </div>
                <div className="col d-flex responsive">
                  <CustomDropdown
                    items={dropdownItems}
                    onSelect={handleSelect}
                    selectedItem={selectedItem || ""}
                    height="50px"
                  />
                  <button
                    type="button"
                    className="btn btn-primary ms-3 user"
                    style={{
                      backgroundColor: "#100F15",
                      borderRadius: "10px",
                      padding: "14px 50px",
                      fontSize: "14px",
                      width: "auto",
                    }}
                    onClick={handleApplyFilters}
                  >
                    Apply
                  </button>
                </div>
                <div className="col d-flex justify-content-end responsive">
                  <button
                    type="button"
                    className="btn btn-outline-secondary me-2 text-center"
                    style={{
                      color: "#100F15",
                      background: "none",
                      border: "1px solid #100F15",
                      padding: "14px 40px",
                      fontSize: "14px",
                      width: "auto",
                      borderRadius: "10px",
                    }}
                    onClick={deleteSelectedQuestions}
                  >
                    Delete all
                  </button>
                  <button
                    type="button"
                    className="btn btn-success user"
                    style={{
                      backgroundColor: "#FF9E00",
                      borderRadius: "10px",
                      color: "#100F15",
                      padding: "14px 40px",
                      fontSize: "14px",
                      width: "auto",
                    }}
                    onClick={handleCreateLessons}
                  >
                    Add New
                  </button>
                </div>
              </div>

              <ScrollableRowContainer>
                <InnerRowContainer>
                  <Container width="100%">
                    <RowContainer
                      alignItem="center"
                      padding="8px 12px 8px 12px"
                      gap="8px"
                      className="row-container"
                    >
                      <RowContainer flex="1.4">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        ></Label>
                      </RowContainer>
                      <RowContainer flex="21">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Name
                        </Label>
                      </RowContainer>

                      <RowContainer flex="4.6">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Level
                        </Label>
                      </RowContainer>
                      <RowContainer flex="6.3">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Latest updated
                        </Label>
                      </RowContainer>
                      <RowContainer flex="3.9">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Action
                        </Label>
                      </RowContainer>
                    </RowContainer>
                    {filterLoading ? (
                      <>
                        <RowContainer
                          bgColor="#ffffff"
                          borderRadius="8px"
                          padding="10px 0px"
                        >
                          {Array.from({ length: 6 }, (_, index) => {
                            return (
                              <RowContainer
                                key={index}
                                width={
                                  index === 0 || index === 5 ? "10%" : "20%"
                                }
                                justifyContent="center"
                              >
                                <ShimmerEffect />
                              </RowContainer>
                            );
                          })}
                        </RowContainer>
                      </>
                    ) : (
                      <>
                        {quetion && quetion.length > 0 ? (
                          <ColumnContainer>
                            {quetion.map((question) => (
                              <Question
                                key={question.id}
                                id={question.id}
                                question={question.question}
                                level={question.questionLevel}
                                latestdate={formatDate(question.updatedAt)}
                                onEdit={handleEditLessons}
                                onDelete={() => {
                                  setSelectedQuestionId(question.id);
                                  toggleDeleteDialog();
                                }}
                                isChecked={selectedQuetions.includes(
                                  question.id
                                )}
                                onCheckboxChange={onCheckboxChange}
                                status={question.active}
                                onStatusChange={handleStatusChange}
                              />
                            ))}
                          </ColumnContainer>
                        ) : (
                          <>
                            <VerticalSpace height={30} />
                            <Label
                              alignItems="center"
                              display="flex"
                              color="rgba(164, 163, 167, 1)"
                              justifyContent="center"
                            >
                              No results found.
                            </Label>
                          </>
                        )}
                      </>
                    )}
                  </Container>
                </InnerRowContainer>
              </ScrollableRowContainer>
              <VerticalSpace height={10} />
              <RowContainer justifyContent="flex-end" className="user-data">
                <PaginationComponent
                  page={page}
                  rowsPerPage={limit}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  count={count}
                  hasNextPage={hasNextPage}
                  hasPreviousPage={hasPreviousPage}
                />
              </RowContainer>

              <VerticalSpace height={100} />
            </div>

            {showDeleteDialog && (
              <DeleteConfirmation
                showDeletePopup={true}
                setShowDeletePopup={setShowDeleteDialog}
                message={`Are you sure you want to delete ${
                  selectedQuetions.length === 0
                    ? "this question?"
                    : selectedQuetions.length === 1
                    ? "this question?"
                    : "these questions?"
                }`}
                deleteButtonLabel="Delete"
                onDelete={handleDelete}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Quesiton;

import React, { useEffect, useState } from "react";
import { Menu, MenuItem, Typography, Box, Button } from "@material-ui/core";
import NestedMenuItem from "material-ui-nested-menu-item";
import BottomArrow from "../../assets/bottomArrowIcon.svg";
import RightArrow from "../../assets/RightSideArrow.svg";
import "../../Components/ParticlesDropdown/NestedMenu.css";
import { baseURL, getRequest } from "../../api/apiClient";
import RowContainer from "../Common/RowContainer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { getDropDown, selectLessonsData } from "../../redux/lessonsSlice";

interface Theme {
  id: number;
  name: string;
}

interface Lesson {
  id: number;
  name: string;
  themes?: Theme[];
}

interface Level {
  level: string;
  lessons: Lesson[];
}

interface MenuPosition {
  top: number;
  left: number;
}

interface LessonsProps {
  data: Level[] | null;
  onSelectItem: (item: Theme) => void;
  disabled?: boolean;
  borderColor?: string;
}

const LessonsDropdown: React.FC<LessonsProps> = ({
  data,
  onSelectItem,
  disabled = false,
  borderColor = "#D9D9D9",
}) => {
  const [menuPosition, setMenuPosition] = useState<MenuPosition | null>(null);
  const [selectedItem, setSelectedItem] = useState<Lesson | null>(null);
  const dispatch: AppDispatch = useDispatch();
  const [menuData, setMenuData] = useState<Level[]>([]);

  const menuDatas = useSelector(selectLessonsData);

  useEffect(() => {
    dispatch(getDropDown());
  }, [dispatch]);

  useEffect(() => {
    if(menuDatas){
      const formattedMenuData: Level[] = Object.keys(menuDatas).map(
        (levelKey) => ({
          level: levelKey,
          lessons: (menuDatas as unknown as Record<string, Lesson[]>)[levelKey],
        })
      );
       setMenuData(formattedMenuData);
    }
   
  }, [menuDatas]);
  
  useEffect(()=>{
    if(data && data.length > 0){
      setSelectedItem(data[0].lessons[0]);
    }
  },[data]);
  

  // useEffect(() => {
  //   if (data && data.length > 0) {
  //     setSelectedItem(data[0].lessons[0]);
  //   }
  // }, [data]);

  // useEffect(() => {
  //   if (data && data.length > 0) {
  //     setSelectedItem(data[0].lessons[0]);
  //   }
  // }, [data]);

  // useEffect(() => {
  //   fetchDropdownData();
  // }, []);

  // const fetchDropdownData = async () => {
  //   try {
  //     const response = await getRequest(`${baseURL}/notification-challenge/get-lesson-dropdown`);
  //     if (!response) {
  //       throw new Error("Failed to fetch data");
  //     }
  //     const data = response.data.data;

      //  const formattedData: Level[] = Object.keys(data).map((levelKey) => ({
      //    level: levelKey,
      //    lessons: data[levelKey],
      //  }));

  //      setMenuData(formattedData);
  //   } catch (error) {
  //     console.error("Error fetching dropdown data:", error);
  //   }
  // };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!disabled) {
      setMenuPosition({
        top: event.currentTarget.getBoundingClientRect().bottom,
        left: event.currentTarget.getBoundingClientRect().left,
      });
    }
  };

  const handleItemClick = (theme: Theme) => {
    if (!disabled) {
      setSelectedItem(theme);
      setMenuPosition(null);
      onSelectItem(theme);
    }
  };

  const renderThemes = (themes?: Theme[]) => {
    if (!themes || themes.length === 0) return null;

    return themes.map((theme) => (
      <div className="menu-items-container">
        <MenuItem
          key={theme.id}
          onClick={() => handleItemClick(theme)}
          // className="custom-menu-paper"
          className="menuItem"
        >
          {theme.name}
        </MenuItem>
      </div>
    ));
  };

  const renderLessons = (lessons: Lesson[]) => {
    return lessons.map((lesson) => (
      <div className="menu-items-container">
        <NestedMenuItem
          key={lesson.id}
          label={lesson.name}
          parentMenuOpen={!!menuPosition}
          rightIcon={<img src={RightArrow} alt="right-arrow" />}
          onPointerEnter={() => {}}
          onPointerLeave={() => {}}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          placeholder=""
          className="nestedMenuItem"
        >
          {renderThemes(lesson.themes)}
        </NestedMenuItem>
      </div>
    ));
  };

  const renderMenuItems = (levels: Level[]) => {
    return (
      <div className="menu-items-container">
        {levels.map((level) => (
          <NestedMenuItem
            key={level.level}
            label={level.level}
            parentMenuOpen={!!menuPosition}
            rightIcon={<img src={RightArrow} alt="right-arrow" />}
            className="nestedMenuItem"
            onPointerEnter={() => {}}
            onPointerLeave={() => {}}
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
            placeholder=""
          >
            {renderLessons(level.lessons)}
          </NestedMenuItem>
        ))}
      </div>
    );
  };
  return (
    <div
      className="row d-flex user"
      style={{
        backgroundColor: disabled ? "#e9ecef" : "#FFFFFF",
        border: `1px solid ${borderColor}`,
        padding: "7px 0px",
        margin: "0",
        borderRadius: "10px",
        width: "100%",
        pointerEvents: disabled ? "none" : "auto",
        cursor:'pointer'
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        borderRadius="10px"
        onClick={handleMenuClick}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100%",
          }}
        >
          {selectedItem ? (
            <RowContainer>
              <RowContainer>{selectedItem.name}</RowContainer>
            </RowContainer>
          ) : (
            "Select Item"
          )}
        </Typography>
        {selectedItem ? (
          <img src={RightArrow} alt="bottom-arrow" />
        ) : (
          <img src={BottomArrow} alt="bottom-arrow" />
        )}
      </Box>
      <Menu
        open={!!menuPosition}
        onClose={() => setMenuPosition(null)}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition || undefined}
        className="custom-menu-paper"
        style={{ margin: "0px" }}
      >
        {menuData.length > 0 && renderMenuItems(menuData)}
      </Menu>
    </div>
  );
};

export default LessonsDropdown;

import { useEffect, useState } from "react";
import Loader from "../../utils/Loading";
import styled from "styled-components";
import RowContainer from "../../Components/Common/RowContainer";
import Container from "../../Components/Common/Container";
import Label from "../../Components/Common/Label";
import { baseURL, getRequest } from "../../api/apiClient";
import formatDate from "../../Components/DateFormat";
import VerticalSpace from "../../Components/VerticalSpace";
import ColumnContainer from "../../Components/Common/ColumnContainer";
import { useNavigate } from "react-router-dom";
import gsap from "gsap";
import "../../stylesheets/common.css";
import AppSupportList from "../../Components/AppSupports/AppSupportList";

const InnerRowContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 1100px;     
`;

const ScrollableRowContainer = styled(RowContainer)`
  @media (max-width: 1150px) {
    width: 100%;
    overflow-x: auto;
  }
`;

interface AppSupports {
  id: number;
  name: string;
  updatedAt: string;
}

const AppSupports = () => {
  const [appSupports, setAppSupports] = useState<AppSupports[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    fetchAppSupports();
  }, []);

  const fetchAppSupports = async () => {
    setLoading(true);
    const response = await getRequest(`${baseURL}/get-app-support`);
    if (response?.data?.success) {
      setAppSupports(response?.data.data);
    }
    setLoading(false);
  };

  const handleEditConfig = (id: number) => {
    navigate(`/app-supports/edit-app-supports/${id}`);
  };

  useEffect(() => {
    let timeLine = gsap.timeline();
    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 0.3,
      ease: "power2.out",
    });

    timeLine.from(".user-data", {
      opacity: 0,
      y: 40,
      duration: 0.5,
      ease: "power2.out",
      stagger: 0.2,
    });
  }, [loading]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <div className="p-3">
            <div className="row mb-3 ">
              <div className="col">
                <h2 style={{ fontWeight: "bold" }}>App Supports</h2>
              </div>
            </div>
            <ScrollableRowContainer>
              <InnerRowContainer>
                <Container width="100%">
                  <RowContainer
                    alignItem="center"
                    padding="8px 12px 8px 12px"
                    gap="8px"
                    className="row-container"
                  >
                    <RowContainer flex="0.5">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      ></Label>
                    </RowContainer>
                    <RowContainer flex="3">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        Id
                      </Label>
                    </RowContainer>
                    <RowContainer flex="11">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        Name
                      </Label>
                    </RowContainer>
                    <RowContainer flex="4.9">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        Date
                      </Label>
                    </RowContainer>
                    <RowContainer flex="1.1">
                      <Label
                        fontWeight={400}
                        fontSize="14px"
                        color="rgba(164, 163, 167, 1)"
                      >
                        Action
                      </Label>
                    </RowContainer>
                  </RowContainer>
                  {appSupports && appSupports.length > 0 ? (
                    <ColumnContainer>
                      {appSupports.map((appSupport) => (
                        <AppSupportList
                          key={appSupport.id}
                          id={appSupport.id}
                          name={appSupport.name || "app_support"}
                          updatedAt={formatDate(appSupport.updatedAt)}
                          onEdit={handleEditConfig}
                        />
                      ))}
                    </ColumnContainer>
                  ) : (
                    <>
                      <VerticalSpace height={30} />
                      <Label
                        alignItems="center"
                        display="flex"
                        color="rgba(164, 163, 167, 1)"
                        justifyContent="center"
                      >
                        No results found.
                      </Label>
                    </>
                  )}
                </Container>
              </InnerRowContainer>
            </ScrollableRowContainer>
          </div>
        </div>
      )}
    </>
  );
};

export default AppSupports;

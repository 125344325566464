import React from "react";

// Define a type for the date format function
type DateFormatFunction = (dateString: string) => string;

// Date format function
const formatDate: DateFormatFunction = (dateString) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
    year: "numeric",
  };
  return date.toLocaleDateString("en-GB", options);
};

export default formatDate;

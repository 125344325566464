import React from "react";
import BlockTextComponent from "./BlockTextComponent"; // Adjust the path as needed
import FlashCardImageComponent from "./FlashCardImageComponent"; // Adjust the path as needed
import Container from "../Common/Container";
import GrammarSingleComponent from "./GrammarSingleComponent";
import ColumnContainer from "../Common/ColumnContainer";

interface GrammarGroupComponentProps {
  data: {
    id: number;
    flashCardContentComponentId: number;
    lightTheme: string;
    createdAt: string;
    updatedAt: string;
    data: {
      components: Array<{
        id: number;
        flashCardContentComponentId?: number;
        description?: string;
        lightTheme: string;
        order: number;
        grammarGroupId: number;
        britzelId?: number;
        type: string;
        flashimage?: string; // For flash images
        particles?: {
          inputValue: string;
          dropdownValue: {
            id: number;
            pId: number;
            name: string;
            children: Array<any>;
            particleType: string;
            particleType1: string;
            particleValue: string;
            particleValue1: string;
          };
        };
      }>;
    };
  };
  onClick?: () => void;
}

const GrammarGroupComponent: React.FC<GrammarGroupComponentProps> = ({
  data,
  onClick,
}) => {
  const components = data.data.components || [];
  
  const sortedComponents = [...components].sort((a, b) => a.order - b.order);
  
  return (
    <Container
      bgColor={data.lightTheme === "Light" ? "#FFFFFF" : ""}
      color={data.lightTheme === "Light" ? "#121217" : "#FFFFFF"}
      borderRadius="24px"
      padding="12px 16px"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      onClick={onClick}
    >
      <ColumnContainer flex="unset" alignItem="center" gap="0px">
        {sortedComponents.map((component: any) => {
          switch (component.type) {
            case "block_text":
              return (
                <BlockTextComponent
                  key={component.id}
                  id={component.id}
                  description={component.description || ""}
                  // lightTheme={component.lightTheme}
                  flashCardContentComponentId={
                    component.flashCardContentComponentId
                  }
                />
              );
            case "grammar_single":
              return <GrammarSingleComponent data={component} isBretzel={false}/>;
            case "flash_image":
              return (
                <FlashCardImageComponent
                  key={component.id}
                  id={component.id}
                  flashCardContentComponentId={
                    component.flashCardContentComponentId
                  }
                  flashimage={component.flashimage || ""}
                  order={component.order}
                  grammarGroupId={component.grammarGroupId}
                  britzelId={component.britzelId}
                  height="120px"
                  width="120px"
                />
              );
            default:
              return null;
          }
        })}
      </ColumnContainer>
    </Container>
  );
};

export default GrammarGroupComponent;

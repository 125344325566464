import React, { useState, useRef, useEffect } from "react";
import "../../assets/css/members.css";
import RowContainer from "../../Components/Common/RowContainer";
import Container from "../../Components/Common/Container";
import Label from "../../Components/Common/Label";
import ColumnContainer from "../../Components/Common/ColumnContainer";
import MembershipUser from "../../Components/membershipUser";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import MembersUser from "../../Components/MembersUsers";
import CustomDropdown from "../../Components/Common/CustomDropdown";
import formatDate from "../../Components/DateFormat";
import { toast, ToastContainer } from "react-toastify";
import VerticalSpace from "../../Components/VerticalSpace";
import "../../stylesheets/indexpage.css";
import { baseURL, getRequest, postRequest } from "../../api/apiClient";
import Toast from "../../Components/Common/Toast";
import DeleteConfirmation from "../../Components/Common/DeleteConfirmation";
import PaginationComponent from "../../Components/Common/PaginationComponent";
import Loader from "../../utils/Loading";
import gsap from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
import '../../stylesheets/common.css'

// Register the ScrollTrigger plugin
// gsap.registerPlugin(ScrollTrigger);
import ShimmerEffect from "../../utils/ShimmerEffect";

const InnerRowContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 1100px;
`;

const ScrollableRowContainer = styled(RowContainer)`
  @media (max-width: 1150px) {
    width: 100%;
    overflow-x: auto;
  }
`;

interface User {
  id: number;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
  active: boolean;
}

const MembersUsers = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [filterLoading, setFilterLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(5);
  const [filters, setFilters] = useState<{
    createdAt?: number;
  }>({});
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const navigate = useNavigate();

  const handleSelect = (item: string) => {
    setSelectedItem(item);
  };

  const dropdownItems: string[] = ["All", "Created"];

  const fectMember = async (
    page: number,
    perPage: number,
    filters: { level?: string; createdAt?: number }
  ) => {
    if (!filters) {
      setLoading(true);
    } else {
      setFilterLoading(true);
    }
    try {
      let url = `${baseURL}/users/get-users?isPremium=${false}&page=${page}&limit=${perPage}`;
      if (filters.createdAt) {
        url += `&createdAt=${filters.createdAt}`;
      }

      const response = await getRequest(url);

      if (response?.data) {
        const modifiedUsers = response.data.data.users.map((user: User) => {
          const fullNameParts = user.fullName.split(" ");
          const firstName = fullNameParts[0];
          const lastName = fullNameParts.slice(1).join(" "); // Join the remaining parts as lastName
          return { ...user, firstName, lastName };
        });
        setUsers(modifiedUsers);
        setCount(response.data.data.totalUserCount);
        setPage(response.data.data.page - 1);
        setHasNextPage(response.data.data.hasNextPage);
        setHasPreviousPage(response.data.data.hasPreviousPage);
      }
      setLoading(false);
      setFilterLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching lessons:", error);
    }
  };

  useEffect(() => {
    fectMember(page + 1, limit, filters);
  }, [page, limit, filters]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleApplyFilters = () => {
    switch (selectedItem) {
      case "Created":
        setFilters({ createdAt: -1 });
        break;
      default:
        setFilters({});
        break;
    }
  };

  const handleStatusChange = async (id: number) => {
    await getRequest(`${baseURL}/users/change-status/${id}`);
    // await fectMember(page + 1, limit, filters);
  };

  const handleEditMember = (id: number) => {
    navigate(`/users/edit-user/${id}`);
  };

  const handleCreateUser = () => {
    navigate('/users/create-user');
  };

  const onCheckboxChange = (id: number) => {
    if (selectedUsers.includes(id)) {
      setSelectedUsers(selectedUsers.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedUsers([...selectedUsers, id]);
    }
  };

  const deleteSelectedUsers = () => {
    if (selectedUsers.length > 0) {
      setShowDeleteDialog(true);
    } else {
      Toast("Please select at least one user to delete.", "error");
    }
  };

  const deleteUsers = async (userIds: number | number[]) => {
    try {
      const payload = {
        userId: Array.isArray(userIds) ? userIds : userIds,
      };
      const response = await postRequest(
        `${baseURL}/users/delete-users`,
        payload
      );

      if (response?.status === 200) {
        Toast(response.data.statusMessage, "success");
        setUsers((prevData) =>
          prevData.filter((user) =>
            Array.isArray(userIds)
              ? !userIds.includes(user.id)
              : user.id !== userIds
          )
        );
      } else {
        toast.error("Failed to delete user(s)");
      }
    } catch (error) {
      console.error("Error deleting lesson:", error);
      toast.error("An error occurred while deleting the lesson(s)");
    }
  };

  const handleDelete = async () => {
    if (selectedUserId !== null) {
      await deleteUsers(selectedUserId);
    } else {
      await deleteUsers(selectedUsers);
    }
    setSelectedUsers([]);
    setSelectedUserId(null);
    setShowDeleteDialog(false);
  };

  const toggleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  const handleInActiveUserList = () => {
    navigate("inactive-users");
  };

  useEffect(() => {
    let timeLine = gsap.timeline();
    // timeLine.from(".member", {
    //   opacity: 0,
    //   y: 40,
    //   duration: 0.3,
    //   ease: "power2.out",
    //   stagger: 0.2
    // });

    // timeLine.from(".left-buttons", {
    //   opacity: 0,
    //   x: -40,  
    //   duration: 0.3,
    //   stagger: 0.4, 
    //   ease: "power2.out", 
    // });

    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 0.3,
      ease: "power2.out",
    });

    timeLine.from(".user-data", {
      opacity: 0,
      y: 40,
      duration: 0.5,
      ease: "power2.out",
      stagger: 0.2
    });


  }, [loading]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="container-fluid">
            <div className="p-3">
              <div className="row mb-3 member">
                <div className="col">
                  <h2 style={{ fontWeight: "bold" }}>Members free</h2>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="filter" className="form-label member">
                    Filter
                  </label>
                </div>
              </div>

              <div className="row mb-3 main-responsive">
                <div className="col d-flex responsive left-buttons">
                  <CustomDropdown
                    items={dropdownItems}
                    onSelect={handleSelect}
                    selectedItem={selectedItem || ""}
                    height="50px"
                  />
                  <button
                    type="button"
                    className="btn btn-primary ms-3 user"
                    style={{
                      backgroundColor: "#100F15",
                      borderRadius: "10px",
                      color: "#FFFFFF",
                      fontSize: "14px",
                      width: "auto",
                      padding: "14px 50px",
                    }}
                    onClick={handleApplyFilters}
                  >
                    Apply
                  </button>
                </div>
                <div className="col d-flex justify-content-end responsive left-buttons">
                  <button
                    type="button"
                    className="btn btn-outline-secondary me-2 text-center user"
                    style={{
                      backgroundColor: "#EB002C",
                      borderRadius: "10px",
                      color: "white",
                      fontSize: "14px",
                      width: "auto",
                      padding: "14px 40px",
                    }}
                    onClick={handleInActiveUserList}
                  >
                    InActive user
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary me-2 text-center user"
                    style={{
                      backgroundColor: "#FF9E00",
                      borderRadius: "10px",
                      color: "#100F15",
                      fontSize: "14px",
                      width: "auto",
                      padding: "14px 40px",
                    }}
                    onClick={handleCreateUser}
                  >
                    Add new
                  </button>
                  {/* <button
              type="button"
              className="btn btn-outline-secondary me-2 text-center user"
              style={{
                color: "#100F15",
                background: "none",
                border: "1px solid #100F15",
                borderRadius: "10px",
                fontSize: "14px",
                width: "auto",
                padding: "14px 40px",
              }}
              onClick={deleteSelectedUsers}
            >
              Delete all
            </button> */}
                </div>
              </div>

              <ScrollableRowContainer>
                <InnerRowContainer>
                  <Container width="100%">
                    <RowContainer
                      alignItem="center"
                      padding="8px 12px 8px 12px"
                      gap="8px"
                      className="row-container"
                    >
                      <RowContainer flex="1.6">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        ></Label>
                      </RowContainer>
                      <RowContainer flex="2">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          ID
                        </Label>
                      </RowContainer>
                      <RowContainer flex="5">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Name
                        </Label>
                      </RowContainer>
                      <RowContainer flex="5">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Lastname
                        </Label>
                      </RowContainer>
                      <RowContainer flex="8">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          email
                        </Label>
                      </RowContainer>
                      <RowContainer flex="6.1">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Created
                        </Label>
                      </RowContainer>
                      <RowContainer flex="2.7">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Action
                        </Label>
                      </RowContainer>
                    </RowContainer>
                    {filterLoading ? (
                      <>
                        <RowContainer
                          bgColor="#ffffff"
                          borderRadius="8px"
                          padding="10px 0px"
                        >
                          {Array.from({ length: 6 }, (_, index) => {
                            return (
                              <RowContainer
                                key={index}
                                width={
                                  index === 0 || index === 5 ? "10%" : "20%"
                                }
                                display="flex"
                                justifyContent="center"
                              >
                                <ShimmerEffect />
                              </RowContainer>
                            );
                          })}
                        </RowContainer>
                      </>
                    ) : (
                      <>
                        {users && users.length > 0 ? (
                          <ColumnContainer>
                            {users.map((user, index) => (
                              <MembersUser
                                srNo={page * limit + index + 1}
                                key={user.id}
                                id={user.id}
                                name={user.firstName}
                                lastname={user.lastName || "N/A"}
                                email={user.email}
                                created={formatDate(user.createdAt)}
                                onEdit={handleEditMember}
                                onDelete={() => {
                                  setSelectedUserId(user.id);
                                  toggleDeleteDialog();
                                }}
                                isChecked={selectedUsers.includes(user.id)}
                                onCheckboxChange={onCheckboxChange}
                                status={user.active}
                                onStatusChange={handleStatusChange}
                              />
                            ))}
                          </ColumnContainer>
                        ) : (
                          <>
                            <VerticalSpace height={30} />
                            <Label
                              alignItems="center"
                              display="flex"
                              color="rgba(164, 163, 167, 1)"
                              justifyContent="center"
                            >
                              No results found.
                            </Label>
                          </>
                        )}
                      </>
                    )}
                  </Container>
                </InnerRowContainer>
              </ScrollableRowContainer>
              <VerticalSpace height={10} />
              <VerticalSpace height={10} />
              <RowContainer justifyContent="flex-end" className="user-data">
                <PaginationComponent
                  page={page}
                  rowsPerPage={limit}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  count={count}
                  hasNextPage={hasNextPage}
                  hasPreviousPage={hasPreviousPage}
                />
              </RowContainer>
            </div>
            {showDeleteDialog && (
              <DeleteConfirmation
                showDeletePopup={true}
                setShowDeletePopup={setShowDeleteDialog}
                message={`Are you sure you want to delete ${
                  selectedUsers.length === 0
                    ? "this user?"
                    : selectedUsers.length === 1
                    ? "this users?"
                    : "these users?"
                }`}
                deleteButtonLabel="Delete"
                onDelete={handleDelete}
              />
            )}
            <VerticalSpace height={100} />
          </div>
        </>
      )}
    </>
  );
};

export default MembersUsers;

// DeleteLoader.tsx
import React from "react";
import "./Loading.css"; 
import lexikLoader from "../assets/Images/Lexik-loader1.gif";

interface ImageLoader {
  width?: string;
  height?: string;
}

const DeleteLoader: React.FC<ImageLoader> = ({ width, height }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        width: "100%",
        height: "100%",
        zIndex: 9,
      }}
    >
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "100%" }}
      >
        <img
          src={lexikLoader}
          className="Loader-Icon"
          alt="Loading"
          height={height || "50px"}
        />
      </div>
    </div>
  );
};

export default DeleteLoader;

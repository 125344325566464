import React from "react";

interface StatusConfirmationProps {
  showStatusPopup: boolean;
  setShowStatusPopup: (show: boolean) => void;
  message: string;
  statusButtonLabel: string;
  onStatus?: () => void;
  onCancel?: () => void;
}

const StatusChangeConfirmation: React.FC<StatusConfirmationProps> = ({
  showStatusPopup,
  setShowStatusPopup,
  message,
  statusButtonLabel,
  onCancel,
  onStatus,
}) => {
  const handleUpdateStatus = () => {
    if (onStatus) {
      onStatus();
    }
    setShowStatusPopup(false);
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      setShowStatusPopup(false);
    }
  };

  return (
    <>
      {showStatusPopup && (
        <div
          className="modal"
          role="dialog"
          style={{
            display: "block",
            position: "fixed",
            zIndex: 1000,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          }}
        >
          <div className="modal-dialog">
            <div
              className="modal-content row-container"
              style={{ borderRadius: "24px", padding: "6px" }}
            >
              <div className="modal-body">
                <h5
                  className="modal-body d-flex align-items-center justify-content-center"
                  style={{ fontWeight: "bold", top: "20px" }}
                >
                  {message}
                </h5>
              </div>
              {/* Assuming VerticalSpace is another component */}
              <div className="modal-body d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary user"
                  onClick={handleCancel}
                  style={{
                    height: "50px",
                    color: "#100F15",
                    background: "none",
                    border: "1px solid #100F15",
                    borderRadius: "10px",
                    fontSize: "14px",
                    width: "auto",
                    padding: "14px 35px",
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger user"
                  style={{
                    backgroundColor:
                      statusButtonLabel === "Deactivate"
                        ? "#EB002C"
                        : "#28A745",
                    color: "#fff",
                    borderRadius: "10px",
                    fontSize: "14px",
                    width: "auto",
                    padding: "14px 35px",
                  }}
                  onClick={handleUpdateStatus}
                >
                  {statusButtonLabel}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StatusChangeConfirmation;

import { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import HorizontalSpace from "../HorizontalSpace";
import "../../assets/css/createlessons.css";
import VerticalSpace from "../VerticalSpace";
import deleteIcon from "../../assets/deleteIcon.svg";
import { Link, useParams } from "react-router-dom";
import Label from "../Common/Label";
import { useNavigate } from "react-router-dom";
import {
  baseURL,
  multiPostRequest,
  postRequest,
} from "./../../api/apiClient";
import "react-toastify/dist/ReactToastify.css";
import RowContainer from "../Common/RowContainer";
import ColumnContainer from "../Common/ColumnContainer";
import DeleteConfirmation from "../Common/DeleteConfirmation";
import Loader from "../../utils/Loading";
import gsap from "gsap";
import "../../stylesheets/common.css";
import DeleteLoader from "../../utils/deleteLoader";
import styled from "styled-components";
import Toast from "../Common/Toast";

const NewLabel = styled(Label)`
  @media (max-width: 769px) {
    font-size: 19px;
  }
`;

const NewRowContainer = styled(RowContainer)`
  @media (max-width: 769px) {
    flex-direction: column;
  }
`;

const NewRowContainers = styled(RowContainer)`
  @media (max-width: 769px) {
    flex-direction: row;
  }
`;

const NewColumnContainers = styled(ColumnContainer)`
  @media (max-width: 769px) {
    min-width: auto;
  }
`;

const NewColumnContainer = styled(ColumnContainer)`
  @media (max-width: 769px) {
    max-width: -webkit-fill-available;
  }
`;

const NewLabels = styled(Label)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: 769px) {
    max-width: 100px;
  }
`;

interface Level {
  id: number;
  level: string;
  challenges_tag: string;
  level_rank: string;
  badge_image: string;
  action_sheet_image: string;
  text: string;
  points_tag: string;
  order: number;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
}

const EditLevel = () => {
  const { id } = useParams();
  const [challengeTag, setChallengeTag] = useState("");
  const [levelRank, setLevelRank] = useState("");
  const [levelData, setLeveldata] = useState<Level | null>();
  const [text, setText] = useState("");
  const [pointsTag, setPointsTag] = useState("");
  const badgeInputRef = useRef<HTMLInputElement>(null);
  const actionSheetInputRef = useRef<HTMLInputElement>(null);
  const [badgeImage, setBadgeImage] = useState("");
  const [actionSheetImage, setActionSheetImage] = useState("");
  const [deleteDialogMessage, setDeleteDialogMessage] = useState("");
  const [onConfirmDelete, setOnConfirmDelete] = useState<() => void>(() => { });
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [badgeImageImageLoading, setBadgeImageImageLoading] = useState(false);
  const [actionSheetImageLoading, setActionSheetImageLoading] =
    useState(false);
  const [errors, setErrors] = useState({
    challengeTag: "",
    levelRank: "",
    text: "",
    pointsTag: "",
    badgeImage: "",
    actionSheeImage: "",
  });

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    setImageLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setImage: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setImageLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      const response = await multiPostRequest(
        `${baseURL}/file-upload`,
        formData
      );

      if (response?.data.success) {
        setImage(response?.data?.data[0].uri);
      }
      setImageLoading(false);
    }
  };

  // Event handlers using the generic handler
  const handleBadgeImageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleImageChange(event, setBadgeImageImageLoading, setBadgeImage);
  };

  const handleActionSheetImageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleImageChange(event, setActionSheetImageLoading, setActionSheetImage);
  };


  const handleChooseFileClick = (inputType: string) => {
    const inputRef =
      inputType === "badge"
        ? badgeInputRef
        : actionSheetInputRef;
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleDeleteImage = async (imageType: string) => {
    let fileUri = "";

    switch (imageType) {
      case "badge":
        fileUri = badgeImage;
        break;
      case "action_sheet_image":
        fileUri = actionSheetImage;
        break;
      default:
        return;
    }

    try {
      const response = await postRequest(`${baseURL}/remove-file`, {
        fileUri,
      });

      if (response?.data.success) {
        switch (imageType) {
          case "badge":
            setBadgeImage("");
            break;
          case "action_sheet_image":
            setActionSheetImage("");
            break;
          default:
            break;
        }
      } else {
        console.error("Failed to delete the image");
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  const validateLevelData = () => {
    return {
      challengeTag: !challengeTag ? "challenge tag is required" : "",
      levelRank: !levelRank ? "level rank is required" : "",
      pointsTag: !pointsTag ? "points tag is required" : "",
      text: !text ? "text is required" : "",
      badgeImage: !badgeImage ? "badge image is required" : "",
      actionSheeImage: !actionSheetImage
        ? "action sheet image is required"
        : "",
    };
  };

  //handle public click


  const handleBackClick = () => {
    navigate(`/ranking-levels`);
  };

  const fetchLevel = async () => {
    setLoading(true);
    const response: any = await postRequest(`${baseURL}/levels/get/${id}`);
    console.log('response: ', response);
    if (response) {
      const levelInfo = response?.data?.data;
      setChallengeTag(levelInfo.challenges_tag || "");
      setLevelRank(levelInfo.level_rank || "");
      setText(levelInfo.text || "");
      setPointsTag(levelInfo.points_tag || "");
      setBadgeImage(levelInfo.badge_image || "");
      setActionSheetImage(levelInfo.action_sheet_image || "");
    }
    setLoading(false);
  }

  const handlePublishClick = async () => {
    const validationErrors = validateLevelData();
    setErrors(validationErrors);

    // If any validation error exists, stop the form submission
    if (Object.values(validationErrors).some((error) => error)) {
      return;
    }

    setIsLoading(true);
    const payload = {
      challenges_tag: challengeTag,
      level_rank: levelRank,
      badge_image: badgeImage,
      action_sheet_image: actionSheetImage,
      text: text,
      points_tag: pointsTag
    }
    const response: any = await postRequest(`${baseURL}/levels/update/${id}`, payload);
    if (response) {
      Toast("Level updated successfully", "success");
    }
    console.log('response: ', response);
    setIsLoading(false);
    // try {
    //   if (levelId) {
    //     // await handleLessonRequest("update");
    //   } else {
    //     await handleLessonRequest("create");
    //   }
    // } catch (error) {
    //   setIsLoading(false);
    // }
  };


  useState(() => {
    fetchLevel();
  });


  useEffect(() => {
    if (levelData?.level_rank) {
      setLevelRank(levelData.level_rank);
    }
  }, [levelData]);



  useEffect(() => {
    let timeLine = gsap.timeline();
    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 0.3,
      ease: "power2.out",
    });

    timeLine.from(".user-data", {
      opacity: 0,
      y: 40,
      duration: 0.2,
      ease: "power2.out",
    });

    timeLine.from(".left-container", {
      opacity: 0,
      x: -40,
      duration: 0.3,
      ease: "power2.out",
      stagger: 0.2,
    });

    timeLine.from(".right-container", {
      opacity: 0,
      x: 40,
      duration: 0.5,
      ease: "power2.out",
      stagger: 0.2,
    });
  }, [isLoading]);

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="p-3 main-page">
            <div
              className="d-flex align-items-center go-back"
              onClick={handleBackClick}
              style={{ width: "fit-content" }}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="me-2"
                style={{
                  backgroundColor: "#D9D9D9",
                  padding: "7px",
                  borderRadius: "8px",
                  color: "#000",
                  cursor: "pointer",
                }}
              />
              <label
                style={{
                  color: "#FF9E00",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Back
              </label>
            </div>
            <Label
              fontSize="22px"
              alignItems="center"
              display="flex"
              justifyContent="center"
              color="#7542E4"
              fontWeight={700}
              className="row-container"
            >
              Level 1 settings
            </Label>
            <VerticalSpace height={20} />
            <div className="row p-3 d-flex main-container justify-content-between">
              <div className="col lesson-container left-container">
                <div
                  className="container p-3"
                  style={{
                    backgroundColor: "rgb(220 220 220 / 50%)",
                    borderRadius: "30px",
                  }}
                >
                  <div className="col">
                    <div className="col-12">
                      <div className="d-flex justify-content-between lessons-top align-items-center">
                        <Label fontSize="20px" fontWeight={700}>
                          Challenges tag
                        </Label>
                      </div>
                      <VerticalSpace height={10} />
                      <input
                        type="text"
                        placeholder="Challenges tag"
                        className={`form-control ${errors.challengeTag ? "is-invalid" : ""
                          }`}
                        id="challengestag"
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                        value={challengeTag}
                        onChange={(e) => setChallengeTag(e.target.value)}
                      />
                      {errors.challengeTag && (
                        <div className="invalid-feedback">
                          {errors.challengeTag}
                        </div>
                      )}
                    </div>
                    <VerticalSpace height={20} />
                    <div className="col-12">
                      <div className="d-flex justify-content-between align-items-center">
                        <Label fontSize="20px" fontWeight={700}>
                          Level Rank
                        </Label>
                      </div>
                      <VerticalSpace height={10} />
                      <input
                        type="text"
                        placeholder="levelRank"
                        className={`form-control ${errors.levelRank ? "is-invalid" : ""
                          }`}
                        id="levelRank"
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                        value={levelRank}
                        onChange={(e) => setLevelRank(e.target.value)}
                      />
                      {errors.levelRank && (
                        <div className="invalid-feedback">
                          {errors.levelRank}
                        </div>
                      )}
                    </div>
                    <VerticalSpace height={20} />
                    <div className="col-12">
                      <NewColumnContainer maxWidth="auto">
                        <NewLabel
                          fontWeight={700}
                          fontSize="20px"
                          color="#000000"
                        >
                          Badge image
                        </NewLabel>
                        <RowContainer>
                          {!badgeImage && (
                            <NewRowContainer
                              bgColor="#FFFFFF"
                              borderRadius="20px"
                              padding="14px 16px"
                              alignItem="center"
                              //   width="auto"
                              gap="14px"
                              justifyContent="space-between"
                            >
                              {badgeImageImageLoading ? (
                                <RowContainer
                                  alignItem="center"
                                  justifyContent="center"
                                  padding="5px"
                                >
                                  <Loader />
                                </RowContainer>
                              ) : (
                                <>
                                  <Label
                                    fontSize="14px"
                                    fontWeight={400}
                                    color="#52525F"
                                    width="max-content"
                                  >
                                    Upload an image jpg, png or svg
                                  </Label>
                                  <input
                                    type="file"
                                    ref={badgeInputRef}
                                    hidden
                                    accept="image/*"
                                    className="form-control"
                                    onChange={handleBadgeImageChange}
                                  />
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary text-center user"
                                    style={{
                                      color: "#100F15",
                                      background: "none",
                                      border: "1px solid #100F15",
                                      borderRadius: "10px",
                                      padding: "14px 30px",
                                      fontSize: "14px",
                                      width: "auto",
                                    }}
                                    onClick={() =>
                                      handleChooseFileClick("badge")
                                    }
                                  >
                                    choose file
                                  </button>
                                </>
                              )}
                            </NewRowContainer>
                          )}
                          {badgeImage && (
                            <>
                              <NewRowContainers
                                bgColor="#FFFFFF"
                                borderRadius="20px"
                                padding="14px 16px"
                                alignItem="center"
                                // width="400px"
                                gap="14px"
                                justifyContent="space-between"
                              >
                                {badgeImageImageLoading ? (
                                  <RowContainer
                                    alignItem="center"
                                    justifyContent="center"
                                    padding="5px"
                                  >
                                    <Loader />
                                  </RowContainer>
                                ) : (
                                  <>
                                    <RowContainer alignItem="center">
                                      {typeof badgeImage === "string" ? (
                                        <>
                                          <img
                                            src={badgeImage}
                                            alt="flash-image"
                                            style={{
                                              height: "55px",
                                              cursor: "pointer",
                                              objectFit: "contain",
                                            }}
                                            onClick={() =>
                                              handleChooseFileClick("badge")
                                            }
                                          />
                                          <input
                                            type="file"
                                            ref={badgeInputRef}
                                            hidden
                                            accept="image/*"
                                            onChange={handleBadgeImageChange}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            src={URL.createObjectURL(
                                              badgeImage
                                            )}
                                            alt="flash-image"
                                            style={{
                                              height: "55px",
                                              cursor: "pointer",
                                              objectFit: "contain",
                                            }}
                                          />
                                        </>
                                      )}
                                    </RowContainer>

                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        handleDeleteImage("badge");
                                      }}
                                    >
                                      <img
                                        src={deleteIcon}
                                        className="user-scale"
                                        alt="Delete"
                                        style={{
                                          WebkitUserSelect: "none",
                                          userSelect: "none",
                                        }}
                                      />
                                    </div>
                                  </>
                                )}
                              </NewRowContainers>
                            </>
                          )}
                        </RowContainer>
                      </NewColumnContainer>
                    </div>
                  </div>
                </div>
              </div>
              <HorizontalSpace width={2} />
              <div className="col lesson-container right-container">
                <div
                  className="container p-3"
                  style={{
                    backgroundColor: "rgb(220 220 220 / 50%)",
                    borderRadius: "30px",
                  }}
                >
                  <div className="col">
                    <div className="col-12">
                      <NewColumnContainer maxWidth="auto">
                        <NewLabel
                          fontWeight={700}
                          fontSize="20px"
                          color="#000000"
                        >
                          Action sheet image
                        </NewLabel>
                        <RowContainer>
                          {!actionSheetImage && (
                            <NewRowContainer
                              bgColor="#FFFFFF"
                              borderRadius="20px"
                              padding="14px 16px"
                              alignItem="center"
                              //   width="auto"
                              gap="14px"
                              justifyContent="space-between"
                            >
                              {actionSheetImageLoading ? (
                                <RowContainer
                                  alignItem="center"
                                  justifyContent="center"
                                  padding="5px"
                                >
                                  <Loader />
                                </RowContainer>
                              ) : (
                                <>
                                  <Label
                                    fontSize="14px"
                                    fontWeight={400}
                                    color="#52525F"
                                    width="max-content"
                                  >
                                    Upload an image jpg, png or svg
                                  </Label>
                                  <input
                                    type="file"
                                    ref={actionSheetInputRef}
                                    hidden
                                    accept="image/*"
                                    className="form-control"
                                    onChange={handleActionSheetImageChange}
                                  />
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary text-center user"
                                    style={{
                                      color: "#100F15",
                                      background: "none",
                                      border: "1px solid #100F15",
                                      borderRadius: "10px",
                                      padding: "14px 30px",
                                      fontSize: "14px",
                                      width: "auto",
                                    }}
                                    onClick={() =>
                                      handleChooseFileClick("action_sheet_image")
                                    }
                                  >
                                    choose file
                                  </button>
                                </>
                              )}
                            </NewRowContainer>
                          )}
                          {actionSheetImage && (
                            <>
                              <NewRowContainers
                                bgColor="#FFFFFF"
                                borderRadius="20px"
                                padding="14px 16px"
                                alignItem="center"
                                // width="400px"
                                gap="14px"
                                justifyContent="space-between"
                              >
                                {actionSheetImageLoading ? (
                                  <RowContainer
                                    alignItem="center"
                                    justifyContent="center"
                                    padding="5px"
                                  >
                                    <Loader />
                                  </RowContainer>
                                ) : (
                                  <>
                                    <RowContainer alignItem="center">
                                      {typeof actionSheetImage === "string" ? (
                                        <>
                                          <img
                                            src={actionSheetImage}
                                            alt="flash-image"
                                            style={{
                                              height: "55px",
                                              cursor: "pointer",
                                              objectFit: "contain",
                                            }}
                                            onClick={() =>
                                              handleChooseFileClick(
                                                "action_sheet_image"
                                              )
                                            }
                                          />
                                          <input
                                            type="file"
                                            ref={actionSheetInputRef}
                                            hidden
                                            accept="image/*"
                                            onChange={
                                              handleActionSheetImageChange
                                            }
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            src={URL.createObjectURL(
                                              actionSheetImage
                                            )}
                                            alt="flash-image"
                                            style={{
                                              height: "55px",
                                              cursor: "pointer",
                                              objectFit: "contain",
                                            }}
                                          />
                                        </>
                                      )}
                                    </RowContainer>

                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        handleDeleteImage("action_sheet_image");
                                      }}
                                    >
                                      <img
                                        src={deleteIcon}
                                        className="user-scale"
                                        alt="Delete"
                                        style={{
                                          WebkitUserSelect: "none",
                                          userSelect: "none",
                                        }}
                                      />
                                    </div>
                                  </>
                                )}
                              </NewRowContainers>
                            </>
                          )}
                        </RowContainer>
                      </NewColumnContainer>
                    </div>
                    <VerticalSpace height={20} />
                    <div className="col-12">
                      <div className="d-flex justify-content-between lessons-top align-items-center">
                        <Label fontSize="20px" fontWeight={700}>
                          Text
                        </Label>
                      </div>
                      <VerticalSpace height={10} />
                      <input
                        type="text"
                        placeholder="Text"
                        className={`form-control ${errors.text ? "is-invalid" : ""
                          }`}
                        id="text"
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                      />
                      {errors.text && (
                        <div className="invalid-feedback">{errors.text}</div>
                      )}
                    </div>
                    <VerticalSpace height={20} />
                    <div className="col-12">
                      <div className="d-flex justify-content-between align-items-center">
                        <Label fontSize="20px" fontWeight={700}>
                          Points tag
                        </Label>
                      </div>
                      <VerticalSpace height={10} />
                      <input
                        type="text"
                        placeholder="pointsTag"
                        className={`form-control ${errors.pointsTag ? "is-invalid" : ""
                          }`}
                        id="pointsTag"
                        style={{
                          borderRadius: "10px",
                          height: "40px",
                          fontSize: "14px",
                        }}
                        value={pointsTag}
                        onChange={(e) => setPointsTag(e.target.value)}
                      />
                      {errors.pointsTag && (
                        <div className="invalid-feedback">
                          {errors.pointsTag}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <VerticalSpace height={200} />
              <div
                className="container row d-flex justify-content-center publish-button"
                style={{
                  position: "fixed",
                  height: "12%",
                  bottom: "0px",
                  zIndex: 1,
                  background:
                    "linear-gradient(178.35deg, rgba(255, 255, 255, 0) 1.4%, rgba(255, 255, 255, 0.69) 26.79%, #FFFFFF 50.22%)",
                }}
              >
                <div
                  className="d-flex justify-content-center"
                  style={{
                    position: "fixed",
                    zIndex: 1,
                    bottom: "34px",

                    backgroundColor: "#FFFFFF",
                  }}
                >
                  {" "}
                  <button
                    type="button"
                    className="btn btn-success d-flex justify-content-center user"
                    style={{
                      backgroundColor: "#FF9E00",
                      color: "#100F15",
                      borderRadius: "10px",
                      height: "fit-content",
                      padding: "10px 20%",
                      width: "400px",
                      fontSize: "14px",
                    }}
                    onClick={handlePublishClick}
                  >
                    Publish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showDeleteDialog && (
        <DeleteConfirmation
          showDeletePopup={true}
          setShowDeletePopup={setShowDeleteDialog}
          message={deleteDialogMessage}
          deleteButtonLabel="Delete"
          onDelete={onConfirmDelete}
          onCancel={onConfirmDelete}
        />
      )}

      {loading && <DeleteLoader />}
    </div>
  );
};

export default EditLevel;

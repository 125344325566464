import React from "react";
import { Colors } from "./Colors"; // Import your color names
import {
  LeftRoundedCornerParticle,
  RoundedCornerParticle,
  RightRoundedCornerParticle,
  SideNodeParticle,
  TextParticles,
  RightSideTriangleParticles,
  InsideNodeParticles,
  InsideNodeParticlesSecond,
  TriangleShapePainter,
} from "./ParticlesSwitchCase"; // Import your components
import RowContainer from "./RowContainer";

interface ParticlesProps {
  text: string[];
  type: string[];
  textColor?: string;
}

const Particles: React.FC<ParticlesProps> = ({ text, type, textColor }) => {
  return getParticle(type, text, textColor);
};

const getParticle = (type: string[], text: string[], textColor?: string) => {
  switch (true) {
    case type[0] === "Nominative" && type[1] === "":
      return (
        <LeftRoundedCornerParticle
          bgColor={Colors.subjectDeclensionColor}
          textColor={Colors.lightBackgroundColor}
          text={text[0]}
          radius="20px 4px 4px 20px"
        />
      );
    case type[0] === "Conjugated verb" && type[1] === "":
      return (
        <RoundedCornerParticle
          bgColor={Colors.verb1VerbColor}
          text={text[0]}
          borderRadius={24}
        />
      );
    case type[0] === "Infinitive" && type[1] === "":
      return (
        <RightRoundedCornerParticle
          bgColor={Colors.verb2VerbColor}
          textColor={Colors.lightBackgroundColor}
          text={text[0]}
        />
      );
    case type[0] === "infinitiveVerb" && type[1] === "":
      return (
        <RightRoundedCornerParticle
          bgColor={Colors.verb2VerbColor}
          text={text[0]}
        />
      );
    case type[0] === "Participle" && type[1] === "":
      return (
        <RoundedCornerParticle
          bgColor={Colors.verb2VerbColor}
          text={text[0]}
          borderRadius={24}
        />
      );
    case type[0] === "Conjugated verb p1" && type[1] === "":
      return (
        <SideNodeParticle
          bgColor={Colors.verb1VerbColor}
          text={text[0]}
          countOfNode={2}
          isNodeAlignSpaceBetween={true}
          isSameBorderRadius={false}
        />
      );
    case type[0] === "prefix" && type[1] === "":
      return (
        <SideNodeParticle
          bgColor={Colors.verb2VerbColor}
          text={text[0]}
          countOfNode={1}
          isNodeRightSide={false}
          isNodeAlignSpaceBetween={true}
        />
      );
    case type[0] === "Text" && type[1] === "":
      return (
        <TextParticles text={text[0]} textColor={textColor} textSize="15px" />
      );
    case type[0] === "Accusative" && type[1] === "":
      return (
        <RoundedCornerParticle
          bgColor={Colors.acusativDeclensionColor}
          text={text[0]}
          borderRadius={4}
        />
      );
    case type[0] === "Masculine" && type[1] === "":
      return (
        <RoundedCornerParticle
          bgColor={Colors.masculineGenreColor}
          text={text[0]}
          borderRadius={4}
        />
      );
    case type[0] === "Femenine" && type[1] === "":
      return (
        <RoundedCornerParticle
          bgColor={Colors.feminineGenreColor}
          text={text[0]}
          borderRadius={4}
        />
      );
    case type[0] === "Neutral" && type[1] === "":
      return (
        <RoundedCornerParticle
          bgColor={Colors.neutralGenreColor}
          text={text[0]}
          borderRadius={4}
        />
      );
    case type[0] === "Plural" && type[1] === "":
      return (
        <RoundedCornerParticle
          bgColor={Colors.pluralGenreColor}
          text={text[0]}
          borderRadius={4}
          textColor={Colors.darkTextColor}
        />
      );
    case type[0] === "Dative" && type[1] === "":
      console.log("first");
      return (
        <RoundedCornerParticle
          bgColor={Colors.dativDeclensionColor}
          text={text[0]}
          borderRadius={4}
        />
      );
    case type[0] === "Genetive" && type[1] === "":
      return (
        <RoundedCornerParticle
          bgColor={Colors.genitivDeclensionColor}
          text={text[0]}
          borderRadius={4}
        />
      );
    case type[0] === "zu" && type[1] === "":
      return (
        <LeftRoundedCornerParticle
          bgColor={Colors.verb2VerbColor}
          text={text[0]}
        />
      );
    case type[0] === "Negation" && type[1] === "":
      return (
        <RoundedCornerParticle
          bgColor={Colors.negationAdverbsColor}
          text={text[0]}
          borderRadius={24}
        />
      );
    case type[0] === "imperative" && type[1] === "":
      return (
        <RightSideTriangleParticles
          bgColor={Colors.verb1VerbColor}
          text={text[0]}
          borderRadius={4}
        />
      );
    case type[0] === "Te" && type[1] === "":
      return (
        <InsideNodeParticles
          bgColor={Colors.tekamoloAdverbsColor}
          text={text[0]}
          dotCount={1}
          whitePositionIndex={1}
          textColor={Colors.lightTextColor}
        />
      );
    case type[0] === "Ka" && type[1] === "":
      return (
        <InsideNodeParticles
          bgColor={Colors.tekamoloAdverbsColor}
          text={text[0]}
          dotCount={2}
          whitePositionIndex={2}
        />
      );
    case type[0] === "Mo" && type[1] === "":
      return (
        <InsideNodeParticles
          bgColor={Colors.tekamoloAdverbsColor}
          text={text[0]}
          dotCount={3}
          whitePositionIndex={3}
        />
      );
    case type[0] === "Lo" && type[1] === "":
      return (
        <InsideNodeParticles
          bgColor={Colors.tekamoloAdverbsColor}
          text={text[0]}
          dotCount={4}
          whitePositionIndex={4}
        />
      );
    case type[0] === "Alt. accusative" && type[1] === "":
      return (
        <InsideNodeParticles
          bgColor={Colors.prepositionPrepositionColor}
          text={text[0]}
          textColor={Colors.darkTextColor}
          dotCount={2}
          nodeWidth={8}
          colorList={[
            Colors.darkBackgroundColor,
            Colors.acusativDeclensionColor,
          ]}
          isPreposition={true}
        />
      );
    case type[0] === "Alt. dative" && type[1] === "":
      return (
        <InsideNodeParticles
          bgColor={Colors.prepositionPrepositionColor}
          text={text[0]}
          textColor={Colors.darkTextColor}
          dotCount={2}
          nodeWidth={8}
          colorList={[Colors.dativDeclensionColor, Colors.darkBackgroundColor]}
          isPreposition={true}
        />
      );
    case type[0] === "acusative" && type[1] === "":
      return (
        <InsideNodeParticles
          bgColor={Colors.prepositionPrepositionColor}
          text={text[0]}
          textColor={Colors.darkTextColor}
          dotCount={1}
          nodeWidth={8}
          colorList={[Colors.acusativDeclensionColor]}
          isPreposition={true}
        />
      );
    case type[0] === "dative" && type[1] === "":
      return (
        <InsideNodeParticles
          bgColor={"#EBEBEC"}
          text={text[0]}
          textColor={Colors.darkTextColor}
          dotCount={1}
          nodeWidth={8}
          colorList={[Colors.dativDeclensionColor]}
          isPreposition={true}
        />
      );
    case type[0] === "Conjunctions" && type[1] === "":
      return (
        <SideNodeParticle
          bgColor={Colors.connectorConjunctionColor}
          text={text[0]}
          countOfNode={1}
          isNodeRightSide={false}
          isSameBorderRadius={true}
          textColor={Colors.darkTextColor}
        />
      );
    case type[0] === "Subconjunction" && type[1] === "":
      return (
        <SideNodeParticle
          bgColor={Colors.connectorConjunctionColor}
          text={text[0]}
          countOfNode={2}
          isNodeRightSide={false}
          isSameBorderRadius={true}
          textColor={Colors.darkTextColor}
        />
      );
    case type[0] === "Conjunctive adverbs" && type[1] === "":
      return (
        <SideNodeParticle
          bgColor={Colors.connectorConjunctionColor}
          text={text[0]}
          countOfNode={3}
          isNodeRightSide={false}
          isSameBorderRadius={true}
          textColor={Colors.darkTextColor}
        />
      );
    case type[0] === "Empty" && type[1] === "":
      return (
        <RoundedCornerParticle
          bgColor={Colors.genitivDeclensionColor}
          text={text[0]}
          textColor={Colors.darkTextColor}
          borderRadius={4}
          hasOnlyBorder
        />
      );

    // Combine Particle using 'Sujeto'
    case type[0] === "Sujeto" && type[1] === "Masculine":
      return (
        <RowContainer width="auto" gap="0px">
          <LeftRoundedCornerParticle
            bgColor={Colors.subjectDeclensionColor}
            textColor={Colors.lightTextColor}
            text={text[0]}
            padding="6px 0 6px 4px"
          />
          <RoundedCornerParticle
            bgColor={Colors.masculineGenreColor}
            text={text[1]}
            textColor={Colors.lightTextColor}
            borderRadius={4}
            padding="6px 4px 6px 0"
          />
        </RowContainer>
      );
    case type[0] === "Sujeto" && type[1] === "Femenine":
      return (
        <RowContainer width="auto" gap="0px">
          <LeftRoundedCornerParticle
            bgColor={Colors.subjectDeclensionColor}
            textColor={Colors.lightTextColor}
            text={text[0]}
            padding="6px 0 6px 4px"
            radius="4px"
          />
          <RoundedCornerParticle
            bgColor={Colors.feminineGenreColor}
            text={text[1]}
            textColor={Colors.lightTextColor}
            borderRadius={4}
            padding="6px 4px 6px 0"
          />
        </RowContainer>
      );
    case type[0] === "Sujeto" && type[1] === "Neutral":
      return (
        <RowContainer width="auto" gap="0px">
          <LeftRoundedCornerParticle
            bgColor={Colors.subjectDeclensionColor}
            textColor={Colors.lightTextColor}
            text={text[0]}
            padding="6px 0 6px 4px"
          />
          <RoundedCornerParticle
            bgColor={Colors.neutralGenreColor}
            text={text[1]}
            textColor={Colors.lightTextColor}
            borderRadius={4}
            padding="6px 4px 6px 0"
          />
        </RowContainer>
      );
    case type[0] === "Sujeto" && type[1] === "Plural":
      return (
        <RowContainer width="auto" gap="0px">
          <LeftRoundedCornerParticle
            bgColor={Colors.subjectDeclensionColor}
            textColor={Colors.lightTextColor}
            text={text[0]}
            padding="6px 0 6px 4px"
          />
          <RoundedCornerParticle
            bgColor={Colors.pluralGenreColor}
            text={text[1]}
            textColor={Colors.darkTextColor}
            borderRadius={4}
            padding="6px 4px 6px 0"
          />
        </RowContainer>
      );

    // Combine Particle using 'Accusative'
    case type[0] === "Accusative" && type[1] === "Masculine":
      return (
        <RowContainer width="auto" gap="0px">
          <LeftRoundedCornerParticle
            bgColor={Colors.acusativDeclensionColor}
            textColor={Colors.lightTextColor}
            text={text[0]}
            padding="6px 0 6px 4px"
          />
          <RoundedCornerParticle
            bgColor={Colors.masculineGenreColor}
            text={text[1]}
            textColor={Colors.lightTextColor}
            borderRadius={4}
            padding="6px 4px 6px 0"
          />
        </RowContainer>
      );
    case type[0] === "Accusative" && type[1] === "Femenine":
      return (
        <RowContainer width="auto" gap="0px">
          <LeftRoundedCornerParticle
            bgColor={Colors.acusativDeclensionColor}
            textColor={Colors.lightTextColor}
            text={text[0]}
            padding="6px 0 6px 4px"
          />
          <RoundedCornerParticle
            bgColor={Colors.feminineGenreColor}
            text={text[1]}
            textColor={Colors.lightTextColor}
            borderRadius={4}
            padding="6px 4px 6px 0"
          />
        </RowContainer>
      );
    case type[0] === "Accusative" && type[1] === "Neutral":
      return (
        <RowContainer width="auto" gap="0px">
          <LeftRoundedCornerParticle
            bgColor={Colors.acusativDeclensionColor}
            textColor={Colors.lightTextColor}
            text={text[0]}
            padding="6px 0 6px 4px"
          />
          <RoundedCornerParticle
            bgColor={Colors.neutralGenreColor}
            text={text[1]}
            textColor={Colors.lightTextColor}
            borderRadius={4}
            padding="6px 4px 6px 0"
          />
        </RowContainer>
      );
    case type[0] === "Accusative" && type[1] === "Plural":
      return (
        <RowContainer width="auto" gap="0px">
          <LeftRoundedCornerParticle
            bgColor={Colors.acusativDeclensionColor}
            textColor={Colors.lightTextColor}
            text={text[0]}
            padding="6px 0 6px 4px"
          />
          <RoundedCornerParticle
            bgColor={Colors.pluralGenreColor}
            text={text[1]}
            textColor={Colors.darkTextColor}
            borderRadius={4}
            padding="6px 4px 6px 0"
          />
        </RowContainer>
      );

    // Combine Particle using 'Dative'
    case type[0] === "Dative" && type[1] === "Masculine":
      return (
        <RowContainer width="auto" gap="0px">
          <LeftRoundedCornerParticle
            bgColor={Colors.dativDeclensionColor}
            textColor={Colors.lightTextColor}
            text={text[0]}
            padding="6px 0 6px 4px"
          />
          <RoundedCornerParticle
            bgColor={Colors.masculineGenreColor}
            text={text[1]}
            textColor={Colors.lightTextColor}
            borderRadius={4}
            padding="6px 4px 6px 0"
          />
        </RowContainer>
      );
    case type[0] === "Dative" && type[1] === "Femenine":
      return (
        <RowContainer width="auto" gap="0px">
          <LeftRoundedCornerParticle
            bgColor={Colors.dativDeclensionColor}
            textColor={Colors.lightTextColor}
            text={text[0]}
            padding="6px 0 6px 4px"
          />
          <RoundedCornerParticle
            bgColor={Colors.feminineGenreColor}
            text={text[1]}
            textColor={Colors.lightTextColor}
            borderRadius={4}
            padding="6px 4px 6px 0"
          />
        </RowContainer>
      );
    case type[0] === "Dative" && type[1] === "Neutral":
      return (
        <RowContainer width="auto" gap="0px">
          <LeftRoundedCornerParticle
            bgColor={Colors.dativDeclensionColor}
            textColor={Colors.lightTextColor}
            text={text[0]}
            padding="6px 0 6px 4px"
          />
          <RoundedCornerParticle
            bgColor={Colors.neutralGenreColor}
            text={text[1]}
            textColor={Colors.lightTextColor}
            borderRadius={4}
            padding="6px 4px 6px 0"
          />
        </RowContainer>
      );
    case type[0] === "Dative" && type[1] === "Plural":
      return (
        <RowContainer width="auto" gap="0px">
          <LeftRoundedCornerParticle
            bgColor={Colors.dativDeclensionColor}
            textColor={Colors.lightTextColor}
            text={text[0]}
            padding="6px 0 6px 4px"
          />
          <RoundedCornerParticle
            bgColor={Colors.pluralGenreColor}
            text={text[1]}
            textColor={Colors.darkTextColor}
            borderRadius={4}
            padding="6px 4px 6px 0"
          />
        </RowContainer>
      );

    // Combine Particle using 'Genitive'
    case type[0] === "Genitive" && type[1] === "Masculine":
      return (
        <RowContainer width="auto" gap="0px">
          <LeftRoundedCornerParticle
            bgColor={Colors.genitivDeclensionColor}
            textColor={Colors.lightTextColor}
            text={text[0]}
            padding="6px 0 6px 4px"
          />
          <RoundedCornerParticle
            bgColor={Colors.masculineGenreColor}
            text={text[1]}
            textColor={Colors.lightTextColor}
            borderRadius={4}
            padding="6px 4px 6px 0"
          />
        </RowContainer>
      );
    case type[0] === "Genitive" && type[1] === "Femenine":
      return (
        <RowContainer width="auto" gap="0px">
          <LeftRoundedCornerParticle
            bgColor={Colors.genitivDeclensionColor}
            textColor={Colors.lightTextColor}
            text={text[0]}
            padding="6px 0 6px 4px"
          />
          <RoundedCornerParticle
            bgColor={Colors.feminineGenreColor}
            text={text[1]}
            textColor={Colors.lightTextColor}
            borderRadius={4}
            padding="6px 4px 6px 0"
          />
        </RowContainer>
      );
    case type[0] === "Genitive" && type[1] === "Neutral":
      return (
        <RowContainer width="auto" gap="0px">
          <LeftRoundedCornerParticle
            bgColor={Colors.genitivDeclensionColor}
            textColor={Colors.lightTextColor}
            text={text[0]}
            padding="6px 0 6px 4px"
          />
          <RoundedCornerParticle
            bgColor={Colors.neutralGenreColor}
            text={text[1]}
            textColor={Colors.lightTextColor}
            borderRadius={4}
            padding="6px 4px 6px 0"
          />
        </RowContainer>
      );
    case type[0] === "Genitive" && type[1] === "Plural":
      return (
        <RowContainer width="auto" gap="0px">
          <LeftRoundedCornerParticle
            bgColor={Colors.genitivDeclensionColor}
            textColor={Colors.lightTextColor}
            text={text[0]}
            padding="6px 0 6px 4px"
          />
          <RoundedCornerParticle
            bgColor={Colors.pluralGenreColor}
            text={text[1]}
            textColor={Colors.darkTextColor}
            borderRadius={4}
            padding="6px 4px 6px 0"
          />
        </RowContainer>
      );

    default:
      return null;
  }
};

export default Particles;

import React from "react";
import Container from "../Common/Container";
import ColumnContainer from "../Common/ColumnContainer";
import RowContainer from "../Common/RowContainer";
import Particles from "../Common/ParticleDesign";

interface DropdownValue {
    id: number;
    pId: number;
    name: string;
    children: any[];
    particleType: string;
    particleType1: string;
    particleValue: string;
    particleValue1: string;
}

interface Particles {
    inputValue: string;
    dropdownValue: DropdownValue;
}

interface Data {
  id: number;
  flashCardContentComponentId: number;
   particles: Particles;
  description: string;
}

interface GrammarSingleComponentprops {
  data: Data;
  lightTheme?: string;
  isBretzel?: boolean;
  onClick?: () => void;
}

const GrammarSingleComponent: React.FC<GrammarSingleComponentprops> = ({
  data,
  lightTheme,
  isBretzel = false,
  onClick,
}) => {
  return (
    <Container
      bgColor={lightTheme === "Light" ? "#FFFFFF" : isBretzel ? "#0C0C13" : " "}
      color={lightTheme === "Light" ? "#121217" : "#FFFFFF"}
      width="100%"
      borderRadius="24px"
      padding="16px"
      cursor="pointer"
      onClick={onClick}
    >
      <RowContainer
        alignItem="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Particles
          text={[data.particles.inputValue ?? "", ""]}
          type={[
            data.particles.dropdownValue.particleType ?? "",
            data.particles.dropdownValue.particleType1 ?? "",
          ]}
          textColor={lightTheme === "Light" ? "#121217" : "#FFFFFF"}
        />
        <div dangerouslySetInnerHTML={{ __html: data.description.cleanUpString() }} />
      </RowContainer>
    </Container>
  );
};

export default GrammarSingleComponent;

import React, { useState } from "react";
import Label from "./Common/Label";
import RowContainer from "./Common/RowContainer";
import Container from "./Common/Container";
import editIcon from "../assets/editIcon.svg";
import deleteIcon from "../assets/deleteIcon.svg";
import styled from "styled-components";
import CustomCheckbox from "./Common/CustomCheckbox";
import Switch from "./Common/Switch";
import StatusChangeConfirmation from "./Common/StatusUpdateConfirmation";

interface lessons {
  id: number;
  title: string;  
  section: string;
  subTheme: boolean;
  level: string;
  latestdate: string;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  onCheckboxChange: (id: number) => void;
  isChecked: boolean;
  status: boolean;
  themeCount: number;
  onStatusChange: (id: number) => void;
}

const NewRowContainer = styled(RowContainer)`
  overflow: hidden;
`;

const NewLabel = styled(Label)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Lessons: React.FC<lessons> = ({
  id,
  title,
  subTheme,
  level,
  latestdate,
  onEdit,
  onDelete,
  onCheckboxChange,
  isChecked,
  status,
  onStatusChange,
  themeCount,
}) => {
  const [isSwitchOn, setIsSwitchOn] = useState(status);
  const [showStatusPopup, setShowStatusPopup] = useState(false);

  const toggleSwitch = () => {
    // setShowStatusPopup(true); 
    const newStatus = !isSwitchOn;
    setIsSwitchOn(newStatus);
    onStatusChange(id);
    setShowStatusPopup(false);
  };

  const handleUpdateStatus = () => {
    const newStatus = !isSwitchOn;
    setIsSwitchOn(newStatus);
    onStatusChange(id);
    setShowStatusPopup(false);
  };

  const handleDelete = () => {
    onDelete(id);
  };
  
  const handleCancelStatusChange = () => {
    setShowStatusPopup(false);
  };

  return (
    <>
      <Container
        bgColor="#fff"
        borderRadius="10px"
        height="50px"
        alignItems="center"
        display="flex"
        width="100%"
        className="user-data user-container-hover"
      >
        <RowContainer
          justifyContent="space-between"
          alignItem="center"
          borderRadius="12px"
          margin="10px"
        >
          <RowContainer flex="1.3">
            {/* <CustomCheckbox
            checked={isChecked}
            onChange={() => onCheckboxChange(id)}
          /> */}
          </RowContainer>
          <NewRowContainer gap="0px" flex="9">
            <NewLabel fontSize="16px" fontWeight={400}>
              {title}
            </NewLabel>
          </NewRowContainer>
          <NewRowContainer gap="0px" flex="7">
            <NewLabel fontSize="16px" fontWeight={400}>
              {/* {section} */}
            </NewLabel>
          </NewRowContainer>
          <NewRowContainer flex="6.2">
            {!subTheme ? (
              <Label
                fontSize="10px"
                color="#000"
                fontWeight={400}
                cursor="pointer"
                padding="8px 13px"
                bgColor={themeCount > 0 ? "#DAD7FE" : "transparent"}
                justifyContent="center"
                borderRadius="40px"
              >
                {themeCount > 0 && `${themeCount} sub-temas`}
              </Label>
            ) : (
              <Label
                fontSize="10px"
                color="#000"
                fontWeight={400}
                cursor="pointer"
                padding="8px 13px"
                justifyContent="center"
                borderRadius="40px"
              />
            )}
          </NewRowContainer>
          <NewRowContainer flex="4.8">
            <NewLabel fontSize="16px" fontWeight={400}>
              {level}
            </NewLabel>
          </NewRowContainer>
          <NewRowContainer flex="6.1">
            <NewLabel fontSize="16px" fontWeight={400}>
              {latestdate}
            </NewLabel>
          </NewRowContainer>
          <RowContainer flex="3.3">
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ gap: "10px" }}
            >
              <Switch isOn={isSwitchOn} onToggle={toggleSwitch} />
              <div style={{ cursor: "pointer" }}>
                <img
                  src={editIcon}
                  className="user-scale"
                  onClick={() => onEdit(id)}
                  style={{ WebkitUserSelect: "none", userSelect: "none" }}
                />
              </div>
              {/* <div style={{ cursor: "pointer" }} onClick={handleDelete}>
              <img src={deleteIcon} style={{ WebkitUserSelect: "none", userSelect: "none" }}/>
            </div> */}
            </div>
          </RowContainer>
        </RowContainer>
      </Container>

      {showStatusPopup && (
        <StatusChangeConfirmation
          showStatusPopup={showStatusPopup}
          setShowStatusPopup={setShowStatusPopup}
          message={`Are you sure you want to ${
            isSwitchOn ? "deactivate" : "activate"
          } this lesson?`}
          statusButtonLabel={isSwitchOn ? "Deactivate" : "Activate"}
          onStatus={handleUpdateStatus}
          onCancel={handleCancelStatusChange}
        />
      )}
    </>
  );
};
export default Lessons;
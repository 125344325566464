// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Default styling for the progress bar */
progress {
  /* width: 100%; */
  height: 12px;
  /* -webkit-appearance: none; */
  /* appearance: none; */
  border-radius: 6px;
  /* overflow: hidden; */
}

/* Webkit-specific styling for Chrome/Safari */
progress::-webkit-progress-bar {
  background-color: #e0e0e0;
}

progress::-webkit-progress-value {
  border-radius: 6px;
}

.progress-bar-red::-webkit-progress-value {
  background-color: #eb002c;
}

.progress-bar-green::-webkit-progress-value {
  background-color: #28a138;
}

progress::-moz-progress-bar {
  background-color: #28a138;
}

.progress-bar-red::-moz-progress-bar {
  background-color: #eb002c;
}

.progress-bar-green::-moz-progress-bar {
  background-color: #28a138;
}
`, "",{"version":3,"sources":["webpack://./src/stylesheets/LessonCardProgress.css"],"names":[],"mappings":"AAAA,yCAAyC;AACzC;EACE,iBAAiB;EACjB,YAAY;EACZ,8BAA8B;EAC9B,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA,8CAA8C;AAC9C;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* Default styling for the progress bar */\nprogress {\n  /* width: 100%; */\n  height: 12px;\n  /* -webkit-appearance: none; */\n  /* appearance: none; */\n  border-radius: 6px;\n  /* overflow: hidden; */\n}\n\n/* Webkit-specific styling for Chrome/Safari */\nprogress::-webkit-progress-bar {\n  background-color: #e0e0e0;\n}\n\nprogress::-webkit-progress-value {\n  border-radius: 6px;\n}\n\n.progress-bar-red::-webkit-progress-value {\n  background-color: #eb002c;\n}\n\n.progress-bar-green::-webkit-progress-value {\n  background-color: #28a138;\n}\n\nprogress::-moz-progress-bar {\n  background-color: #28a138;\n}\n\n.progress-bar-red::-moz-progress-bar {\n  background-color: #eb002c;\n}\n\n.progress-bar-green::-moz-progress-bar {\n  background-color: #28a138;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useRef, useEffect } from "react";
import "../../assets/css/members.css";
import RowContainer from "../../Components/Common/RowContainer";
import Container from "../../Components/Common/Container";
import Label from "../../Components/Common/Label";
import ColumnContainer from "../../Components/Common/ColumnContainer";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import NewRankAndLevels from "../../Components/RankingLevels/RankingLevels";
import CustomDropdown from "../../Components/Common/CustomDropdown";
import { getRequest, postRequest, baseURL } from "../../api/apiClient";
import formatDate from "../../Components/DateFormat";
import DeleteConfirmation from "../../Components/Common/DeleteConfirmation";
import { toast } from "react-toastify";
import VerticalSpace from "../../Components/VerticalSpace";
import "../../stylesheets/indexpage.css";
import PaginationComponent from "../../Components/Common/PaginationComponent";
import Toast from "../../Components/Common/Toast";
import { setDropDown } from "../../redux/lessonsSlice";
import { setParticles } from "../../redux/particlesReducerSlice";
import { useDispatch } from "react-redux";
import Loader from "../../utils/Loading";
import gsap from "gsap";
import "../../stylesheets/common.css";
import ShimmerEffect from "../../utils/ShimmerEffect";
import DeleteLoader from "../../utils/deleteLoader";
import StatusChangeConfirmation from "../../Components/Common/StatusUpdateConfirmation";

const ReponsiveRow = styled(RowContainer)`
  @media (max-width: 1430px) {
    overflow-x: auto;
  }
`;

const InnerRowContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 1100px;
`;

const ScrollableRowContainer = styled(RowContainer)`
  @media (max-width: 1150px) {
    width: 100%;
    overflow-x: auto;
  }
`;

interface RankingLevels {
  id: number;
  level: string;
  challenges_tag: string;
  level_rank: string;
  updatedAt: string;
  active: boolean;
}

const RankingLevels = () => {
  const [rankingLevels, setRankingLevels] = useState<RankingLevels[]>([]); // State to store user data
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedPackages, setSelectedPackages] = useState<number[]>([]);
  const [selectedPackageId, setSelectedPackageId] = useState<number | null>(
    null
  );

  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(5);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [filters, setFilters] = useState<{
    level?: string;
    updatedAt?: number;
    active?: string;
  }>({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dropdownItems: string[] = [
    "All",
    "Level",
    "Active",
    "InActive",
    "Latest update",
  ];

  const handleSelect = (item: string) => {
    setSelectedItem(item);
  };

  // Fetch packages data from API
  const fetchPackages = async (
    page: number,
    perPage: number,
    filters: { level?: string; updatedAt?: number; active?: string }
  ) => {
    if (!filters) {
      setLoading(true);
    } else {
      setFilterLoading(true);
    }
    try {
      let url = `${baseURL}/levels/list?page=${page}&limit=${perPage}`;

      if (filters.level) {
        url += `&level=${filters.level}`;
      }

      if (filters.updatedAt) {
        url += `&updatedAt=${filters.updatedAt}`;
      }

      if (typeof filters.active !== "undefined") {
        url += `&active=${filters.active}`;
      }

      const response = await postRequest(url);

      if (response?.data) {
        setRankingLevels(response.data.data.levels);
        setCount(response.data.data.count);
        setPage(response.data.data.page - 1);
        setHasNextPage(response.data.data.hasNextPage);
        setHasPreviousPage(response.data.data.hasPreviousPage);
      }
      setLoading(false);
      setFilterLoading(false);
      const fetchLessons = await getRequest(
        `${baseURL}/notification-challenge/get-lesson-dropdown`
      );

      const fetchParticles = await getRequest(
        `${baseURL}/particle/get-dropdown`
      );
      dispatch(setDropDown(fetchLessons?.data?.data || []));
      dispatch(setParticles(fetchParticles?.data?.data || []));
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };

  useEffect(() => {
    fetchPackages(page + 1, limit, filters);
  }, [page, limit, filters]);


  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleApplyFilters = () => {
  //   switch (selectedItem) {
  //     case "Level":
  //       setFilters({ level: "A1,A2,B1" });
  //       break;
  //     case "Latest update":
  //       setFilters({ updatedAt: -1 });
  //       break;
  //     case "Active":
  //       setFilters({ active: "true" });
  //       break;
  //     case "InActive":
  //       setFilters({ active: "false" });
  //       break;
  //     default:
  //       setFilters({});
  //       break;
  //   }
  // };

  const handleEditPackage = (id: number) => {
    navigate(`/ranking-levels/edit-levels/${id}`);
  };
  
  const handleCreatePackage = () => {
    navigate("/ranking-levels/create-levels");
  };

  const handleStatusChange = async (id: number, status: boolean) => {

    const payload = {
      status: !status,
    }
    
    const response:any = await postRequest( 
      `${baseURL}/levels/status-change/${id}`, payload
    );
    if(response){
      Toast(response.data.statusMessage, "success");
    }

  };

  const onCheckboxChange = (id: number) => {
    if (selectedPackages.includes(id)) {
      setSelectedPackages(
        selectedPackages.filter((selectedId) => selectedId !== id)
      );
    } else {
      setSelectedPackages([...selectedPackages, id]);
    }
  };

  const deleteSelectedPackages = () => {
    if (selectedPackages.length > 0) {
      setShowDeleteDialog(true);
    } else {
      Toast("Please select at least one packages to delete.", "error");
    }
  };

  const deletePackages = async (packageIds: number | number[]) => {
    try {
      const payload = {
        packageId: Array.isArray(packageIds) ? packageIds : packageIds,
      };
      setIsLoading(true);
      const response = await postRequest(
        `${baseURL}/levels/delete/${packageIds}`,
        payload
      );
      setIsLoading(false);
      if (response?.status === 200) { 
        Toast("Package deleted successfully", "success");
        setRankingLevels((prevData) =>
          prevData.filter((packages) =>
            Array.isArray(packageIds)
              ? !packageIds.includes(packages.id)
              : packages.id !== packageIds  
          )
        );
      } else {
        setIsLoading(false);
        toast.error("Failed to delete lesson(s)");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error deleting lesson:", error);
      toast.error("An error occurred while deleting the lesson(s)");
    }
  };

  const handleDelete = async () => {
    if (selectedPackageId !== null) {
      await deletePackages(selectedPackageId);
    } else {
      await deletePackages(selectedPackages);
    }
    // await fetchPackages(page + 1, limit, filters);
    setSelectedPackages([]);
    setSelectedPackageId(null);
    setShowDeleteDialog(false);
  };

  const toggleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  useEffect(() => {
    let timeLine = gsap.timeline();

    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 0.3,
      ease: "power2.out",
    });

    timeLine.from(".user-data", {
      opacity: 0,
      y: 40,
      duration: 0.5,
      ease: "power2.out",
      stagger: 0.2,
    });
  }, [loading]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="container-fluid">
            <div className="p-3">
              {/* Heading */}
              <div className="row mb-3 ">
                <div className="col">
                  <h2 style={{ fontWeight: "bold" }}>Ranking / Levels</h2>
                </div>
              </div>

              {/* Filter Label */}

              {/* <div className="row mb-3">
                <div className="col">
                  <label htmlFor="filter" className="form-label">
                    Filter
                  </label>
                </div>
              </div> */}

              {/* Buttons Row */}
              <div className="row mb-3 main-responsive">
                {/* <div className="col d-flex responsive">
                  <CustomDropdown
                    items={dropdownItems}
                    onSelect={handleSelect}
                    selectedItem={selectedItem || ""}
                    height="50px"
                  />
                  <button
                    type="button"
                    className="btn btn-primary ms-3 user"
                    style={{
                      backgroundColor: "#100F15",
                      borderRadius: "10px",
                      color: "#FFFFFF",
                      fontSize: "14px",
                      width: "auto",
                      padding: "14px 50px",
                    }}
                    onClick={handleApplyFilters}
                  >
                    Apply
                  </button>
                </div> */}
                <div className="col d-flex justify-content-end responsive">
                  {/* <button
              type="button"
              className="btn btn-outline-secondary me-2 text-center"
              style={{
                color: "#100F15",
                background: "none",
                border: "1px solid #100F15",
                borderRadius: "10px",
                fontSize: "14px",
                width: "auto",
                padding: "14px 40px",
              }}
              onClick={deleteSelectedLessons}
            >
              Delete all
            </button> */}
                  <button
                    type="button"
                    className="btn btn-success user"
                    style={{
                      backgroundColor: "#FF9E00",
                      borderRadius: "10px",
                      color: "#100F15",
                      fontSize: "14px",
                      width: "auto",
                      padding: "14px 40px",
                    }}
                    onClick={handleCreatePackage}
                  >
                    Add New
                  </button>
                </div>
              </div>
              <ScrollableRowContainer>
                <InnerRowContainer>
                  <Container width="100%">
                    <RowContainer
                      alignItem="center"
                      padding="8px 12px 8px 12px"
                      gap="8px"
                      className="row-container"
                    >
                      <RowContainer flex="1.4">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        ></Label>
                      </RowContainer>
                      <RowContainer flex="8">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Level
                        </Label>
                      </RowContainer>
                      <RowContainer flex="8">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          challenges_tag
                        </Label>
                      </RowContainer>
                      <RowContainer flex="7">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          level_rank
                        </Label>
                      </RowContainer>
                      
                      <RowContainer flex="5">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Uploaded
                        </Label>
                      </RowContainer>
                      <RowContainer flex="3.3">
                        <Label
                          fontWeight={400}
                          fontSize="14px"
                          color="rgba(164, 163, 167, 1)"
                        >
                          Action
                        </Label>
                      </RowContainer>
                    </RowContainer>
                    {filterLoading ? (
                      <RowContainer
                        bgColor="#FFFFFF"
                        borderRadius="8px"
                        padding="10px 0px"
                      >
                        {Array.from({ length: 6 }, (_, index) => {
                          return (
                            <RowContainer
                              key={index}
                              width={index === 0 || index === 5 ? "10%" : "20%"}
                              display="flex"
                              justifyContent="center"
                            >
                              <ShimmerEffect />
                            </RowContainer>
                          );
                        })}
                      </RowContainer>
                    ) : (
                      <>
                        {rankingLevels && rankingLevels.length > 0 ? (
                          <ColumnContainer>
                            {rankingLevels.map((rankingLevels) => (
                              <NewRankAndLevels
                                key={rankingLevels.id}
                                id={rankingLevels.id}
                                label={rankingLevels.level_rank || 'N/A'}
                                level={rankingLevels.level}
                                challenges_tag={rankingLevels.challenges_tag || 'N/A'}
                                latestdate={formatDate(rankingLevels.updatedAt)}
                                onEdit={handleEditPackage}
                                onDelete={() => {
                                  setSelectedPackageId(rankingLevels.id);
                                  toggleDeleteDialog();
                                }}
                                isChecked={selectedPackages.includes(
                                  rankingLevels.id
                                )}
                                onCheckboxChange={onCheckboxChange}
                                status={rankingLevels.active}
                                onStatusChange={() => handleStatusChange(rankingLevels?.id, rankingLevels?.active)}
                              />
                            ))}
                          </ColumnContainer>
                        ) : (
                          <>
                            <VerticalSpace height={30} />
                            <Label
                              alignItems="center"
                              display="flex"
                              color="rgba(164, 163, 167, 1)"
                              justifyContent="center"
                            >
                              No results found.
                            </Label>
                          </>
                        )}
                      </>
                    )}
                  </Container>
                </InnerRowContainer>
              </ScrollableRowContainer>
              <VerticalSpace height={10} />
              <RowContainer justifyContent="flex-end" className="user-data">
                <PaginationComponent
                  page={page}
                  rowsPerPage={limit}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  count={count}
                  hasNextPage={hasNextPage}
                  hasPreviousPage={hasPreviousPage}
                />
              </RowContainer>
            </div>
            {showDeleteDialog && (
              <DeleteConfirmation
                showDeletePopup={true}
                setShowDeletePopup={setShowDeleteDialog}
                message={`Are you sure you want to delete ${
                  selectedPackages.length === 0
                    ? "this packages?"
                    : selectedPackages.length === 1
                    ? "this packages?"
                    : "these packages?"
                }`}
                deleteButtonLabel="Delete"
                onDelete={handleDelete}
              />
            )}

            {isLoading && <DeleteLoader />}
            <VerticalSpace height={100} />
          </div>
        </>
      )}
    </>
  );
};

export default RankingLevels;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import deleteIcon from "../../assets/deleteIcon.svg";
import VerticalSpace from "../VerticalSpace";
import Label from "../Common/Label";
import editIcon from "../../assets/editIcon.svg";
import plusIcon from "../../assets/plus_Icon.png";
import "../../stylesheets/theme.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  baseURL,
  deleteRequest,
  getRequest,
  postRequest,
} from "../../api/apiClient";
import Toast from "../Common/Toast";
import DeleteConfirmation from "../Common/DeleteConfirmation";
import Loader from "../../utils/Loading";
import gsap from "gsap";
import "../../stylesheets/common.css";
import DeleteLoader from "../../utils/deleteLoader";

interface Level {
  id: number;
  name: string;
  order: string;
}

const Levels = () => {
  const { id } = useParams();
  const [addLevel, setNewLevel] = useState(false);
  const [level, setLevel] = useState<Level[]>([]);
  const [levelName, setLevelName] = useState("");
  const [editLevel, setEditLevel] = useState<Level | null>(null);

  const [levelError, setLevelError] = useState("");
  const [deleteDialogMessage, setDeleteDialogMessage] = useState("");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [onConfirmDelete, setOnConfirmDelete] = useState<() => void>(() => { });
  const [levelToDelete, setLevelToDelete] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchLevel = async () => {
    try {
      setIsLoading(true);
      const response = await postRequest(`${baseURL}/levels/list`);
      
      if (response?.data?.data){
        const levels = response?.data?.data?.levels.map((level: any) => ({
          id: level.id,
          name: level.level,
          order: level.order,
        }));

        const sortedLevels = levels?.sort(
          (a: any, b: any) => a.order - b.order
        );

        setLevel(sortedLevels);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching levels:", error);
    }
  };


  useEffect(() => {
    fetchLevel();
  }, []);

  const handleNewLevel = () => {
    setLevelError("");
    setNewLevel(true);
    setEditLevel(null);
  };

  const handleEditLevel = (level: Level) => {
    setLevelName(level.name);
    setEditLevel(level);
    setNewLevel(true);
  };

  const handleSaveFlashCard = async () => {
    if (!levelName) {
      setLevelError("Please enter flashcard name");
      return;
    }

    if (editLevel?.id) {
      await handleUpdateLevel(editLevel?.id, levelName);
    } else {
      await handleCreateLevel();
    }
  }

  const handleUpdateLevel = async (levelId: number,
    levelName: string) => {

    setLoading(true);

    const newOrder = level.length + 1;
    const payload = {
      level: levelName,
      order: newOrder
    }
    const response = await postRequest(`${baseURL}/levels/update/${levelId}`, payload);
    if (response?.data?.data) {


      const updatedLevel = level.map((level) =>
        level.id === levelId
          ? { ...level, name: levelName }
          : level
      );
      setLevel(updatedLevel);
      setLevelName('');

      // setLevel((prevLevels) => [...prevLevels, newLevel]);
      Toast(response?.data?.statusMessage, "success");
      setNewLevel(false);
    }
    setLoading(false);
  }


  const handleCreateLevel = async () => {
    setLoading(true);
    const newOrder = level.length + 1;
    const payload = {
      level: levelName,
      order: newOrder
    }
    const response = await postRequest(`${baseURL}/levels/create`, payload);
    if (response?.data?.data) {

      const newLevel = {
        id: response.data.data.id,
        name: response.data.data.level,
        order: response.data.data.order
      };

      setLevel((prevLevels) => [...prevLevels, newLevel]);
      Toast(response?.data?.statusMessage, "success");
      setNewLevel(false);
    }
    setLoading(false);
  }

  const deleteLevel = async (id: number) => {
    setLoading(true);
    const response = await postRequest(`${baseURL}/levels/delete/${id}`);
    if (response?.data?.data) {
      const updatedLevels = level.filter((level) => level.id !== id);
      setLevel(updatedLevels);
      Toast(response?.data?.statusMessage, "success");
      setShowDeleteDialog(false);
      setLevelToDelete(null);
    }
    setLoading(false);
  };

  const handeleAddLevelDetails = async (id: number) => {
    navigate(`/ranking-levels/edit-levels/${id}`);
  };

  const handleBackClick = async () => {
    navigate("/ranking-levels");
  };

  useEffect(() => {
    let timeLine = gsap.timeline();
    timeLine.from(".row-container", {
      opacity: 0,
      y: -40,
      duration: 0.3,
      ease: "power2.out",
    });

    timeLine.from(".user-data", {
      opacity: 0,
      y: 40,
      duration: 0.5,
      ease: "power2.out",
      stagger: 0.2,
    });
  }, [isLoading]);

  return (
    <div className="container-fluid">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="p-3 main-page">
            <div
              className="d-flex  align-items-center go-back"
              onClick={handleBackClick}
              style={{ width: "fit-content" }}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="me-2"
                style={{
                  backgroundColor: "#D9D9D9",
                  padding: "7px",
                  borderRadius: "8px",
                  color: "#000",
                  cursor: "pointer",
                }}
              />
              <label
                style={{
                  color: "#FF9E00",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Back
              </label>
            </div>
            {/* </a> */}
            <h4
              className="row-container"
              style={{
                textAlign: "center",
                margin: "auto",
                color: "#7542E4",
                fontWeight: "bold",
              }}
            >
              Add a level
            </h4>
            <VerticalSpace height={30} />
            <div
              className="container d-flex theme-container justify-content-center"
              style={{ maxWidth: "666px" }}
            >
              <div
                className="container p-4"
                style={{
                  backgroundColor: "rgb(220 220 220 / 66%)",
                  borderRadius: "30px",
                }}
              >
                <div className="col-12 theme-content">
                  <div className="d-flex justify-content-between">
                    <Label fontSize="20px" fontWeight={700}>
                      Levels
                    </Label>
                  </div>

                  <div>
                    {level.length === 0 ? (
                      <div className="p-5 d-flex justify-content-center align-items-center ">
                        <label
                          htmlFor="theme"
                          className="form-label"
                          style={{ fontWeight: "bold", color: "#7542E4" }}
                        >
                          There are no level yet in this ranking levels.
                        </label>
                      </div>
                    ) : (
                      level?.map((level) => (
                        <div
                          className="row d-flex justify-content-center align-items-center "
                          style={{ gap: "10px" }}
                          key={level.id}
                        >
                          <div
                            className="drop_box theme-box col-10 user"
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              cursor: "pointer",
                            }}
                            onClick={() => handleEditLevel(level)}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                padding: "7px 1px",
                              }}
                            >
                              <Label
                                fontSize="14px"
                                color="#000"
                                fontWeight={400}
                              >
                                {level.name}
                              </Label>
                            </div>
                          </div>
                          <div
                            className="p-0"
                            style={{ cursor: "pointer", width: "fit-content" }}
                            onClick={() => handeleAddLevelDetails(level?.id)}
                          >
                            <img
                              src={editIcon}
                              className="user-scale"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>

                          {/* <div
                            className="p-0 user-scale"
                            style={{
                              cursor: "pointer",
                              width: "fit-content",
                              backgroundColor: "rgb(203 203 203 / 99%)",
                              borderRadius: "10px",
                            }}
                          >
                            <img
                              src={plusIcon}
                              width="31px"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div> */}

                          <div
                            className="p-0"
                            style={{ cursor: "pointer", width: "fit-content" }}
                            onClick={() => deleteLevel(level.id)}

                          >
                            <img
                              src={deleteIcon}
                              className="user-scale"
                              style={{
                                WebkitUserSelect: "none",
                                userSelect: "none",
                              }}
                            />
                          </div>
                        </div>
                      ))
                    )}
                  </div>

                  <button
                    type="button"
                    className="user"
                    style={{
                      color: "#100F15",
                      background: "none",
                      border: "1px solid #100F15",
                      alignItems: "center",
                      borderRadius: "10px",
                      padding: "14px 40px",
                      width: "auto",
                    }}
                    onClick={handleNewLevel}
                  >
                    Add level
                  </button>
                </div>
              </div>
            </div>
          </div>

          {addLevel && (
            <div
              className="modal"
              role="dialog"
              style={{
                display: "block",
                position: "fixed",
                zIndex: 1,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="modal-dialog">
                <div className="modal-content" style={{ borderRadius: "20px" }}>
                  <div className="modal-body p-3">
                    <h5 style={{ fontWeight: "bold" }}>Add a Level</h5>
                    <VerticalSpace height={10} />
                    <input
                      type="text"
                      className="form-control"
                      id="inputthemes"
                      placeholder="New level name"
                      value={levelName}
                      onChange={(e) => setLevelName(e.target.value)}
                    />
                    {levelError && (
                      <div className="error-message">{levelError}</div>
                    )}
                  </div>
                  <div className="modal-body d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn btn-secondary user"
                      onClick={() => setNewLevel(false)}
                      style={{
                        // height: "50px",
                        padding: "12px",
                        color: "#100F15",
                        background: "none",
                        border: "1px solid #100F15",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger user"
                      style={{
                        backgroundColor: "#FF9E00",
                        color: "#100F15",
                      }}
                      // onClick={createLevel}
                      onClick={() => handleSaveFlashCard()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <VerticalSpace height={120} />
      {showDeleteDialog && (
        <DeleteConfirmation
          showDeletePopup={true}
          setShowDeletePopup={setShowDeleteDialog}
          message={deleteDialogMessage}
          deleteButtonLabel="Delete"
          onDelete={onConfirmDelete}
          onCancel={onConfirmDelete}
        />
      )}

      {loading && <DeleteLoader />}
    </div>
  );
};

export default Levels;

import React from 'react';
import { Modal, Box, useMediaQuery } from '@mui/material';
import CloseIcon from '../../assets/Images/CloseIconWhite.svg';

interface ViewTicketImageProps {
    open: boolean;
    imageSrc: string;
    onClose: () => void;
}

const ViewTicketImage: React.FC<ViewTicketImageProps> = ({ open, imageSrc, onClose }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                <div className="container-fluid pb-5">
                    <div className='d-flex align-items-center justify-content-end pb-3'>
                        <img className='img-fluid user-scale' src={CloseIcon} style={{ height: '35px', width: '35px', cursor: 'pointer' }} alt="Close_Icon" onClick={onClose} />
                    </div>
                    <div className="container d-flex align-items-center justify-content-center h-100">
                        <img className='img-fluid' src={imageSrc} alt="Ticket_Image" style={{ height: isMobile ? '550px' : '700px', width: 'auto', objectFit: 'cover' }} />
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    bgcolor: 'rgb(0,0,0,0.8)',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
    outline: 0
};

export default ViewTicketImage;
